/** @format */

import React from "react";
import { Button, Tooltip } from "@mui/material";
import ObjectSize from "./ObjectSize";
import { deleteDatabase } from "../components/Sidebar/IndexedDBForCurrentVenueBookings";
import ConfirmDialog from "./ConfirmDialog";

const CacheControl = () => {
  const handleDeleteDatabase = async () => {
    alert("Clearing cache");
    await deleteDatabase("venuehubDatabase");
    await deleteDatabase("venuehubTimestamp");
    await deleteDatabase("venuehubSettings");
  };

  return (
    <div>
      <Tooltip title="Clear the cache to force a refresh of all bookings.">
        <ConfirmDialog
          noButton
          header="Clear Cache"
          message="Force a refresh of all bookings?"
          onConfirm={() => handleDeleteDatabase()}
          style={{ position: "fixed", bottom: "10px", width: "10px", height: "10px" }}
        ></ConfirmDialog>
      </Tooltip>
    </div>
  );
};

export default CacheControl;
