/** @format */

// http://localhost:3000/SignAgreement?bookingids=aluz3t36y44qlyqbywvv5af3sv6vmpcw&userid=agent@venuehub.live&agreement=undefined&agreementId=cqkwei2xwwy7t7k2nwjt63kp2tx75jrb&sign=agentSignature
// Den här komponenten möjliggör att logga in med enbart personnummer och bankid.
// Om personnumret känns igen så loggas användaren in, annars skapas en ny användare.
// Om användaren skapas så skapas en ny användare i Users collection med samma ID som användaren.

import React, { useEffect, useState } from "react";
import { Modal, TextField, Button, Grid, Backdrop, CircularProgress, formLabelClasses } from "@mui/material";
import { db, login, signup } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import PhoneInput from "../components/UI helpers/PhoneInput";
import CheckIfEmailExists from "../utils/CheckIfEmailExists";
import { useSelector } from "react-redux";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { Login } from "@mui/icons-material";

const BankIdSignIn = ({ openSignup, setOpenSignup, mailAddress, role, customToken, agreementId }) => {
  const userRole = useSelector((state) => state.counter.userRole);
  const currentUser = useSelector((state) => state.counter.currentUser);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(mailAddress);
  const [emailOk, setEmailOk] = useState("false");
  const [telephone, setTelephone] = useState("");
  const [orgNumber, setOrgNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [usr, setUsr] = useState(getAuth().currentUser);
  const [ready, setReady] = useState(false);
  const [socialId, setSocialId] = useState("");
  const [encryptedSocialId, setEncryptedSocialId] = useState("");

  const handleOpen = () => {
    setOpenSignup(true);
  };

  const handleClose = () => {
    setOpenSignup(false);
  };

  async function testEmail() {
    if ((await CheckIfEmailExists(email)) === "Exists") {
      console.log("Exists");
      setEmailOk(true);
    } else {
      console.log("Don't exist");
      setEmailOk(false);
    }
  }

  // useEffect(() => {
  //   console.log("USR", usr);
  // }, [getAuth()]);

  useEffect(() => {
    testEmail();
  }, []);

  async function handleSocialId(value) {
    if (value.length !== 12) {
      setSocialId(null);
      return;
    }
    setSocialId(value);
    const body = { string: value };
    const encrypted = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/encryptString`, body);
    setEncryptedSocialId(encrypted.data.encryptedString);
  }

  //   async function triggerEncrypt(string) {
  //     const body = { string: string };
  //     const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/encryptString`, body); //for live
  //     // const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/encryptString`, body); // for testing
  //     console.log("❤️ ❤️ ❤️", response.data);
  //     return response.data.encryptedString;
  //   setString(response.data.encryptedString);
  //   }

  //   async function triggerDecrypt() {
  //     const body = { string: string };
  //     const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/decryptString`, body); //for live
  //     // const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/decryptString`, body); // for testing
  //     console.log("❤️ ❤️ ❤️", response.data);
  //     setString(response.data.decryptedString);
  //   }
  const returnAddress = window.location.href;
  console.log("Return address", returnAddress);

  //   const handleSignIn = async () => {
  //     setLoading(true);

  //     await fetch(
  //       `https://europe-west3-venuehub-64e72.cloudfunctions.net/bankIdSignIn?socialId=${socialId}&returnAddress=${returnAddress}&email=${email}`
  //     )
  //       .then((response) => response.json())
  //       //   .then((data) => window.open(data.url))        // Open in new tab
  //       .then((data) => (window.location.href = data.url)) // Open in same tab
  //       .catch((error) => console.error(error));
  //     setLoading(false);
  //   };

  const handleSignIn = async () => {
    setLoading(true);

    await fetch(
      `https://europe-west3-venuehub-64e72.cloudfunctions.net/bankIdSignIn?socialId=${socialId}&returnAddress=${returnAddress}&email=${email}&encryptedSocialId=${encryptedSocialId}&role=${role}&agreementId=${agreementId}&sign=agentSignature` //&phoneNumber=${phoneNumber}`
    )
      .then((response) => response.json())
      .then((data) => (window.location.href = data.url))
      .catch((error) => console.error(error));

    LoginWithCloudFunction();
    setLoading(false);
  };

  const LoginWithCloudFunction = () => {
    // const [searchParams] = useSearchParams();
    // const customToken = searchParams.get("customToken");
    const auth = getAuth();

    if (customToken) {
      console.log("Signing in with custom token:", customToken);
      signInWithCustomToken(auth, customToken)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log("User signed in:", user, user.email);
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error("Error signing in with custom token:", error);
        });
    }
  };

  const handleEmail = (value) => {
    setEmail(value);
    testEmail();
  };

  useEffect(() => {
    console.log("Logging in with", customToken);
    LoginWithCloudFunction();
  }, [customToken]);

  return (
    <>
      <br></br>
      <br></br>
      <Modal
        // open={ready && openSignup && userRole === "none"}
        open={openSignup}
        // open={true}
        // onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{ style: { backdropFilter: "blur(0px)", position: "absolute", top: "0", left: "0" } }}
        style={{ display: "grid", justifyContent: "center", alignContent: "center" }}
      >
        <Grid
          className="standardModal"
          container
          style={{ width: "400px" }}
          // style={{ padding: "20px", background: "linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5))", borderRadius: "10px" }}
          spacing={4}
          xs={12}
        >
          {!emailOk ? <TextField required label="Email" value={email} onChange={(e) => handleEmail(e.target.value)} fullWidth /> : ""}
          <div style={{ display: "grid", gap: "10px", width: "100%", marginTop: "20px", fontFamily: "Avenir" }}>
            <h7>Verify your identity with BankID</h7>
            <TextField
              required
              label="Personnummer"
              placeholder="YYYYMMDDXXXX"
              //   value={socialId}
              onChange={(e) => handleSocialId(e.target.value)}
              fullWidth
            />
            <div style={{ width: "100%", overflow: "scroll", opacity: "0.2" }}>Encrypted: {encryptedSocialId} </div>
            <Button disabled={!socialId || socialId.length !== 12} onClick={() => handleSignIn()} variant="contained">
              <span style={{ display: "flex", justifyContent: "right", overflow: "hidden", borderRadius: "10px" }}>
                <img
                  width="60px"
                  height="auto"
                  src="https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2FBankId%2FBankID_logo.svg?alt=media&token=26fbec4d-1b7d-4410-b0b1-a4120e72c460"
                  //   src="https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2FBankId%2FBankID_logo_white.png?alt=media&token=1551e937-a7f8-4786-9006-208fdda54e23"
                />
              </span>{" "}
              Sign in {loading && <CircularProgress style={{ zoom: "0.7", marginLeft: "10px" }} />}
            </Button>
          </div>
        </Grid>
      </Modal>
    </>
  );
};

export default BankIdSignIn;
