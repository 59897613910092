/** @format */

import { CircularProgress, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import VenueForm from "../components/Forms/VenueForm";
import VenueCalendar from "../components/Sidebar/VenueCalendar";
import PieChartCenteredLabel from "../components/UI helpers/PieChartCenteredLabel";
import UIDrawer from "../components/UI helpers/UIDrawer";
import Drawer from "../components/UI helpers/UIDrawer";
import UpComingEvents from "../features/bookings/UpComingEvents";
import UpComingEventsFlat from "../features/bookings/UpComingEventsFlat";
import MiniMonth from "../features/MiniMonth";
import CreateEditor from "../features/owner/CreateEditor";
import EditorChip from "../features/owner/EditorChip";
import ShowOccupancyData from "../features/owner/ShowOccupancyData";
import { storeCurrentVenue } from "../features/venueFilter/venueFilterSlice";
import ChangeVenue from "../helpers/ChangeVenue";
import Frame1 from "../helpers/Frame1";
import { listenToOwnedVenuesBookings, OwnerVenuesListener, reloadCalendarBookings } from "../helpers/Listeners";
import SelectVenue from "../helpers/SelectVenue";
import ShowCurrentVenue from "../helpers/ShowCurrentVenue";
import VenueCard from "../helpers/VenueCard";
import EditIcon from "@mui/icons-material/Edit";
import AddBookings from "../components/MiscUIComponents/AddBookings";
import HelpTool from "../helpers/HelpTool";
import Helper from "../helpers/HelpTool";
import VenueOverviewDisplay from "../components/MiscUIComponents/VenueOverviewDisplay";
import CalendarOverView from "../components/Sidebar/CalendarOverView";

// const listener = () => {
//   listenToOwnedVenuesBookings();
// };

const OwnerHome = () => {
  const userRole = useSelector((state) => state.counter.userRole);
  const venueAccess = useSelector((state) => state.counter.venueAccess);
  const ownedVenues = useSelector((state) => state.counter.ownedVenues);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const [ownedVenueIndex, setOwnedVenueIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // function changeVenue = () => {
  //   reloadCalendarBookings(ownedVenues[ownedVenueIndex]);
  // };

  // listenToOwnedVenuesBookings();

  return (
    <div>
      <Grid container>
        <Grid item className="middle" xs={12} sm={9} spacing={4}>
          <Grid item className="venuesContainer" mx={4} mt={2} style={{ height: "1vh" }}>
            {venueAccess !== "none" &&
              venueAccess.map((venueid, index) => (
                <div
                  key={venueid}
                  onClick={() => {
                    setOwnedVenueIndex(index);
                    console.log(ownedVenues[index]);
                    dispatch(storeCurrentVenue(ownedVenues[index]));
                  }}
                >
                  {/* Render venue component or content here */}
                </div>
              ))}
          </Grid>

          <Grid item px={4}>
            <h5>{currentVenue ? <b>{currentVenue.venue}</b> : "Choose a Venue above!"}</h5>
          </Grid>

          <Grid item className="upcomingEventsContainer" style={{ height: "80vh" }}>
            <VenueCalendar />
          </Grid>
        </Grid>

        <Grid item className="right" xs={12} sm={3} style={{ height: "50vh" }}>
          <Grid
            item
            className="editorsContainer"
            p={3}
            style={{
              height: "20vh",
              borderRadius: "10px",
            }}
          >
            <PieChartCenteredLabel
              width="200px"
              height="100px"
              data={[
                { value: 5, label: "Transportation", color: "#00ffd499" },
                { value: 10, label: "Energy use", color: "#00ffd433" },
                { value: 15, label: "Waste", color: "#00ffd455" },
                { value: 20, label: "Water", color: "#00ffd477" },
                { value: 20, label: "Food and Catering", color: "#00ffd4bb" },
              ]}
              label="Emissions"
            />

            {userRole === "admin" && (
              <IconButton onClick={() => navigate("/Sustainability")} style={{ float: "right", opacity: "0.5" }}>
                <EditIcon />
              </IconButton>
            )}
          </Grid>

          <Grid
            item
            className="statsContainer"
            p={3}
            mt={2}
            style={{
              height: "auto",
              background: "#33333322",
              borderRadius: "10px",
            }}
          >
            <Grid container>
              <Grid item>
                <ShowOccupancyData />
              </Grid>
              <Grid item xs={12} mt={4}>
                <MiniMonth />
              </Grid>
            </Grid>
            <AddBookings currentVenue={currentVenue} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default OwnerHome;
