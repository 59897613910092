/** @format */

import React, { Fragment, useEffect, useRef } from "react";
import { SingleBookingContainer } from "./SingleBookingContainer";
import { Avatar, Grid, Slider, Tooltip } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { eachDayOfInterval, getDaysInMonth, startOfMonth, endOfMonth, format, set } from "date-fns";
import WhatDayIsIt from "../MiscUIComponents/WhatDayIsIt";
import getDay from "date-fns/getDay";
import { Modal } from "@mui/material";
import BookingFormGeneral from "../Forms/BookingFormGeneral";
import { useDispatch } from "react-redux";
import { storeCurrentVenueId } from "../../features/venueFilter/venueFilterSlice";
import FollowCursorTooltip from "../UI helpers/FollowCursorTooltip";

const MultiBookingsCalendar = () => {
  const [showArtistInfo, setShowArtistInfo] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [values, setValues] = useState([0]);
  const [cellWidth, setCellWidth] = useState("100px");
  const [cellHeight, setCellHeight] = useState("50px");
  const [currentBooking, setCurrentBooking] = useState(null);
  const [openBooking, setOpenBooking] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const accessibleVenues = useSelector((state) => state.counter.accessibleVenues);
  const multiVenueBookings = useSelector((state) => state.counter.multiVenueBookings);
  const cs = useSelector((state) => state.counter.currentSettings);
  const chosenVenues = useSelector((state) => state.counter.chosenVenues);
  const dispatch = useDispatch();
  const today = new Date().toLocaleDateString("sv", "SE");
  const month = cs.vcMonth;
  const year = cs.vcYear;
  const checkIfPast = (dateStr) => {
    // Expecting dateStr in "yyyy-mm-dd" format
    const [year, month, day] = dateStr.split("-").map(Number);
    // Create a Date object for the input date
    // Note: month is 0-indexed in JavaScript Date objects
    const inputDate = new Date(year, month - 1, day);

    // Get today's date and reset time to 00:00:00 to compare only dates
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Compare the input date with today
    return inputDate < today;
  };

  // Function that takes a date string (YYYY-MM-DD)
  function isSunday(dateString) {
    try {
      const [year, month, day] = dateString.split("-").map(Number);
      const date = new Date(year, month - 1, day);
      return date.getDay() === 0; // Returns true if Sunday, false otherwise
    } catch (error) {
      console.error("Invalid date format:", error);
      return false; // Returns false for invalid date strings
    }
  }

  const handleOpenBooking = (booking) => {
    setCurrentBooking(booking);
  };

  useEffect(() => {
    if (!currentBooking) return;
    console.log("currentBooking", currentBooking);
    dispatch(storeCurrentVenueId(currentBooking.venueid));
    setOpenBooking(true); // This is to be used when it's also switching currentVenue.
  }, [currentBooking]);

  useEffect(() => {}, [currentVenue]);

  const handleCloseBooking = () => {
    setCurrentBooking(null);
    setOpenBooking(false);
  };
  const dateRefs = useRef([]); // for scrolling to date
  const scrollToDate = (index) => {
    // Ensure the ref exists before scrolling
    if (dateRefs.current[index]) {
      dateRefs.current[index].scrollIntoView({
        behavior: "smooth",
        inline: "center", // horizontally center the element
      });
    }
  };
  //   const cellWidth = "50px";
  console.log("accessibleVenues", accessibleVenues);
  //   Filter bookings by date
  const tableBkg = [
    // "linearGradient(0deg, rgba(253, 16, 16, 0.1), rgba(252, 223, 7, 0.5))",
    // "linearGradient(0deg, rgba(255,255,255,0.1), rgba(0,0,0,0.5))",
    // "linearGradient(0deg, rgba(255,255,255,0.1), rgba(0,0,0,0.5))",
    // "#00ffd408",
    // "#00ffd403",
    // "#00ffd408",
    // "#00ffd403",
    // "#00ffd408",
  ];
  const filteredBookings = multiVenueBookings.filter((booking) => {
    // Convert the booking date to a JavaScript Date object.
    const bookingDate = new Date(booking.date);

    // Compare month and year:
    const monthMatches = bookingDate.getMonth() === cs.vcMonth;
    const yearMatches = bookingDate.getFullYear() === cs.vcYear;

    return monthMatches && yearMatches;
  });
  //   Sort bookings by date
  const sortedBookings = filteredBookings.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  });

  const datesInMonth = eachDayOfInterval({
    start: startOfMonth(new Date(year, month)), // Ensure `month` is 0-indexed
    end: endOfMonth(new Date(year, month)),
  }).map((day) => format(day, "yyyy-MM-dd"));

  const daysInMonth = eachDayOfInterval({
    start: startOfMonth(new Date(year, month)),
    end: endOfMonth(new Date(year, month)),
  }).map((date) => date.getDate());

  console.log("daysInMonth", daysInMonth);

  const handleCellWidthChange = (event, newValues) => {
    // setValues(newValues);
    setCellWidth(newValues);
  };
  const handleCellHeightChange = (event, newValues) => {
    setCellHeight(newValues);
  };

  const bookingsPerVenue = (venueid) => (
    <Fragment style={{ position: "relative" }}>
      {/* <Fragment style={{ display: "flex", flexDirection: "row" }}> */}
      <td width={cellWidth} height="cellHeight" className="mbcFixedCell">
        <Avatar alt="Venuehub" src={accessibleVenues.find((venue) => venue.venueid === venueid).venueImage} />
      </td>
      {datesInMonth.map((date, index) => (
        <td
          className="bookingCell"
          width={cellWidth}
          key={date} // Assuming each day is unique; otherwise, consider using the index or a unique identifier.
          style={{
            overflow: "hidden",
            background: "transparent",
            height: "auto",
            // borderRadius: "5px",
            textAlign: "center",
            color: "white",
            // display: "flex",
            flex: "0 0 auto", // Prevent the item from shrinking or growing
          }}
        >
          {/* <div>{format(new Date(date), "dd")}</div> */}
          <div style={{ opacity: checkIfPast(date) ? 0.3 : 1 }}>{miniBookingContainer(venueid, date)}</div>
        </td>
      ))}
    </Fragment>
  );

  const statusColor = (booking) =>
    booking.status === "Confirmed"
      ? "rgb(29, 185, 84,0.5)"
      : booking.status === "Pending Request"
      ? "rgb(255, 69, 0,0.5)"
      : "rgb(255, 165, 0,0.5";

  const miniBookingContainer = (venueid, date) =>
    multiVenueBookings.map(
      (booking) =>
        booking.date === date &&
        booking.venueid === venueid && (
          <Grid
            className="miniBookingContainer"
            key={booking.bookingid}
            item
            style={{
              position: "relative",
              background: "#111",
              width: cellWidth,
              height: cellHeight,
              textAlign: "center",
              color: "black",
              flex: "0 0 auto",
              border: `5px solid ${statusColor(booking)} `,
              borderRadius: "10px",
              overflow: "hidden",
            }}
            onClick={() => {
              handleOpenBooking(booking);
              //   setOpen(true);
              //   setBooking(booking);
            }}
          >
            {/* <div className="overViewArtist">{booking.artist.artist}</div> */}
            <div className="overViewLoadIn" style={{ background: "#00000099" }}>
              {booking.loadIn}
            </div>
            <div className="loadIn-loadOut"></div>
            <div className="overViewLoadOut" style={{ background: "#00000099" }}>
              {booking.loadOut}
            </div>
            <FollowCursorTooltip title={booking.artist.artist} placement="right">
              <div
                className="miniBookingContainerImg"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: `${parseInt(cellWidth, 10) - 10}px`,
                  height: "100%",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center", // centers horizontally
                  alignItems: "center",
                  borderBottom: `5px solid ${statusColor(booking)} `,
                }}
              >
                <img src={booking.artist.image} height="100px" width="auto" />
              </div>
            </FollowCursorTooltip>
          </Grid>
        )
    );

  useEffect(() => {
    scrollToDate(format(new Date(), "dd")); // Scroll to today's date
  }, [multiVenueBookings]);

  //   SCROLLING UP DOWN
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const onWheel = (evt) => {
      // Prevent the default vertical scroll behavior
      evt.preventDefault();
      // Adjust the horizontal scroll position
      scrollContainer.scrollLeft += evt.deltaY;
    };
    scrollContainer.addEventListener("wheel", onWheel, { passive: false });
    return () => {
      scrollContainer.removeEventListener("wheel", onWheel);
    };
  }, []);

  return (
    <Grid
      container
      xs={12}
      p={4}
      style={{
        overflow: "hidden", // Clipping any overflow
        borderRadius: "10px",
      }}
    >
      {/* <Slider
        className="cellWidthSlider"
        value={cellWidth}
        onChange={handleCellWidthChange}
        min={50}
        max={100}
        step={1}
        track="inverted"
        size="small"
        style={{ width: "150px" }}
      />
      <Slider
        className="cellHeightSlider"
        value={cellHeight}
        onChange={handleCellHeightChange}
        min={50}
        max={100}
        step={1}
        track="inverted"
        size="small"
        style={{ width: "150px" }}
      /> */}
      <div style={{ overflow: "scroll", width: "100vw" }} className="overViewGrid" ref={scrollContainerRef}>
        <table width="100vw">
          <tr style={{ display: "flex", flexDirection: "row", flex: "0 0 auto" }}>
            <td width={cellWidth} className="mbcFixedCell">
              Venue
            </td>
            {datesInMonth.map((date, index) => (
              <td
                width={cellWidth}
                style={{ textAlign: "center", opacity: checkIfPast(date) ? 0.1 : 1 }}
                ref={(el) => (dateRefs.current[index] = el)}
              >
                <div style={{ color: isSunday(date) ? "#ff00008f" : "#777" }}>
                  <WhatDayIsIt date={date} numberOfLetters={3} />
                </div>
                <div> {format(new Date(date), "dd")}</div>
              </td>
            ))}
          </tr>
        </table>
        <table width="100vw">
          <tr>
            {console.log("accessibeleVenues MOFU", accessibleVenues)}
            {chosenVenues &&
              chosenVenues.map((venueid, i) => (
                // <Tooltip title={accessibleVenues.find((venue) => venue.venueid === venueid).venue}>
                <tr
                  className="bookingRow"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flex: "0 0 auto",
                    background: tableBkg[i],
                    // border: "10px solid transparent",
                  }}
                >
                  {bookingsPerVenue(venueid)}
                </tr>
                // </Tooltip>
              ))}
          </tr>
        </table>
      </div>
      <Modal className="bookingFormModal" open={openBooking}>
        {currentBooking !== [] && <BookingFormGeneral bookingDataProp={currentBooking} handleClose={handleCloseBooking} noNumber />}
      </Modal>
    </Grid>
  );
};

export default MultiBookingsCalendar;
