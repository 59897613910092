/** @format */

import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import shortText from "../../helpers/shortText";
import { storeCurrentBooking, storeCurrentProduct, storeCurrentVenue } from "../venueFilter/venueFilterSlice";
import { useDispatch } from "react-redux";
import { set } from "date-fns";
import store from "../../app/store";
import { db } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import CreateEventNortic from "./CreateEventNortic";

const SetTicketCategoriesBookingNortic = ({ bookingData, setBookingData, saveBooking }) => {
  console.log("Ticket bookingData", bookingData);
  const dispatch = useDispatch();
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentProduct = useSelector((state) => state.counter.currentProduct);
  const [productData, setProductData] = useState(currentProduct);
  console.log("productDataMofu", productData);
  console.log("currentProductMofu", currentProduct);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [newTicketCategory, setNewTicketCategory] = useState({ organizerId: currentVenue.ticketVenueId });
  const [ticketCategories, setTicketCategories] = useState([]); // This holds all ticketCategories with data from Nortic merged with local data
  const [totalMaxAvailable, setTotalMaxAvailable] = useState(0);
  const [openAdvancedSettings, setOpenAdvancedSettings] = useState(false);
  const [ticketEventId, setTicketEventId] = useState(bookingData.ticketEventId);
  const date = bookingData.date + "T" + bookingData.show + ":00+01:00";
  const payload = {
    organizerId: currentVenue.ticketVenueId,
    name: bookingData.artist.artist,
    status: "DRAFT",
    eventCategory: "THEATER",
    shows: [
      {
        name: bookingData.artist.artist,
        startDateTime: date,
        maxAvailable: totalMaxAvailable,
        ticketCategories: ticketCategories
          .filter((category) => category.selected)
          .map(({ selected, sortWeight, id, organizerId, description, ...rest }) => rest),
      },
    ],
  };

  // If product Changes
  useEffect(() => {
    getCurrentProduct();
  }, [bookingData.productid]);

  function getCurrentProduct() {
    console.log("currentVenue", currentVenue);
    const tmp = currentVenue.products.filter((product) => product.productid === bookingData.productid);

    setTicketCategories(tmp[0].productTicketCategories ? tmp[0].productTicketCategories : []);
    // setBookingData({ ...bookingData, ticketCategories: tmp[0].productTicketCategories });
    console.log("currentProduct Alabama", tmp[0]);
  }

  // This holds the selected ticketCategories array of IDs
  async function getTicketCategories(tmpBookingData) {
    // alert("getTicketCategories");
    setLoading(true);
    // const data = { organizerId: newTicketCategory.organizerId };
    const data = { organizerId: currentVenue.ticketVenueId };
    try {
      // const result = await axios.post("http://127.0.0.1:5001/venuehub-64e72/europe-west3/getTicketCategoriesNortic", data);
      const result = await axios.post("https://europe-west3-venuehub-64e72.cloudfunctions.net/getTicketCategoriesNortic", data);
      const dataFromNortic = result.data.values;
      const dataFromProduct = currentProduct.productTicketCategories || [];

      const dataFromBooking = tmpBookingData.ticketCategories || bookingData.ticketCategories || [];

      //   If no local data is found, set ticketCategories to data from Nortic and selectedTicketCategories to currentVenue.ticketCategories
      if (dataFromProduct.length === 0 && dataFromBooking.length === 0) {
        setTicketCategories(dataFromNortic);
        // return;
      }

      const mergedData = dataFromNortic.map((category) => {
        const productCategory = dataFromProduct.find((item) => item.id === category.id) || null;
        const bookingCategory = bookingData.ticketCategories.find((item) => item.id === category.id) || null;
        // If local data is found, merge it with data from Nortic
        if (productCategory || bookingCategory) {
          return { ...category, ...productCategory, ...bookingCategory };
        } else {
          return category;
        }
      });
      console.log("mergedData", mergedData);
      setTicketCategories(mergedData);
      // setBookingData({ ...bookingData, ticketCategories: mergedData });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getTicketCategories(bookingData);
  }, []);

  const handleChange = (event) => {
    handleCategoryChange(Number(event.target.value), "selected", event.target.checked);
  };

  const handleCategoryChange = (id, field, value) => {
    // alert(id + " " + field + " " + value);
    const updatedCategories = ticketCategories.map((category) => {
      if (category.id === id) {
        return { ...category, [field]: value };
      } else {
        return category;
      }
    });
    console.log("updatedCategories BBBBB", updatedCategories);
    console.log("currentProduct", currentProduct);

    setTicketCategories(updatedCategories);
    const tmpBookingData = { ...bookingData, ticketCategories: updatedCategories.filter((category) => category.selected) };
    setBookingData(tmpBookingData);
    console.log("BookingData THIS", tmpBookingData);
  };

  useEffect(() => {
    if (!ticketCategories || (ticketCategories && ticketCategories.length === 0)) return;
    // if (!selectedTicketCategories || (selectedTicketCategories && selectedTicketCategories.length === 0)) return;
    // const tmpTicketCategories = ticketCategories.filter((category) => selectedTicketCategories.includes(category.id));
    const tmpTicketCategories = ticketCategories.filter((category) => category.selected);
    const tmpProductData = { ...productData, productTicketCategories: tmpTicketCategories };
    setProductData(tmpProductData);
    setTotalMaxAvailable(tmpTicketCategories.reduce((acc, category) => acc + category.maxAvailable, 0));
  }, [ticketCategories]);

  useEffect(() => {
    getTicketCategories();
  }, [currentVenue]);

  useEffect(() => {
    // If Nortic event is created, save booking in order to store ticketEventId. ticketEventId is set to "-" if no event is created
    if (bookingData.ticketEventId === ticketEventId) return; // If no change, return
    saveBooking();
  }, [bookingData.ticketEventId]);

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "80vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  };

  return (
    <div style={{ display: "flex-wrap" }}>
      {console.log("currentProduct", currentProduct)}
      {console.log("productData", productData)}

      <h7>Ticket categories for {currentProduct.product}</h7>
      {/* <Button onClick={() => setBookingData({ ...bookingData, ticketEventId: "-" })}>Happen</Button> */}
      <FormControlLabel
        label="Edit Mode"
        control={<Switch checked={editMode} onChange={() => setEditMode(!editMode)} />}
        style={{ marginLeft: "55px" }}
      />
      <FormGroup
      // onChange={(event) => handleChange(event)}
      >
        {loading && <CircularProgress />}
        {ticketCategories &&
          ticketCategories.length &&
          ticketCategories
            // .sort((a, b) => a.name.localeCompare(b.name, "sv"))
            .map((category) => (
              <>
                {(editMode || category.selected) && (
                  <Grid container key={category.id} spacing={2} mt={category.selected ? 2 : 0}>
                    {/* Category Label with Switch */}
                    <Grid
                      item
                      xs={12} // Full width on extra-small screens
                      sm={6} // Takes 6 columns (50%) on small screens
                      md={4} // Takes 4 columns (33.33%) on medium screens
                    >
                      <FormControlLabel
                        label={category.name}
                        control={<Switch checked={category.selected || false} value={category.id} />}
                        onChange={(event) => handleChange(event)}
                      />
                    </Grid>

                    {/* Conditional Fields */}
                    {category.selected && (
                      <>
                        <Grid item xs={12}>
                          <Grid container justifyContent="flex-end">
                            <Grid container xs={12} sm={8} spacing={2}>
                              <Grid item xs={12} md={6}>
                                <TextField
                                  label="Price"
                                  size="small"
                                  // value={ticketCategories.find((item) => item.id === category.id).price || 0}
                                  value={category.price || 0}
                                  onChange={(e) => handleCategoryChange(category.id, "price", Number(e.target.value))}
                                  sx={{
                                    background: !ticketCategories.find((item) => item.id === category.id).price && "orange",
                                    borderRadius: "10px",
                                  }}
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={12} md={6}>
                                <TextField
                                  label="Max Available"
                                  size="small"
                                  value={ticketCategories.find((item) => item.id === category.id).maxAvailable || 0}
                                  onChange={(e) => handleCategoryChange(category.id, "maxAvailable", Number(e.target.value))}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  label="Description"
                                  size="small"
                                  fullWidth
                                  value={ticketCategories.find((item) => item.id === category.id).description || ""}
                                  onChange={(e) => handleCategoryChange(category.id, "description", e.target.value)}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Divider />
                  </Grid>
                )}
              </>
            ))}
      </FormGroup>
      <Grid container xs={12}>
        {bookingData.ticketEventId === "-" && (
          <Grid item xs={12} md={4}>
            {ticketCategories.length > 0 && (
              <CreateEventNortic payload={payload} bookingData={bookingData} setBookingData={setBookingData} />
            )}
          </Grid>
        )}
      </Grid>
      {console.log("Payload", payload)}
      <a href="https://www.nortic.se/admin" target="_blank">
        <div
          style={{ padding: "20px" }}
          // onClick={() => setOpenAdvancedSettings(true)}
        >
          <img
            style={{ padding: "15px" }}
            width="120px"
            height="auto"
            src="https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2FTicket%20service%20logoypes%2FNortic-logotyp.png?alt=media&token=241b7bd7-72ac-43c9-a1b5-be76ccf36b44"
          />
          <h7>Advanced settings</h7>
        </div>
      </a>
      {/* <Modal open={openAdvancedSettings}>
        <Box sx={boxStyle}>
          <iframe id="ticketsIframe" width="100%" height="90%" src="https://nortic.se/admin" title="Nortic"></iframe>
          <Button onClick={() => setOpenAdvancedSettings(false)}>Close</Button>
        </Box>
      </Modal> */}
    </div>
  );
};

export default SetTicketCategoriesBookingNortic;

// const payload = {
//   organizerId: 3302,
//   name: "Metallica",
//   status: "DRAFT",
//   eventCategory: "THEATER",
//   shows: [
//     {
//       name: "Shrek",
//       startDate: "2025-01-21T14:42:30+01:00",
//       maxAvailable: 1000,
//       ticketCategories: [
//         {
//           name: "Ordinarie",
//           price: 100,
//           maxAvailable: 1000,
//         },
//       ],
//     },
//   ],
// };
