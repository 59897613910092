/** @format */

import {
  Autocomplete,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import shortText from "../../helpers/shortText";
import { storeCurrentVenue } from "../venueFilter/venueFilterSlice";
import { useDispatch } from "react-redux";
import { set } from "date-fns";
import store from "../../app/store";
import { db } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";

const ShowTicketCategoriesNortic = () => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const settings = { size: "small", width: 3, margin: "normal" };
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // const [newTicketCategory, setNewTicketCategory] = useState({ organizerId: currentVenue.ticketVenueId });
  const [newTicketCategory, setNewTicketCategory] = useState({ organizerId: currentVenue.ticketVenueId });
  const [ticketCategories, setTicketCategories] = useState([]);
  const [selectedTicketCategories, setSelectedTicketCategories] = useState(
    (currentVenue.ticketCategories && currentVenue.ticketCategories) || []
  );
  async function getTicketCategories() {
    setLoading(true);
    const data = { organizerId: currentVenue.ticketVenueId };
    console.log("Data", data);
    try {
      // console.log("Body", body);

      // const result = await axios.post("http://127.0.0.1:5001/venuehub-64e72/europe-west3/getTicketCategoriesNortic", data);
      const result = await axios.post("https://europe-west3-venuehub-64e72.cloudfunctions.net/getTicketCategoriesNortic", data);
      console.log("Ticket Categories", result.data);
      setTicketCategories(result.data.values);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!newTicketCategory.organizerId) return;
    getTicketCategories();
  }, [newTicketCategory.organizerId]);

  const handleChange = (event) => {
    if (event.target.checked && !selectedTicketCategories.includes(event.target.value)) {
      // Add the item if checked
      setSelectedTicketCategories([...selectedTicketCategories, Number(event.target.value)]);
    } else {
      // Remove the item if unchecked
      setSelectedTicketCategories(selectedTicketCategories.filter((category) => category !== Number(event.target.value)));
    }
    console.log("selectedTicketCategories", selectedTicketCategories);
  };

  const isChosen = (id) => {
    if (selectedTicketCategories.includes(id)) return true;
    else return false;
  };

  useEffect(() => {
    dispatch(storeCurrentVenue({ ...currentVenue, ticketCategories: selectedTicketCategories }));
    // update the parameter ticketCategories in the document with currentVenue.venueid in collection Venues
    async function updateTicketCategories() {
      setLoading(true);
      await updateDoc(doc(db, "Venues", currentVenue.venueid), {
        ticketCategories: selectedTicketCategories,
      });
      setLoading(false);
    }
    updateTicketCategories();
  }, [selectedTicketCategories]);

  useEffect(() => {
    setTicketCategories([]);
    setNewTicketCategory({ organizerId: currentVenue.ticketVenueId });
    getTicketCategories();
  }, [currentVenue]);

  return (
    <div style={{ display: "flex-wrap" }}>
      <h7>Ticket categories at {currentVenue.venue}</h7>
      <FormGroup onChange={(event) => handleChange(event)}>
        <table className="">
          {ticketCategories.length &&
            ticketCategories.map((category) => (
              <tr key={category.id}>
                <td style={{ opacity: selectedTicketCategories.includes(category.id) ? 1 : 0.5 }}>
                  <FormControlLabel
                    label={category.name}
                    control={
                      //   <Switch checked={isChosen(category.id)} onChange={(e) => handleChange(category.id, category.name, e.target.checked)} />
                      <Switch checked={selectedTicketCategories.includes(category.id)} value={category.id} size="small" />
                    }
                  />
                </td>
                {/* <td>{category.name}</td> */}
              </tr>
            ))}
        </table>
      </FormGroup>
      {/* {selectedTicketCategories.length && (
          <div>
            Selected:{" "}
            {selectedTicketCategories.map((category) => (
              <div key={category}>{category}</div>
            ))}
          </div>
        )} */}
    </div>
  );
};

export default ShowTicketCategoriesNortic;
