/** @format */

import { Box, FormControlLabel, Grid, MenuItem, Modal, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import StandardTimesSlider from "../components/MiscUIComponents/StandardTimesSlider";
import { checkCollision } from "./checkCollision";
import Button from "@mui/material/Button";
import { db } from "../firebase";
import VhDatePicker from "../components/MiscUIComponents/DatePicker";
import { useSelector } from "react-redux";
import { doc, updateDoc } from "firebase/firestore";
import TimeSlot from "./TimeSlot";
import { parse, format, addMinutes, subMinutes } from "date-fns";

const CorrectCollisions = ({ openCorrectCollision, setOpenCorrectCollision, booking, collidingArray, setCollidingArray, collision }) => {
  if (!collidingArray) return;
  console.log("collidingArray", collidingArray);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  // const [collision, setCollision] = useState(false);
  const formlock = false;
  const handleClose = () => {
    setOpenCorrectCollision(false);
  };

  const handleChange = async (bookingData) => {
    let tmpArray = collidingArray.map((b) => {
      if (b.bookingid === bookingData.bookingid) {
        // change all other times to loadIn and loadOut using dataWithAdjustedTimes
        return { ...b, ...dataWithAdjustedTimes(bookingData) }; // Create a new object with updated values
      }
      return b; // Keep other objects unchanged
    });
    await sortCollidingArray(tmpArray);
    setCollidingArray(tmpArray);
  };

  function adjustTime(time, adjustment) {
    if (!time || !adjustment) {
      return time;
    }

    try {
      const parsedTime = parse(time, "HH:mm", new Date());

      let adjustmentMinutes = 0;
      if (adjustment.includes(":")) {
        const [hours, minutes] = adjustment.split(":");
        adjustmentMinutes = parseInt(hours) * 60 + parseInt(minutes);
      } else {
        adjustmentMinutes = parseInt(adjustment);
      }

      if (adjustment.startsWith("-")) {
        const adjustedTime = subMinutes(parsedTime, adjustmentMinutes);
        return format(adjustedTime, "HH:mm");
      } else {
        const adjustedTime = addMinutes(parsedTime, adjustmentMinutes);
        return format(adjustedTime, "HH:mm");
      }
    } catch (error) {
      console.error("Error adjusting time:", error);
      return time;
    }
  }

  function dataWithAdjustedTimes(bookingData) {
    const getIn = adjustTime(bookingData.loadIn, "-01:00");
    const loadIn = bookingData.loadIn;
    const soundcheck = adjustTime(bookingData.loadIn, "00:30");
    const doors = adjustTime(bookingData.loadIn, "01:00");
    const show = adjustTime(bookingData.loadIn, "01:30");
    const loadOut = bookingData.loadOut;
    const curfew = adjustTime(bookingData.loadOut, "01:00");

    return {
      ...bookingData,
      getIn,
      loadIn,
      soundcheck,
      doors,
      show,
      loadOut,
      curfew,
    };
  }

  async function storeChanges() {
    for (let item of collidingArray) {
      await handleUpdate(item);
    }
    setOpenCorrectCollision(false);
  }

  const handleUpdate = async (bookingData) => {
    await updateDoc(doc(db, "Bookings", bookingData.bookingid), {
      date: bookingData.date,
      getIn: bookingData.getIn,
      loadIn: bookingData.loadIn,
      soundcheck: bookingData.soundcheck,
      doors: bookingData.doors,
      show: bookingData.show,
      loadOut: bookingData.loadOut,
      curfew: bookingData.curfew,
      // penciled: bookingData.penciled,
    });
  };

  function sortCollidingArray(arrayData) {
    if (!arrayData) return;
    if (arrayData.some((item) => !item.penciled)) {
      sortCollidingArrayByPenciled(arrayData);
    }
    const sortedArray = [...arrayData]
      .sort((a, b) => {
        const dateA = new Date(a.created);
        const dateB = new Date(b.created);
        if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
          // Handle invalid dates (e.g., log an error, skip comparison)
          console.error("Invalid date in collidingArray:", a.created, b.created);
          return 0; // Or handle in a way appropriate for your application.
        }
        return dateA - dateB; // Sort by date difference
      })
      .map((item, index) => ({
        ...item,
        penciled: index + 1, // Add penciled property with order (index + 1)
      }));
    setCollidingArray(sortedArray);
  }

  function sortCollidingArrayByPenciled(arrayData) {
    const sortedArray = [...arrayData].sort((a, b) => {
      return a.penciled - b.penciled; // Sort by penciled order
    });
    setCollidingArray(sortedArray);
  }

  return (
    <Modal open={openCorrectCollision} onClose={handleClose}>
      <Box
        sx={modalStyle}
        // className="correctCollisionModal"
      >
        <div>
          {collision && <h2>Correct Collision ⚠️</h2>}
          {!collision && <h2>Corrected ☑️</h2>}
          {collision
            ? "There's a time collision with another booking. Please adjust the time or date."
            : "All good, no collision. Click 'Store changes' to save."}
          {collidingArray.map((item) => (
            <Grid container mt={1} xs={12}>
              {item && (
                <>
                  <Grid item xs={12} mt={1}>
                    <h7>{item.artist.artist}</h7>
                    <h5>
                      {item.loadIn} - {item.loadOut}
                    </h5>
                  </Grid>

                  <Grid item xs={9}>
                    <VhDatePicker
                      currentVenue={currentVenue}
                      bookingData={item}
                      setBookingData={handleChange}
                      formlock={item.status === "Confirmed" ? true : false}
                    />
                  </Grid>

                  <Grid item xs={1} mt={1}>
                    <Select value={item.penciled} onChange={() => handleChange(item)} size="small">
                      {collidingArray.map((c) => (
                        <MenuItem key={c.created} value={c.penciled}>
                          {c.penciled}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={12} style={{ opacity: item.status === "Confirmed" ? "0.5" : "1" }}>
                    {/* <StandardTimesSlider
                      collision={collision}
                      productData={item}
                      setProductData={handleChange}
                      TextFields={true}
                      simple={true}
                      formlock={item.status === "Confirmed" ? true : false}
                    /> */}
                    <TimeSlot
                      item={item}
                      handleChange={handleChange}
                      formlock={item.status === "Confirmed" ? true : false}
                      collision={collision}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          ))}
          <Grid item xs={2} mt={1}>
            <Button disabled={collision} onClick={() => storeChanges()} variant="contained">
              Store changes
            </Button>
          </Grid>
          <Grid item xs={12} mt={4}></Grid>
        </div>
      </Box>
    </Modal>
  );
};

export default CorrectCollisions;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "80vh",
  background: "linear-gradient(0deg, #111111cc, #222222cc)",
  backdropFilter: "blur(15px)",
  border: "1px solid #444",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  borderRadius: "10px",
};
