/** @format */

import React, { useRef, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControl, Grid, Paper } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { createSmallId, db, login, useAuth } from "../../firebase";
import { Prev } from "react-bootstrap/esm/PageItem";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useSelector } from "react-redux";
import { resetPassword } from "./resetPassword";
import PasswordReset from "./PasswordResetMUI";
import { useNavigate } from "react-router-dom";

const LoginMUI = ({ setOpenLogin, openLogin, userName }) => {
  const currentUserId = useSelector((state) => state.counter.currentUserId);
  const [credentials, setCredentials] = useState({});
  const [loading, setLoading] = useState(false);
  const [hidePassword, setHidePassword] = useState(false);
  const [openReset, setOpenReset] = useState(false);
  const [message, setMessage] = useState("Enter credentials to log in");
  const [msgColor, setMsgColor] = useState("white");
  const USR = useAuth();
  const navigate = useNavigate();

  function handleClose() {
    setOpenLogin(false);
  }

  async function storeLoginAttempt(msg) {
    let ip;
    await fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => (ip = data.ip));

    const id = createSmallId();
    await setDoc(doc(db, "loginAttempts", id), {
      email: credentials.email,
      msg,
      ip,
    });
  }

  async function handleLogin() {
    try {
      setLoading(true);
      await login(credentials["email"], credentials["password"]);
      // await console.log("USRID", currentUserId);
      setMsgColor("#00ffd4");
      setMessage("Successfully logged in");
      storeLoginAttempt("Successfully logged in");
    } catch {
      // alert("Failed to sign in");
      setMsgColor("red");
      setMessage("Failed to sign in");
      storeLoginAttempt("Failed to sign in");
    }

    setLoading(false);

    setTimeout(() => {
      handleClose();
      if (window.location.pathname === "/Home" || window.location.pathname === "/Login") {
        navigate("/Owner/Home");
      }
    }, 2000);

    // const docSnap = await getDoc(doc(db, "Users"));

    // var user = getAuth().currentUser;

    // handleClose();

    // console.log('User Role----->  : ', await CheckUserRole());
    // await CheckUserRole() === "owner" ? console.log(await CheckUserRole()) : console.log('no Owner')
    // await CheckUserRole() === "agent" ? console.log(await CheckUserRole()) : console.log('no Agent')
    // await CheckUserRole() === "editor" ? console.log(await CheckUserRole()) : console.log('no Venue')
  }

  return (
    <div>
      <Dialog
        open={openLogin}
        // open={true}
        onClose={handleClose}
        PaperProps={{
          style: { backgroundColor: "#ffffff11", boxShadow: "2px 2px 10px black" },
        }}
      >
        <Paper
          className="loginPaper"
          style={{
            backgroundImage: "none",
            background: "transparent",
            WebkitBackdropFilter: "blur(20px)",
            backdropFilter: "blur(20px)",
            padding: "30px",
          }}
        >
          {/* <form> */}
          <Grid container gap={2} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <DialogTitle sx={{ background: "" }}>
                <h2>Sign in</h2>
              </DialogTitle>
            </Grid>
            <DialogContent>
              <Grid item sx={{ textAlign: "center" }}>
                {loading && (
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CircularProgress />
                    <div> Logging in...</div>
                  </div>
                )}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  onChange={(e) => setCredentials((prev) => ({ ...prev, email: e.target.value }))}
                  onBlur={(e) => setCredentials((prev) => ({ ...prev, email: e.target.value }))}
                  margin="normal"
                  id="name"
                  label="Email Address"
                  type="email"
                  defaultValue={userName && userName}
                  fullWidth
                  autoFocus
                />
              </Grid>
              {!hidePassword && (
                <Grid item sx={{ width: "100%" }} xs={12}>
                  <TextField
                    onChange={(e) => setCredentials((prev) => ({ ...prev, password: e.target.value }))}
                    margin="normal"
                    id="name"
                    label="Password"
                    type="password"
                    fullWidth
                  />
                  <div style={{ color: msgColor }}>{message}</div>
                </Grid>
              )}
            </DialogContent>
          </Grid>
          <DialogActions sx={{ background: "transparent" }}>
            <Button onClick={handleLogin} variant="contained">
              Login
            </Button>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            {/* <Button onClick={sendResetPasswordLink}>Forgot Password?</Button> */}
            <Button onClick={() => setOpenReset(true)}>Forgot Password?</Button>
          </DialogActions>
          {/* </form> */}
        </Paper>
        <PasswordReset setOpenReset={setOpenReset} openReset={openReset} currentEmailField={credentials["email"]} />
      </Dialog>
    </div>
  );
};

export default LoginMUI;
