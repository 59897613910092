/** @format */

import React, { useEffect, useState } from "react";
import { Button, Modal, Box, TextField, Typography, Grid, Select, MenuItem, Switch, FormControlLabel } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import CreateEditor from "./CreateEditor";
import { useSelector } from "react-redux";
import { db } from "../../firebase";
import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import axios from "axios";
import CreateUser from "./CreateUser";
import { set } from "date-fns";
import { number } from "prop-types";
import { current } from "@reduxjs/toolkit";
import store from "../../app/store";
import { bigFirstLetter } from "../../helpers/bigFirstLetter";

const HandleUsers = ({}) => {
  const currentUser = useSelector((state) => state.counter.currentUser);
  const isOwner = currentUser.role === "owner";
  console.log("currentUser", currentUser);
  // ---------------------------------------------------------
  // This is the only place where we set abilities ans roles
  const allAbilities = [
    "Itinerary",
    "View",
    "Edit products/modules",
    "Edit bookings",
    "Sign agreements",
    "Handle Settlements",
    "Event Polls",
    ...(!isOwner ? ["Handle Users"] : []),
  ];
  const allRoles = ["owner", "editor", "viewer", "participant", "none"];
  // ---------------------------------------------------------
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [accessibleVenues, setAccessibleVenues] = useState([{}]);
  console.log("accessibleVenues", accessibleVenues);
  const [userData, setUserData] = useState([
    {
      firstName: "",
      lastName: "",
      phone: "",
      email: "mdernulf",
      venueAccess: ["avl7xq6nz4akxpfkhagnhvth32vzt2jz", "ggwih5vttjkjvmgr5y2ooi4eno5zayxs"],
      userid: "",
      password: "korven",
      role: "owner",
      abilities: [
        {
          venueAccess: "avl7xq6nz4akxpfkhagnhvth32vzt2jz",
          abilities: ["View", "Edit products/modules"],
        },
        {
          venueAccess: "ggwih5vttjkjvmgr5y2ooi4eno5zayxs",
          abilities: ["View", "Edit products/modules", "Edit bookings"],
        },
      ],
    },
  ]);

  const venueAccess = useSelector((state) => state.counter.venueAccess);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = async (key, value) => {
    if (key === "socialId") {
      console.log(key, value);
      console.log("encrypted:");
      const encryptedString = await encryptString(value);

      console.log("encrypted:", encryptedString);
      setUserData((prev) => ({ ...prev, socialId: encryptedString }));
    }
  };

  const handleChangeAbility = (user, ability, venueid) => {
    console.log("testar", user, ability, venueid);
    const oldValue = checkAbilityPerVenue(user, ability, venueid);
    const newValue = !oldValue;

    const index = userData.findIndex((item) => item.email === user.email);
    let tmp = { ...userData[index] };
    let tmpUserData = [...userData];

    // If user has no abilities for this venue, add the venueAccess and the first ability
    // Lets the user gain access to new a venue.
    if (!tmpUserData[index].venueAccess.includes(venueid)) {
      tmpUserData[index].venueAccess.push(venueid);
      tmpUserData[index].abilities.push({ venueAccess: venueid, abilities: [ability] });
      setUserData([...tmpUserData]);
      storeUserData(tmpUserData[index]);
      return;
    }

    // Add or remove the ability
    user.abilities.map((item) => {
      if (item.venueAccess === venueid) {
        if (newValue === true) {
          // Add ability
          tmp.abilities.filter((a) => a.venueAccess === venueid)[0].abilities.push(ability);
          const numberOfAbilities = tmp.abilities.filter((a) => a.venueAccess === venueid)[0].abilities.length;
          tmpUserData[index] = tmp;
          storeUserData(tmpUserData[index]);
          setUserData([...tmpUserData]);
        } else {
          // Remove ability
          tmp.abilities.filter((a) => a.venueAccess === venueid)[0].abilities = item.abilities.filter((a) => a !== ability);
          const numberOfAbilities = tmp.abilities.filter((a) => a.venueAccess === venueid)[0].abilities.length;

          // If the user has no abilities left for this venue...
          if (numberOfAbilities === 0) {
            // Remove the venueAccess
            const i = tmpUserData[index].venueAccess.indexOf(venueid);
            tmpUserData[index].venueAccess.splice(i, 1); // Removes 1 element at the found i (since "index" is already used)
            // Also remove the ability object
            const indexAbility = tmpUserData[index].abilities.findIndex((a) => a.venueAccess === venueid);
            tmpUserData[index].abilities.splice(indexAbility, 1);
          }
          tmpUserData[index] = tmp;
          setUserData([...tmpUserData]);
          console.log("tmpUserData", tmpUserData[index]);
          storeUserData(tmpUserData[index]);
        }
      }
    });
  };

  async function storeUserData(data) {
    console.log("DATA", data);
    console.log("storeUserData");
    let tmp = { ...data };
    if (tmp.uid) {
      tmp.userid = tmp.uid; // if "uid" is used make it "userid" instead...
      delete tmp.uid; //...and remove uid
    }
    tmp.role = tmp.role.toLowerCase(); // make sure role is stored in lowercase
    const userRef = doc(db, "Users", tmp.userid);
    await setDoc(userRef, tmp);
  }

  async function encryptString(value) {
    const body = { string: value };
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/encryptString`, body);
    return response.data.encryptedString;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to Firebase
    handleClose();
  };

  useEffect(() => {
    let tmpUsers = [];
    if (!venueAccess || venueAccess.length === 0) return;
    async function getData() {
      try {
        const venuePromises = venueAccess.map((venueid) => getDoc(doc(db, "Venues", venueid)));
        const venueDocs = await Promise.all(venuePromises);
        const tmp = venueDocs.map((docSnap) => docSnap.data()).filter(Boolean);
        setAccessibleVenues(tmp);
      } catch (error) {
        console.error("Error fetching venue data: ", error);
      }
    }
    getData();

    async function getUsersWithAccess() {
      for (const venueid of venueAccess) {
        let tmp = [];
        const querySnapshot = await getDocs(query(collection(db, "Users"), where("venueAccess", "array-contains", venueid)));
        tmp = querySnapshot.docs.map((doc) => doc.data());
        tmpUsers = tmpUsers.concat(tmp);
      }
      const result = Array.from(new Map(tmpUsers.map((user) => [user.email, user])).values());

      // const result = new Set(tmpUsers);
      console.log("result", result);
      setUserData(result);
    }
    getUsersWithAccess();
  }, [venueAccess]);

  function checkAbilityPerVenue(user, currentAbility, venueid) {
    console.log("user", user, "currentAbility", currentAbility, "venueid", venueid);
    // if (user.role === "owner") return true;
    if (!user.abilities) return false;

    // if (!user.abilities.some((ability) => ability.venueAccess === venueid)) return false;
    // if (!user.venueAccess.includes(venueid)) return false;

    // if (user.abilities.some((ability) => ability.venueAccess === venueid && ability.abilities.includes(currentAbility))) {
    if (user.abilities.some((ability) => ability.venueAccess === venueid && ability.abilities.includes(currentAbility))) {
      return true;
    } else {
      return false;
    }
  }

  // if (userData && userData.length > 1)
  return (
    <div>
      <PeopleIcon />
      <Button onClick={handleOpen}>Manage Users</Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box
          sx={{
            position: "absolute",
            top: "10vh",
            left: "5vw",
            width: "90vw",
            height: "80vh",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
          style={{ overflowY: "scroll" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <h2>Manage users</h2>
              <h7>Current users</h7>
              {userData &&
                userData.map((user) => (
                  <Grid item xs={12} mt={6} className="handleUsers-user">
                    <div style={{ display: "flex", gap: "15px" }}>
                      <TextField
                        size="small"
                        id="email"
                        label="Email"
                        type="email"
                        value={user.email}
                        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                        inputProps={{
                          style: {
                            width: "200px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        }}
                      />
                      <TextField
                        size="small"
                        id="socialId"
                        label="Social ID"
                        type="encrypted"
                        value={user.socialId}
                        onBlur={(e) => handleChange("socialId", e.target.value)}
                        inputProps={{
                          style: {
                            width: "200px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        }}
                      />
                      {/* {user.socialId} */}
                      <Select size="small" value={user.role}>
                        {allRoles.map((role) => (
                          <MenuItem value={role}>{role}</MenuItem>
                        ))}
                      </Select>
                    </div>
                    <Grid item xs={12} mt={4}>
                      <table className="smallTable">
                        <thead>
                          <tr>
                            <th>Venue</th>
                            {allAbilities.map((ability) => (
                              <th style={{ textAlign: "center", fontSize: "12px", fontWeight: "100" }}>{ability}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody style={{ borderTop: "20px solid transparent" }}>
                          {/* {accessibleVenues.map((venue, index) => ( */}
                          {accessibleVenues.map((venue, index) => (
                            <tr>
                              <td key={index}>{venue.venue}</td>
                              {allAbilities.map((ability) => (
                                <td style={{ textAlign: "center" }}>
                                  {/* {console.log("user", user)} */}
                                  <Switch
                                    size="small"
                                    checked={checkAbilityPerVenue(user, ability, venue.venueid)}
                                    onClick={(e) => handleChangeAbility(user, ability, venue.venueid)}
                                  />
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
            <Grid item xs={12}>
              <CreateUser userData={userData} setUserData={setUserData} allRoles={allRoles} allAbilities={allAbilities} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default HandleUsers;

// const Users = [
//   {
//     userId: "",
//     venueAccess: ["venue1", "venue2"],
//     abilities: [
//       { venueid: "venue1", abilities: ["Edit products/modules", "Edit bookings", "Sign agreements", "Handle Settlements"] },
//       { venueid: "venue2", abilities: ["Edit products/modules", "Edit bookings", "Sign agreements", "Handle Settlements"] },
//     ],
//   },
// ];
