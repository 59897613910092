/** @format */

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { format, addDays } from "date-fns";
import { useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import firebase from "firebase/compat/app";
import { getStorage } from "firebase/storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "firebase/compat/firestore";
import { useSelector } from "react-redux";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { enableIndexedDbPersistence } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBkG4yC057TYQia4k6pi2sfnYladgPViaU",
  authDomain: "venuehub-64e72.firebaseapp.com",
  projectId: "venuehub-64e72",
  storageBucket: "venuehub-64e72.appspot.com",
  messagingSenderId: "432523093238",
  appId: "1:432523093238:web:ee75fcdb37af79a00f38d7",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);
const auth = getAuth();
// const storage = getStorage(app, 'gs://venuehub-64e72.appspot.com');
const storage = getStorage();
export const database = firebase.firestore();

export function createId() {
  const characters = "abcdefghijklmnopqrstuvwxyz234567";
  let id = "";
  for (let i = 0; i < 32; i++) {
    let randomIndex = Math.floor(Math.random() * characters.length);
    id += characters.charAt(randomIndex);
  }
  return id;
}

export function createSmallId() {
  const characters = "abcdefghijklmnopqrstuvwxyz234567";
  let id = "";
  for (let i = 0; i < 8; i++) {
    let randomIndex = Math.floor(Math.random() * characters.length);
    id += characters.charAt(randomIndex);
  }
  return id;
}

export function createMidId() {
  const characters = "abcdefghijklmnopqrstuvwxyz234567";
  let id = "";
  for (let i = 0; i < 20; i++) {
    let randomIndex = Math.floor(Math.random() * characters.length);
    id += characters.charAt(randomIndex);
  }
  return id;
}

export const db = getFirestore();

// Enable offline persistence using IndexedDB
// enableIndexedDbPersistence(db).catch((err) => {
//   if (err.code === "failed-precondition") {
//     // This error typically indicates that multiple tabs are open.
//     console.error("Persistence failed - multiple tabs open:", err);
//   } else if (err.code === "unimplemented") {
//     // The current browser does not support all features required to enable persistence
//     console.error("Persistence is not available in this browser:", err);
//   }
// });

export function signup(email, password, telephone) {
  return createUserWithEmailAndPassword(auth, email, password);
}

// Create a new user with email and password
// export function signup(email, password, telephone) {
//   return (createUserWithEmailAndPassword = async (email, password) => {
//     try {
//       // Create a user with the provided email and password
//       await auth.createUserWithEmailAndPassword(email, password);

//       // Success message or further processing
//       console.log("User created successfully!");
//       alert("success");
//     } catch (error) {
//       // Handle the error
//       alert("error");
//       if (error.code === "auth/email-already-in-use") {
//         console.log("User already exists!");
//         alert("User already exists!");
//         // Display an error message to the user indicating that the email is already registered
//       } else {
//         console.error("Error creating user:", error);
//         alert("another error");
//         // Display a generic error message or handle other types of errors
//       }
//     }
//   });
// }

export function login(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
}

export function logout() {
  return signOut(auth);
}

export function useAuth() {
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => setCurrentUser(user));
    return unsub;
  }, []);

  return currentUser;
}
export async function getAllDocumentsInCollection(collectionName) {
  const collection = database.collection(collectionName);
  const querySnapshot = await collection.get();
  const data = querySnapshot.docs.map((doc) => doc.data());
  return data;
}
export function updateDocument(collection, document, id) {
  database
    .collection(collection)
    .doc(id)
    .update({ ...document })
    .then(() => {})
    .catch((error) => {
      console.error("Error updating document document: ", error);
    });
}

export function deleteDocument(collection, id) {
  // Get a reference to the collection
  const collectionRef = database.collection(collection);

  // Get a reference to the document you want to delete
  const docRef = collectionRef.doc(id);

  // Delete the document
  docRef
    .delete()
    .then(() => {})
    .catch((error) => {
      console.error("Error deleting document: ", error);
    });
}
export const addDocumentToCollection = (collection, document, uuid) => {
  database
    .collection(collection)
    .doc(uuid)
    .set(document)
    .then(function (docRef) {
      toast("Added!");
    })
    .catch(function (error) {});
};
export const findDocumentById = async (collection, documentId) => {
  try {
    const doc = await database.collection(collection).doc(documentId).get();
    if (doc.exists) {
      // The artist document was found
      const artist = doc.data();
      return artist;
    } else {
      // The artist document was not found
      console.log("No such artist document!");
      return null;
    }
  } catch (error) {
    console.log("Error getting artist document:", error);
    return null;
  }
};

export async function getApprovedVenuesInDateRange(dateRange, status) {
  const collection = database.collection("Bookings");
  const querySnapshot = await collection
    .where("date", ">=", format(new Date(dateRange[0]), "yyyy-MM-dd"))
    .where("date", "<=", format(new Date(dateRange[1]), "yyyy-MM-dd"))
    .get();
  const data = querySnapshot.docs.map((doc) => doc.data());
  return data;
}

export async function getVenuesByCapacityRange(capacityRange, preferredDays) {
  const collection = database.collection("Venues");
  const querySnapshot = await collection
    .where("capacity", ">=", capacityRange[0])
    .where("capacity", "<=", capacityRange[1])
    .where("preferredDays", "array-contains-any", preferredDays)
    .get();
  const venues = querySnapshot.docs.map((doc) => doc.data());
  return venues;
}
// export async function getVenuesByIds(venueIds) {
// 	const collection = database.collection('Venues');
// 	const querySnapshot = await collection
// 		.where('venueId', 'in', venueIds)
// 		.get();
// 	const data = querySnapshot.docs.map((doc) => doc.data());
// 	return data;
// }
export async function getVenuesByIds(venueIds) {
  const collection = database.collection("Venues");
  let data = [];
  let promises = [];
  for (let i = 0; i < venueIds.length; i += 10) {
    promises.push(collection.where("venueid", "in", venueIds.slice(i, i + 10)).get());
  }
  const querySnapshot = await Promise.all(promises);
  querySnapshot.forEach((snap) => {
    data = data.concat(snap.docs.map((doc) => doc.data()));
  });
  return data;
}

// export function venuesToAccess() {
//   let tmp = [];
//   const venueAccess = useSelector((state) => state.counter.venueAccess);
//   venueAccess.map(async (id) => tmp.push(await getDoc(doc(db, "Venues", id))));
//   console.log("this is TMP", tmp);
//   return tmp;
// }

export async function getAgreementByBookingId(bookingid) {
  console.log("Bookingid", bookingid);
  const collection = database.collection("Agreements");
  const querySnapshot = await collection.where("bookingids", "array-contains", bookingid).get();
  const agreement = querySnapshot.docs.map((doc) => doc.data());
  return agreement;
}

export async function getBookingById(bookingid) {
  console.log("Bookingid", bookingid);
  const collection = database.collection("Bookings");
  const querySnapshot = await collection.where("bookingid", "==", bookingid).get();
  const booking = querySnapshot.docs.map((doc) => doc.data());
  return booking[0];
}

export async function getBookingsByVenueid(venueid) {
  console.log("Venueid", venueid);
  let bookings = [];
  // const querySnapshot = await getDocs(query(collection(db, "Bookings"), where("venueid", "==", venueid)));
  const querySnapshot = await getDocs(query(collection(db, "Bookings"), where("venueid", "==", "wjrbwst2ttgcxv3s6ptyqphqqr2k7kk5")));
  querySnapshot.forEach((doc) => {
    console.log(doc.id, " => ", doc.data());
    bookings.push(doc.data());
  });
  console.log("Bookings", bookings);
  return bookings;
}

/**
 * Returns an array of HTML <a> tags pointing to download links
 * for every file in the "ArchivedAgreements" folder of Firebase Storage.
 */
export async function getArchivedAgreementsLinks() {
  try {
    // Initialize or retrieve your Firebase app’s storage object
    const storage = getStorage(); // If you have a custom app, pass it: getStorage(yourApp)

    // Create a reference to the "ArchivedAgreements" folder
    const archivedRef = ref(storage, "ArchivedAgreements/");

    // List all items (files) in that folder
    const listResult = await listAll(archivedRef);

    // For each file, get a public download URL and build an HTML link
    const downloadLinks = await Promise.all(
      listResult.items.map(async (itemRef) => {
        const url = await getDownloadURL(itemRef);

        // Return an anchor tag string
        return `<a href="${url}" download target="_blank">${itemRef.name}</a>`;
      })
    );

    return downloadLinks; // Array of <a> tag strings
  } catch (error) {
    console.error("Error listing archived agreements:", error);
    return [];
  }
}

export default getFirestore();
