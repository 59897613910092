/** @format */

import React from "react";
import GradingIcon from "@mui/icons-material/Grading";
import AgreementModal from "./AgreementModal";
import { jsPDF } from "jspdf";
import { Button, CircularProgress, Grid, InputAdornment, Modal, Switch, TextField, Tooltip } from "@mui/material";
import { Box, display } from "@mui/system";
import { useSelector } from "react-redux";
import { useState } from "react";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useEffect } from "react";
import { AES, enc } from "crypto-js";
import GeneralForm from "../../helpers/GeneralForm";
import { userObj } from "../../dbObjects.js/dbUser";
import DisplayObject from "../../helpers/displayObject";
import SimpleSendEmail from "../UI helpers/SimpleSendEmail";
import EmailConfirmationVenueSignature from "../../helpers/EmailConfirmationVenueSignature";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { storeCurrentBookingId, storeCurrentUser } from "../../features/venueFilter/venueFilterSlice";
import { useDispatch } from "react-redux";
import { db } from "../../firebase";
import AttachmentUpload from "./AttachmentUpload";
import { left } from "@popperjs/core";
import { set } from "date-fns";
import { wait } from "../MiscUIComponents/Wait";
import { ClosedCaptionDisabledOutlined } from "@mui/icons-material";
import { getAuth, signOut } from "firebase/auth";
import { useLocation } from "react-router-dom";

const BankidSignAgreement = ({ agreement, setAgreement, phone, sign, agreementId, sumIncVat, socialId }) => {
  console.log("AgreementId i Batchagreement", agreementId);
  //   const bookingIds = bookingIds;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const passedVerification = queryParams.get("passedVerification");
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [sendConfirmationEmail, setSendConfirmationEmail] = useState(false);
  const [bookingId, setBookingId] = useState();
  const currentUser = useSelector((state) => state.counter.currentUser);
  const currentBookingId = useSelector((state) => state.counter.currentBookingId);

  const encryptedSocialId = socialId || "";
  //   const initialSocialId = AES.decrypt(encryptedSocialId, secretKey).toString(enc.Utf8);
  const dispatch = useDispatch();

  // console.log(props.socialId);
  // const socialId = AES.decrypt(encryptedSocialId, secretKey).toString(enc.Utf8);
  //   const [socialId, setSocialId] = useState(AES.decrypt(encryptedSocialId, secretKey).toString(enc.Utf8));
  //   const [decryptedSocialId, setDecryptedSocialId] = useState(AES.decrypt(encryptedSocialId, secretKey).toString(enc.Utf8));
  // const phoneRef = +46707402114;
  const phoneRef = phone;
  const type = sign;
  const [company, setCompany] = useState(agreement.agent.company || currentUser.company);
  const [orgNumber, setOrgNumber] = useState(agreement.agent.orgNumber || currentUser.orgNumber);
  const [streetAddress, setStreetAddress] = useState(agreement.agent.streetAddress || currentUser.streetAddress);
  const [zipCode, setZipCode] = useState(agreement.agent.zipCode || currentUser.zipCode);
  const [city, setCity] = useState(agreement.agent.city || currentUser.city);
  const [country, setCountry] = useState(agreement.agent.country || currentUser.country);
  // const [formData, setFormData] = useState(currentUser);
  const [formData, setFormData] = useState(agreement.agent);
  //   const [agreement, setAgreement] = useState();
  // console.log("SHOW", props.bookingData.agent);
  console.log("SHOW", currentUser);
  // const userId = "mdernulf@gmail.com";
  const userId = currentUser.email;
  const returnAddress = window.location.href;

  async function signAgreement() {
    setLoading(true);
    // await handleDone();
    await storeAgreement();
    console.log("THIS IS THE AGREEMENT", agreement);
    console.log("returnAddress", returnAddress, userId, agreementId, socialId, phone);
    // await wait(3000);
    window.location.href = `https://venuehub.live/verificatonOK?${returnAddress}&type=agentSignature&user=${userId}&agreementId=${agreementId}&socialId=${socialId}&phoneNumber=${phone}`;
    // fetch(
    //   `https://europe-west3-venuehub-64e72.cloudfunctions.net/verifyId?socialId=${socialId}&phoneNumber=${phoneRef}&type=${type}&userId=${userId}&agreementId=${agreementId}&returnAddress=${returnAddress}&`
    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     window.location.href = data.url;
    //     console.log("data", data);
    //     setLoading(false);
    //   })
    //   .catch((error) => console.error(error));
  }
  //   console.log("return to: ", returnAddress);
  // alert(AES.decrypt(props.socialId, secretKey).toString(enc.Utf8));

  async function handleChange(key, value) {
    // if (key === "socialId") setSocialId(AES.decrypt(value, secretKey).toString(enc.Utf8));
    // THIS
    // setFormData((prev) => ({ ...prev, [key]: value }));

    // dispatch(storeCurrentUser((prev) => ({ ...prev, [key]: value })));
    // console.log("formDATA", formData);
    //update agreement...
    // let tmp = JSON.parse(JSON.stringify(agreement));
    // await updateDoc(doc(db, "Agreements", props.agreementId), {
    //   agent: {
    //     firstName: formData.firstName,
    //     lastname: formData.lastName,
    //     phone: formData.phone,
    //     email: formData.email,
    //     streetAddress: formData.streetAddress,
    //     zipCode: formData.zipCode,
    //     city: formData.city,
    //     country: formData.country,
    //     orgNumber: formData.orgNumber,
    //     company: formData.company,
    //   },
    // });
    // props.setAgreement((previous) => ({ ...previous, [key]: value }));

    // THIS
    setAgreement((previous) => ({
      ...previous,
      agent: {
        ...previous.agent,
        [key]: value,
      },
    }));
    // handleDone(); // THIS???!!! Varför handleDone?
  }

  // useEffect(() => {
  //   async function getAndSetAgentData() {
  //     const snapShot = await getDoc(doc(db, "Agreements", props.agreementId));
  //     const agreementData = snapShot.data();
  //     setAgreement(agreementData);
  //     console.log("agreementData", agreementData);
  //     // setCompany(agreementData.agent.company);
  //     // setOrgNumber(agreementData.agent.orgNumber);
  //     // setStreetAddress(agreementData.agent.streetAddress);
  //     // setZipCode(agreementData.agent.zipCode);
  //     // setCity(agreementData.agent.city);
  //     // setCountry(agreementData.agent.country);
  //     let tmp = JSON.parse(JSON.stringify(formData));
  //     tmp.company = agreementData.agent.company;
  //     tmp.orgNumber = agreementData.agent.orgNumber;
  //     tmp.streetAddress = agreementData.agent.streetAddress;
  //     tmp.zipCode = agreementData.agent.zipCode;
  //     tmp.city = agreementData.agent.city;
  //     tmp.country = agreementData.agent.country;
  //   }
  //   getAndSetAgentData();
  // }, []);

  //   useEffect(() => {
  //     //THIS!!! Den här gör att hela sidan laddas om.
  //     // dispatch(storeCurrentUser(formData));
  //     console.log(formData);
  //   }, [formData]);

  useEffect(() => {
    const auth = getAuth();

    if (!passedVerification) {
      //   alert("user signed out");
      if (currentUser.role === "owner" || currentUser.role === "editor") return;
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          console.log("User signed out");

          // Optionally, redirect the user to the login page or perform other actions.
          // window.location.href = "/login"; // Example redirect
        })
        .catch((error) => {
          // An error happened.
          console.error("Sign-out error:", error);
        });
      // Put bookingId in Redux
      bookingId && dispatch(storeCurrentBookingId(bookingId));
      // If returning from another page (missing bookingId) set it from Redux
      if (!bookingId) setBookingId(currentBookingId);
    }
    // if (initialSocialId !== "") setChecked(true);
  }, []);

  //   function handleSocialId(value) {
  //     const encryptedValue = AES.encrypt(value, secretKey).toString();
  //     setDecryptedSocialId(value);
  //     // setSocialId(value);
  //     handleChange("socialId", encryptedValue);
  //   }

  // TO DO: store user data (inc or ex social id)
  async function handleDone() {
    // let tmpFormData = { ...formData };
    // console.log("checked", checked);
    // console.log("formData", formData);
    // console.log("SocialId", socialId);
    // console.log("decryptedSocialId", decryptedSocialId);
    // console.log("encryptedSocialId", AES.encrypt(socialId, secretKey).toString());
    // console.log("tnmpformdata social id", formData.socialId);
    // console.log("currentUser.email", currentUser.email);
    // if (!checked) tmpFormData.socialId = "";
    // console.log("tmpFormData", tmpFormData);
    // await updateDoc(doc(db, "Users", currentUser.userid), {
    // ...tmpFormData,
    // firstName: formData.firstName,
    // lastName: formData.lastName,
    // phone: formData.phone,
    // streetAddress: formData.streetAddress,
    // zipCode: formData.zipCode,
    // city: formData.city,
    // country: formData.country,
    // socialId: !checked ? "" : formData.socialId,
    //   socialId: !checked ? "" : AES.encrypt(socialId, secretKey).toString(),
    // email: currentUser.email, //Important, otherwise user might get locked out.
    // });

    // let tmpAgreement = JSON.parse(JSON.stringify(agreement));
    // console.log("tmpAgreement", tmpAgreement);

    // tmpAgreement.agent = {
    //   company: agreement.agent.company,
    //   orgNumber: agreement.agent.orgNumber,
    //   email: agreement.agent.email,
    //   firstName: agreement.agent.firstName,
    //   lastName: agreement.agent.lastName,
    //   phone: agreement.agent.phone,
    //   streetAddress: agreement.agent.streetAddress,
    //   zipCode: agreement.agent.zipCode,
    //   city: agreement.agent.city,
    //   country: agreement.agent.country,
    //   // socialId: !checked ? "" : formData.socialId,
    //   //   socialId: !checked ? "" : AES.encrypt(socialId, secretKey).toString(),
    // };
    // console.log("tmpAgreement", tmpAgreement);
    console.log("agreementYes", agreement, sumIncVat);
    console.log("agreementYes", agreement, sumIncVat);
    console.log("agreement type:", typeof agreement);
    console.log("agreement content:", agreement);
    // await updateDoc(doc(db, "Agreements", agreementId), {
    //   ...agreement,
    //   totalSumIncVat: sumIncVat,
    // });

    console.log("agreement Stored", agreementId);
    // setDone(true);
  }

  async function storeAgreement() {
    console.log("agreement Stored", agreement);

    // await updateDoc(doc(db, "Agreements", agreementId), {
    //   agent: {
    //     ...agreement.agent,
    //   },
    //   //   totalSumIncVat: sumIncVat,
    // });
    const agreementRef = doc(db, "Agreements", agreement.agreementId);

    // Set the "capital" field of the city 'DC'
    await updateDoc(agreementRef, {
      agent: { ...agreement.agent },
    });
  }

  // Storing or not storing socialId
  //   const [checked, setChecked] = useState(true);
  //   const handleSwitch = (event) => {
  //     setChecked(event.target.checked);
  //     sign === "venueSignature" && handleDone();
  //   };

  // if currentUserData is available use props.setAgreement to shuffle it to the agreement.
  useEffect(() => {
    console.log("formData checker", formData);
    const formObj = ["firstName", "lastName", "company", "orgNumber", "streetAddress", "zipCode", "city", "country", "email", "phone"];
    formObj.forEach((key) => {
      if (formData[key] === "") {
        console.log("formData[key]", formData[key]);
        handleChange(key, currentUser[key]);
      }
    });
  }, [formData]);

  //   useEffect(() => {
  //     async function storeSocialId() {
  //       await updateDoc(doc(db, "Users", currentUser.userid), {
  //         ...formData,
  //         socialId: !checked ? "" : formData.socialId,
  //       });
  //     }
  //     // storeSocialId();
  //   }, [checked]);

  // useEffect(() => {
  //   !currentUser.company ||
  //     !currentUser.orgNumber ||
  //     !currentUser.streetAddress ||
  //     !currentUser.zipCode ||
  //     !currentUser.city ||
  //     !currentUser.country ||
  //     (decryptedSocialId.length !== 12 && setDone(false));
  // }, [formData]);

  useEffect(() => {
    console.log("agreement!", agreement);
  }, [agreement]);

  if (sign)
    return (
      <div className="signForm">
        {sign === "agentSignature" && (
          <>
            {/* <h1>{sign}</h1> */}

            {
              !agreement.agent.company ||
                !agreement.agent.orgNumber ||
                !agreement.agent.streetAddress ||
                !agreement.agent.zipCode ||
                !agreement.agent.city ||
                !agreement.agent.country ||
                !agreement.agent.phone
              // ||
              // decryptedSocialId.length !== 12 ? (
              //   <div>Sign agreement via BankId. Please enter missing information.</div>
              // ) : (
              //   ""
              // )
            }
            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap", marginTop: "10px" }}>
              {/* <TextField
                type="number"
                error={decryptedSocialId.length !== 12}
                defaultValue={decryptedSocialId}
                label="Personnr/Social ID"
                placeholder="YYYYMMDDXXXX"
                size="small"
                onChange={(e) => handleSocialId(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={checked ? "Storing Social ID to your profile" : "Not storing Social ID to your profle"}>
                        <Switch checked={checked} onChange={handleSwitch} color="primary" />
                      </Tooltip>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip title="Encrypted">
                        <EnhancedEncryptionIcon style={{ color: "#555", zIndex: "100000" }} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              /> */}
              {/* {!AES.decrypt(props.socialId, secretKey).toString(enc.Utf8) && (
                <div>
                Store my Social ID <Switch checked={checked} onChange={handleSwitch} color="primary" />
                </div>
              )} */}
              <TextField
                size="small"
                label="First Name"
                error={!agreement.agent.firstName && !currentUser.firstName}
                defaultValue={agreement.agent.firstName || currentUser.firstName}
                // onChange={(e) => setCountry(e.target.value)}
                onChange={(e) => handleChange("firstName", e.target.value)}
                disabled={done}
              />
              <TextField
                size="small"
                label="Last Name"
                error={!agreement.agent.lastName && !currentUser.lastName}
                defaultValue={agreement.agent.lastName || currentUser.lastName}
                // onChange={(e) => setCountry(e.target.value)}
                onChange={(e) => handleChange("lastName", e.target.value)}
                disabled={done}
              />
              <TextField
                size="small"
                label="Company Name"
                error={!agreement.agent.company && !currentUser.company}
                defaultValue={agreement.agent.company || currentUser.company}
                // onChange={(e) => setCompany(e.target.value)}
                onChange={(e) => handleChange("company", e.target.value)}
                disabled={done}
              />
              <TextField
                size="small"
                label="Organization Number"
                error={!agreement.agent.orgNumber && !currentUser.orgNumber}
                defaultValue={agreement.agent.orgNumber || currentUser.orgNumber}
                // onChange={(e) => setOrgNumber(e.target.value)}
                onChange={(e) => handleChange("orgNumber", e.target.value)}
                disabled={done}
              />
              <TextField
                size="small"
                label="Street Address"
                error={!agreement.agent.streetAddress && !currentUser.streetAddress}
                defaultValue={agreement.agent.streetAddress || currentUser.streetAddress}
                // onChange={(e) => setStreetAddress(e.target.value)}
                onChange={(e) => handleChange("streetAddress", e.target.value)}
                disabled={done}
              />
              <TextField
                size="small"
                label="Zip Code"
                error={!agreement.agent.zipCode && !currentUser.zipCode}
                defaultValue={agreement.agent.zipCode || currentUser.zipCode}
                // onChange={(e) => setZipCode(e.target.value)}
                onChange={(e) => handleChange("zipCode", e.target.value)}
                disabled={done}
              />
              <TextField
                size="small"
                label="City"
                error={!agreement.agent.city && !currentUser.city}
                defaultValue={agreement.agent.city || currentUser.city}
                value={agreement.agent.city || currentUser.city}
                // onChange={(e) => setCity(e.target.value)}
                onChange={(e) => handleChange("city", e.target.value)}
                disabled={done}
              />
              <TextField
                size="small"
                label="Country"
                error={!agreement.agent.country && !currentUser.country}
                defaultValue={agreement.agent.country || currentUser.country}
                // onChange={(e) => setCountry(e.target.value)}
                onChange={(e) => handleChange("country", e.target.value)}
                disabled={done}
              />
              <TextField
                size="small"
                label="Email"
                error={!agreement.agent.email && !currentUser.email}
                defaultValue={agreement.agent.email || currentUser.email}
                // onChange={(e) => setCountry(e.target.value)}
                onChange={(e) => handleChange("email", e.target.value)}
                disabled={done}
              />
              <TextField
                size="small"
                label="Phone"
                // error={!agreement.agent.phone.length < 7 && !currentUser.phone.length < 7}
                error={!agreement.agent.phone}
                defaultValue={agreement.agent.phone || currentUser.phone}
                // onChange={(e) => setCountry(e.target.value)}
                onChange={(e) => handleChange("phone", e.target.value)}
                disabled={done}
              />
              {/* {console.log("formData", formData)} */}
              {/* <Button
                onClick={() => handleDone()}
                variant="outlined"
                disabled={
                  //   decryptedSocialId.length !== 12 ||
                  !formData.company ||
                  !formData.orgNumber ||
                  !formData.streetAddress ||
                  !formData.zipCode ||
                  !formData.city ||
                  !formData.country ||
                  !formData.phone ||
                  // !formData.email ||
                  done
                }
              >
                Ready to Sign
              </Button> */}
            </div>
          </>
        )}
        {sign === "venueSignature" && (
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap", marginTop: "10px" }}>
            {/* {formData.email} */}
            <TextField
              size="small"
              label="Customer Email"
              style={{ width: "300px" }}
              error={!agreement.agent.email}
              defaultValue={agreement.agent.email === "Email" ? null : agreement.agent.email}
              // onChange={(e) => setCountry(e.target.value)}
              onChange={(e) => handleChange("email", e.target.value)}
              // disabled={done}
            />
            {/* {socialId ? "" : ""} */}
            {/* <TextField
              type="number"
      
              error={socialId.length !== 12}
              defaultValue={socialId}
              label="Your Social ID"
              placeholder="YYYYMMDDXXXX"
              size="small"
              onChange={(e) => handleSocialId(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={checked ? "Storing Social ID to your profile" : "Not storing Social ID to your profle"}>
                      <Switch checked={checked} onChange={handleSwitch} color="primary" />
                    </Tooltip>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title="Encrypted">
                      <EnhancedEncryptionIcon style={{ color: "#555", zIndex: "100000" }} />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            /> */}
          </div>
        )}

        {passedVerification === "true" && (
          <>
            <h6 style={{ marginTop: "20px" }}>Make sure your details above are correct</h6>
            <Button
              disabled={
                (sign === "agentSignature" &&
                  (!agreement.agent.company ||
                    !agreement.agent.orgNumber ||
                    !agreement.agent.streetAddress ||
                    !agreement.agent.zipCode ||
                    !agreement.agent.city ||
                    !agreement.agent.country)) ||
                sign === "venueSignature" ||
                !agreement.agent.email
              }
              style={{ marginTop: "20px" }}
              variant="contained"
              size="large"
              color="success"
              onClick={() => signAgreement()}
            >
              {console.log(agreement)}
              {loading && <CircularProgress sx={{ zoom: "0.4" }} style={{ marginRight: "15px" }} />}
              Sign Agreement
            </Button>
            {/* <Button style={{ marginTop: "20px" }} variant="outlined" size="large" color="success" onClick={() => storeAgreement()}>
              Store
            </Button> */}
          </>
        )}
      </div>
    );
};

export default BankidSignAgreement;
