/** @format */

import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChangePassword from "../components/Authentication/ChangePassword";
import { ownerAccountObj } from "../dbObjects.js/dbOwnerAccount";
import { userObj } from "../dbObjects.js/dbUser";
import DisplayObject from "../helpers/displayObject";
import GeneralForm from "../helpers/GeneralForm";
import SelectVenue from "../helpers/SelectVenue";
import LockIcon from "@mui/icons-material/Lock";
import HandleUsers from "../features/owner/HandleUsers";
import { storeSuperOwner } from "../features/venueFilter/venueFilterSlice";
import { useDispatch } from "react-redux";
import { customerAccountObj } from "../dbObjects.js/dbCustomerAccount";

const OwnerAccount = () => {
  const dispatch = useDispatch();
  const superOwner = useSelector((state) => state.counter.superOwner);
  const [userData, setUserData] = useState();
  const [openPassword, setOpenPassword] = useState(false);
  // const [openHandleUsersModal, setOpenHandleUsersModal] = useState(false);
  const navigate = useNavigate();
  // const currentUser = useSelector((state) => state.counter.currentUser);
  const handleClose = () => {
    navigate(-1);
  };

  const handleSuperOwner = (value) => {
    if (value === "jerrythedog") dispatch(storeSuperOwner(true));
  };

  const handleUsers = () => {
    return navigate("/users");
  };

  return (
    <Grid p={4}>
      <h2>Account</h2>

      {/* <DisplayObject data={userData} />
      <DisplayObject data={currentUser} /> */}
      <div className="formInABox">
        <GeneralForm
          form={ownerAccountObj}
          formName="userForm"
          priority={1}
          setUserData={setUserData}
          navigateAfter={-1}
          // fields={["firstName", "lastName", "phone", "streetAddress", "zipCode", "city", "country", "socialId"]}
        />
      </div>
      <Grid container style={{ display: "flex", gap: "15px", flexDirection: "column" }} mt={6}>
        {userData && (userData.role === "owner" || superOwner) && (
          <Grid item>
            <HandleUsers />
          </Grid>
        )}
        <Grid item>
          <TextField
            onChange={(e) => {
              handleSuperOwner(e.target.value);
            }}
            style={{ opacity: "0.2" }}
          />
        </Grid>
        <Grid item>
          <LockIcon />
          <Button onClick={() => setOpenPassword(true)}>Change Password</Button>
          <ChangePassword setOpenPassword={setOpenPassword} openPassword={openPassword} currentEmailField={userData && userData.email} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OwnerAccount;
