/** @format */
import FontAvenir from "./FontAvenir";
import React, { useState, useEffect } from "react";
import { jsPDF } from "jspdf";
import { Button, CircularProgress, FormControlLabel, IconButton, Modal, Switch, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import AgreementSign from "./AgreementSign";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DownloadIcon from "@mui/icons-material/Download";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import UserForm from "../Forms/UserForm";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { userObj } from "../../dbObjects.js/dbUser";
import GeneralForm from "../../helpers/GeneralForm";
import UserFormAgent from "../Forms/UserFormAgent";
import { collection, doc, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../../firebase";
import { VolunteerActivismOutlined } from "@mui/icons-material";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { makeNumber, numberFormatted } from "../../helpers/Numbers";
import { useGridApiOptionHandler } from "@mui/x-data-grid";
import BatchAgreementSign from "./BatchAgreementSign";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import GradingIcon from "@mui/icons-material/Grading";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ReplyIcon from "@mui/icons-material/Reply";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import VisualDataSummary from "../VisualData/VisualDataSummary";
import VisualDataSummaryPDF from "../VisualData/VisualDataSummaryPDF";
import LoadingDots from "../UI helpers/LoadingDots";
import DisplayObject from "../../helpers/displayObject";
import { countries } from "../../dbObjects.js/dbCountries";
import AttachmentUpload from "./AttachmentUpload";
import GeneralConditions, {
  GeneralConditionsSwedish,
  GeneralConditionsEnglish,
  avtalsingressSwedish,
  avtalsingressEnglish,
  AvtalsingressSwedish,
  AvtalsingressEnglish,
} from "./GeneralConditions";
import axios from "axios";
import { wait } from "../MiscUIComponents/Wait";
import PdfDownloader from "./PdfDownloader";
import { useRef } from "react";
import LogoUpload from "../MiscUIComponents/LogoUpload";
import { useNavigate } from "react-router-dom";
import PdfUploader from "../MiscUIComponents/PdfUploader";
import validateEmail from "../MiscUIComponents/ValidateEmail";
import PdfHandler from "./PdfHandler";
import EmailProgressBar from "../../helpers/EmailProgressBar";
import ConnectAgreement from "../../helpers/ConnectAgreement";
import {
  storeCurrentAgreement,
  storeCurrentAgreements,
  storeCurrentPdfDocHTML,
  storeCurrentVenue,
} from "../../features/venueFilter/venueFilterSlice";
import { useDispatch } from "react-redux";
import { current } from "@reduxjs/toolkit";
import InsTranslate from "../../helpers/InsTranslate";
import QuickSpecs from "./QuickSpecs";
import QuickSpecsMulti from "./QuickSpecsMulti";
import { set } from "date-fns";
import AgreementAddendum from "./AgreementAddendum";
import BankidSignAgreement from "./BankidSignAgreement";
import PrivacyPolicy from "./PrivacyPolicy";

const numberOfColumns = 12;

const BatchAgreementModal = (props) => {
  let keys = [];
  let values = [];
  const [open, setOpen] = useState(props.open);
  const [pdf, setPdf] = useState();
  const [bookingData, setBookingData] = useState();
  const [bookingIds, setBookingIds] = useState(props.bookingids);
  const [agreement, setAgreement] = useState();
  const [userData, setUserData] = useState();
  const currentBookings = useSelector((state) => state.counter.currentBookings);
  const currentArtistBookings = useSelector((state) => state.counter.currentArtistBookings);
  const currentAgreements = useSelector((state) => state.counter.currentAgreements);
  const currentUser = useSelector((state) => state.counter.currentUser);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  // if (currentVenue.venueid === "none") setVenueData(props.agreementId);
  useEffect(() => {
    // Check if the currentVenue.venueid is "none"
    if (currentVenue.venueid === "none") {
      // Call setVenueData function before anything else
      setVenueData(props.agreementId);
    }
  }, [currentVenue]); // Runs whenever currentVenue changes
  console.log("MF", currentVenue);
  const superOwner = useSelector((state) => state.counter.superOwner);
  const currency = "SEK";
  console.log(currentVenue);
  const [relevantBookings, setRelevantBookings] = useState([]);
  const [bookingFeatures, setBookingFeatures] = useState();
  // const bookingFeatures = props.bookingFeatures;
  console.log("currentBookings before", currentBookings);
  const [statusColor, setStatusColor] = useState();
  const [sustainabilityData, setSustainabilityData] = useState();
  const [sendEmail, setSendEmail] = useState(bookingData && bookingData.venue.email);
  const [pdfUrl, setPdfUrl] = useState();
  const [pageReady, setPageReady] = useState(false);
  const [paramsDone, setParamsDone] = useState(false);
  const navigate = useNavigate();
  let bookings = [];
  const dispatch = useDispatch();
  const [language, setLanguage] = useState("svenska");
  const [translateModules, setTranslateModules] = useState(true);

  const specificationsRef = useRef(null);
  // const elementRef = useRef(null);
  // const elementRef = useRef(null);

  const [sentArray, setSentArray] = useState([]);
  async function testSend() {
    try {
      const response = await axios.post(
        `https://europe-west3-venuehub-64e72.cloudfunctions.net/sendEmailTestBeforeVenueSignature?email=${encodeURIComponent(
          // `http://127.0.0.1:5001/venuehub-64e72/europe-west3/sendEmailTestBeforeVenueSignature?email=${encodeURIComponent(
          // testing
          sendEmail
        )}&agreementId=${props.agreementId}`
      );
      console.log(response.data);
      // console.log(response.status);
      // if (response.status === 201) msg = "Exists";
      // if (response.status === 202) msg = "OK";
    } catch (error) {
      console.error("Error checking email:", error);
      // Retry the request after a short delay
      // setTimeout(handleBlur, 1000); // Adjust the delay time as needed (e.g., 1000ms = 1 second)
    }
  }

  const handleOpen = () => {
    setTimeout(() => {
      setOpen(true);
    }, 1000);
  };

  const handleClose = () => {
    setOpen(false);
    props.setShowAgreement && props.setShowAgreement(false);
  };

  const handleSocialId = (e) => {
    alert(e.target.value);
  };

  var docPDF = new jsPDF();
  docPDF.setFont("times");

  // Store pdf to storage folder "/agreements" =====> INAKTUELLT (RADERA)
  const storage = getStorage();
  async function uploadPdfToStorage(docPDF, agreementId) {
    const storageRef = ref(storage, `agreements/${agreementId}.pdf`);
    await uploadBytes(storageRef, docPDF);
    console.log("PDF uploaded successfully.");
    let pdfUrl = "";
    // Get the download URL
    await getDownloadURL(storageRef).then(async (url) => {
      console.log("url:", url);
      pdfUrl = url;
    });
    // await updateDoc(doc(db, "Agreements", bookingData.agreementId), {
    //   agreementPdf: pdfUrl,
    // });
  }

  // SET RELEVANT BOOKINGS
  // useEffect(() => {
  //   setRelevantBookings(currentBookings.filter((item) => props.bookingids.includes(item.bookingid))); // props.bookingids may be an array of several ids
  // }, [currentBookings]);

  // SET RELEVANT BOOKINGS getting bookingids from AGREEMENTID
  useEffect(() => {
    if (props.agreementId === false || agreement === undefined) return;

    async function getData() {
      console.log("aId", props.agreementId);
      if (props.agreementId === false) {
        setOpen(false);
        props.setShowAgreement && props.setShowAgreement(false);
        return;
      }
      // const getAgreement = (await getDoc(doc(db, "Agreements", props.agreementId))).data();
      // console.log("getAgreement", getAgreement);
      // setAgreement(getAgreement);
      let tmp = [];
      let tmpBookingIds = agreement.bookingids;
      console.log("bookingIds", tmpBookingIds);
      const bookingsForAgreementId = await getDocs(query(collection(db, "Bookings"), where("agreementId", "==", props.agreementId)));
      bookingsForAgreementId.forEach((doc) => {
        tmpBookingIds && console.log("tmpBookingIds", tmpBookingIds);
        tmpBookingIds &&
          tmpBookingIds.some((booking) => booking === doc.data().bookingid) &&
          doc.data().status !== "Killed" &&
          tmp.push(doc.data());
      });
      console.log("tmp real", tmp);
      tmp.sort((a, b) => new Date(a.date) - new Date(b.date)); // Get bookings in date order
      setRelevantBookings(tmp);
      setBookingIds(agreement.bookingIds);
      setBookingData(tmp[0]); // Sets bookingData
    }
    getData();
    // props.sign !== "agentSignature" && getData();
    // props.sign === "agentSignature" && getData();
  }, [props.agreementId, agreement]);

  // SET RELEVANT BOOKINGS getting bookingids from PROPS.BOOKINGIDS
  useEffect(() => {
    console.log("props.bookingids", props.bookingids);
    let tmp = [];
    async function getData() {
      console.log("props.bookingids", props.bookingids);
      props.bookingids.map(async (bookingid) => {
        const docSnap = await getDoc(doc(db, "Bookings", bookingid));
        tmp.push(docSnap.data());
        console.log(tmp[0]);
      });
      setRelevantBookings(tmp);
      setBookingData(tmp[0]); // Sets bookingData
    }
    props.bookingids && props.sign === "agentSignature" && getData();
  }, [props.bookingids]);

  // THIS!! Remove?
  // useEffect(() => {
  //   if (agreement == undefined) {
  //     // alert("agreement undefined");
  //     return;
  //   } else {
  //     // alert("agreement in place");
  //     console.log("check agreement", agreement);
  //     setTimeout(() => {
  //       console.log("check agreement", agreement);
  //     }, 1000);
  //   }
  // }, [agreement]);

  useEffect(() => {
    setBookingFeatures(relevantBookings.bookingFeatures);
    console.log("relevantBookings", relevantBookings);
  }, [relevantBookings]);

  const [sum, setSum] = useState();
  const [vat, setVat] = useState();
  const [sumIncVat, setSumIncVat] = useState();
  const [preCharge, setPreCharge] = useState();
  const [endChargeIncVat, setEndChargeIncVat] = useState();
  const [preChargeDate, setPreChargeDate] = useState();
  const [endChargeDate, setEndChargeDate] = useState();
  const [totalCo2e, setTotalCo2e] = useState(0);
  const [vatAmounts, setVatAmounts] = useState();

  useEffect(() => {}, [sumIncVat]);

  useEffect(() => {
    //sort by date
    relevantBookings.sort((a, b) => {
      if (a.date > b.date) return 1;
      if (a.date < b.date) return -1;
      return 0;
    });

    let tmpIncludedFeaturesSum = [];
    // This too is only applicable when bookingFeatures are set.

    relevantBookings &&
      relevantBookings.map((booking) =>
        Object.values(booking.bookingFeatures).map((feature) =>
          Object.values(feature.options).map((option) => {
            const result = option.selected && !option.customerOpted && option.price && Number(option.price);
            console.log("result", result);

            result !== undefined && tmpIncludedFeaturesSum.push({ amount: Number(result), vat: option.vat });
          })
        )
      );
    console.log("tmpIncludedFeaturesSum", tmpIncludedFeaturesSum);

    const totalsByVat = tmpIncludedFeaturesSum.reduce((acc, { amount, vat }) => {
      if (vat === undefined) vat = "25%";
      vat = parseFloat(vat.replace("%", ""));
      if (acc[vat]) {
        acc[vat] += (amount * vat) / 100; // If VAT exists, add to its total
      } else {
        acc[vat] = (amount * vat) / 100; // Otherwise, initialize it
      }
      return acc;
    }, {});
    console.log("totalsByVat", totalsByVat);
    setVatAmounts(totalsByVat);

    const totalSum = tmpIncludedFeaturesSum.reduce((acc, { amount }) => acc + amount, 0);
    const totalVat = Object.values(totalsByVat).reduce((acc, value) => acc + value, 0);
    setSum(totalSum);
    setSumIncVat(totalSum + totalVat);
  }, [relevantBookings]);

  const [preChargeIncVat, setPreChargeIncVat] = useState();
  useEffect(() => {
    if (agreement && sumIncVat) {
      console.log("Counter", agreement, sumIncVat);
      // setPreChargeIncVat((sumIncVat * parseFloat(agreement.preCharge && agreement.preCharge.replace("%", ""))) / 100);
      setPreChargeIncVat((sumIncVat * parseFloat(agreement.preCharge)) / 100);
      // setEndChargeIncVat(sumIncVat - (sumIncVat * parseFloat(agreement.preCharge && agreement.preCharge.replace("%", ""))) / 100);
      setEndChargeIncVat(sumIncVat - (sumIncVat * parseFloat(agreement.preCharge)) / 100);
    }
  }, [sumIncVat]);

  useEffect(() => {
    bookingData && getSustainabilityData(bookingData.venueid);
  }, [bookingData]);

  useEffect(() => {
    // docPDF.setFont("Courier");
    // docPDF.setFont("Avenir Regular", "normal");
    // uploadPdfToStorage(docPDF, bookingData && bookingData.agreementId);
  }, [docPDF]);

  function savePDF() {
    docPDF.html(document.querySelector("#agreement"), {
      callback: function (docPDF) {
        var img = new Image();
        img.src =
          "https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FVenuehub_Blackonwhite.png?alt=media&token=f6fe81d1-74f8-424f-8f48-1f4ddf89a943";

        img.onload = function () {
          docPDF.addImage(this, 10, 10);
        };

        // docPDF.setFont("Courier");
        docPDF.save();
      },
    });
  }

  async function setVenueData() {
    // first make sure agreement is set otherwise get it (in order to get the correct venue)
    let tmpAgreement;
    if (!agreement) {
      // alert("agreement not set " + props.agreementId);
      tmpAgreement = await getAgreement();
    } else {
      // alert("agreement already set");
      tmpAgreement = agreement;
    }
    async function getAgreement() {
      const snap = await getDoc(doc(db, "Agreements", props.agreementId));
      setAgreement(snap.data());
      if (snap.data().attachments) setIncludedAttachments(snap.data().attachments);
      return snap.data();
    }

    if (currentVenue.venueid === "none") {
      console.log("currentVenuevenueid", currentVenue.venueid);
      const venueData = await getVenue();
      async function getVenue() {
        const snap = await getDoc(doc(db, "Venues", tmpAgreement.venueid));
        return snap.data();
      }
      dispatch(storeCurrentVenue(venueData));
    } else return;
  }
  // bookingData &&
  //   document.querySelector("#agreement") &&
  //   PdfHandler(document.querySelector("#agreement").innerHTML, setPdfUrl, includedAttachments, bookingData.agreementId);

  //if on venuepage bookings are stored in currentBookings. While if on agentpage they're instead stored in currentArtistBookings
  useEffect(() => {
    async function getData() {
      const docSnap = await getDoc(doc(db, "Bookings", props.bookingids[0]));
      setBookingData(docSnap.data());
      console.log("FIRST BOOKING", docSnap.data());
      console.log("FIRST ID", props.bookingids[0]);
      setStatusColor("#aaa");
    }
    props.bookingids && getData();
  }, [currentBookings]);
  // OLD
  // useEffect(() => {
  //   currentBookings.map((tmp) => {
  //     if (tmp.bookingid === props.bookingids[0]) {
  //       setBookingData(tmp);
  //       setStatusColor("#aaa");
  //     }
  //   });
  // }, [currentBookings]);

  const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(10px)",
  };

  useEffect(() => {
    props.open === true && handleOpen();
    props.open === false && handleClose();
  }, [props.open]);

  async function getSustainabilityData(venueid) {
    const path = `venues/${venueid}/2024`;
    const docRef = doc(db, "ClimateData", path);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setSustainabilityData(docSnap.data());
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  const dateDaysPriorTo = (date, days) => {
    if (days === undefined || days === "-") days = 0;

    console.log(date, days);
    return new Date(new Date(date).setDate(new Date(date).getDate() - days)).toISOString().split("T")[0];
  };

  function convertStringToNumberOrZero(value) {
    const number = parseFloat(value);
    if (isNaN(value)) {
      return 0;
    }
    return number;
  }

  // REMOVE ???
  useEffect(() => {
    async function getAgreement() {
      const snap = await getDoc(doc(db, "Agreements", props.agreementId));
      // alert("agreement set 2" + snap.data().attachments.length);
      // if (snap.data().attachments) setIncludedAttachments(snap.data().attachments);
      return snap.data();
    }

    console.log("GETAGREEMENT", getAgreement());
    setAgreement(getAgreement());
  }, []);

  if (bookingData && !paramsDone) GetMissingParameters();
  // Check that all venue contact information is available in booking, otherwise get it from the venue.
  async function GetMissingParameters() {
    if (currentVenue && bookingData) {
      let tmp = JSON.parse(JSON.stringify(bookingData));
      const VenueContactData = [
        "venueStreetAddress",
        "venueZipCode",
        "city",
        "venue",
        "company",
        "postalStreetAddress",
        "postalZipCode",
        "postalCity",
        "email",
        "phone",
        "orgNumber",
        "preCharge",
        "preChargeAt",
        "balance",
        "balanceAt",
      ];

      await VenueContactData.forEach((item) => {
        if (bookingData.venue[item] === currentVenue[item]) {
          console.log(currentVenue[item]);
        } else {
          tmp.venue[item] = currentVenue[item];
        }
      });

      console.log("CHECK TMP", tmp);
      console.log("CHECK aid", bookingData.agreementId);

      // await new Promise((resolve) => setTimeout(resolve, 4000));
      await wait(500);

      console.log("Check currentVenue", currentVenue);
      setBookingData(tmp);
      setPreCharge(tmp.venue["preCharge"]);
      console.log("TMP", tmp);
      console.log(
        tmp.venue["venue"],
        tmp.venue["company"],
        tmp.venue["postalStreetAddress"],
        tmp.venue["postalZipCode"],
        tmp.venue["postalCity"],
        tmp.venue["email"],
        tmp.venue["phone"],
        tmp.venue["preChargeAt"],
        tmp.venue["balanceAt"],
        tmp.venue["preCharge"]
      );
      if (currentVenue)
        // THIS "IF"(above) <= <= <= <= Take away if missing venuedata in agreements....

        try {
          console.log("CHECKUP", tmp.venue);
          await setDoc(
            doc(db, "Agreements", bookingData.agreementId),
            {
              venue: {
                venue: tmp.venue["venue"],
                company: tmp.venue["company"],
                postalStreetAddress: tmp.venue["postalStreetAddress"],
                postalZipCode: tmp.venue["postalZipCode"],
                postalCity: tmp.venue["postalCity"],
                email: tmp.venue["email"],
                phone: tmp.venue["phone"],
                orgNumber: tmp.venue["orgNumber"],
              },
              preChargeAt: tmp.venue["preChargeAt"],
              // balanceAt: tmp.venue["balanceAt"] || 0,
              // preCharge: tmp.venue["preCharge"],
            },
            { merge: true }
          );
        } catch (error) {
          console.error("Error updating document:", error);
        }
      console.log("agreement set?", agreement);
      // alert("setting parameters done");
      setParamsDone(true);
    }
  }

  const [includedAttachments, setIncludedAttachments] = useState([]);
  const handleAttachment = (checked, attachment) => {
    // console.log("attachment", attachment);
    // console.log("checked", checked);
    // if (agreement && agreement.venueSignature) return; // If agreement is signed, no more attachments can be added.

    if (checked) {
      setIncludedAttachments([...includedAttachments, attachment]);
    }
    if (!checked && includedAttachments.length > 1) {
      // console.log("before", includedAttachments);
      setIncludedAttachments(includedAttachments.filter((item) => item !== attachment));
    }
    if (!checked && includedAttachments.length === 1) {
      // console.log("before last", includedAttachments);
      updateDoc(doc(db, "Agreements", props.agreementId), {
        attachments: "",
      }).then(() => {});
      setIncludedAttachments([]);
    }
  };

  useEffect(() => {
    function attachAttachmentsToAgreement() {
      console.log("included Attachments after", includedAttachments);
      setAgreement({ ...agreement, attachments: includedAttachments });
      updateDoc(doc(db, "Agreements", props.agreementId), {
        attachments: includedAttachments.length === 0 ? "" : includedAttachments,
      }).then(() => {});
    }
    if (includedAttachments && includedAttachments.length > 0) attachAttachmentsToAgreement();
  }, [includedAttachments]);

  useEffect(() => {
    setIncludedAttachments(agreement && agreement.attachments ? agreement.attachments : []);
  }, [paramsDone]);

  // first clear all attachments from agreement. THIS HERE!!!!!
  useEffect(() => {
    if (props.sign === "agentSignature") return; // If agreement is signed, no more attachments can be added.
    // updateDoc(doc(db, "Agreements", props.agreementId), {
    //   attachments: "",
    // }).then(() => {});
  }, []);

  // Check if customer email is valid.
  const [validEmail, setValidEmail] = useState(false);
  function validateEpost() {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(bookingData.agent.email)) {
      // This block will run if 'email' is a valid email address
      setValidEmail(true);
    } else {
      // This block will run if 'email' is NOT a valid email address
      setValidEmail(false);
    }
  }

  const handleNavigate = () => {
    navigate("/Owner/Venue", { state: { activeTab: "Media" } });
    handleClose();
  };

  useEffect(() => {
    agreement && validateEpost();
  }, [paramsDone]);

  // Set agreements listener
  useEffect(() => {
    if (currentVenue.venueid === undefined) return;
    console.log("setting agreements listener...", currentVenue.venueid);
    const agreementsRef = collection(db, "Agreements");
    const q = query(agreementsRef, where("venueid", "==", currentVenue.venueid));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const updatedAgreements = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dispatch(storeCurrentAgreements(updatedAgreements));
    });

    return () => unsubscribe();
  }, [currentVenue.venueid, dispatch]); // Add dispatch to dependencies

  useEffect(() => {
    // Rerender if translateModules changes
  }, [translateModules]);

  useEffect(() => {
    console.log("AGREEMENT", agreement);
    if (currentAgreements.length > 0) setAgreement(currentAgreements.find((item) => item.agreementId === props.agreementId));
    else {
      console.log("agreement not found in currentAgreements, loading instead");
      async function getAgreement() {
        const snap = await getDoc(doc(db, "Agreements", props.agreementId));
        // alert("agreement set 3" + snap.data().attachments.length);
        if (snap.data().attachments !== null) {
          setIncludedAttachments(snap.data().attachments);
        }
        return snap.data();
      }
      const tmpAgreement = getAgreement();
      setAgreement(tmpAgreement);
      // if(currentVenue===undefined) dispatch(storeCurrentVenue())
    }
  }, [currentAgreements]);

  // useEffect(() => {
  //   dispatch(storeCurrentPdfDocHTML(document.querySelector("#agreement") && document.querySelector("#agreement").innerHTML));
  //   dispatch(storeCurrentAgreement(agreement));
  // }, [agreement]);

  if (paramsDone)
    // if (bookingData )
    return (
      <div>
        {console.log("HERE RELEVANT BOOKINGS", relevantBookings)}
        {console.log("BOOKINGDATA", bookingData)}
        <div onClick={handleOpen}>
          {/* show correct icon depending on status */}
          {bookingData.status === "Confirmed" && <GradingIcon className="agreementIcon" size={25} style={{ color: "#00ffd499" }} />}
          {bookingData.status === "Pending Request" && (
            <AccessTimeIcon className="agreementIcon agreementAction" size={25} style={{ color: "#00ffd4" }} />
          )}
          {bookingData.status === "Awaiting Artist" && <ReplyIcon className="agreementIcon" size={25} style={{ color: "#00ffd4" }} />}
        </div>

        {console.log("CHECKUP bookingdata:", bookingData)}
        {console.log("CHECKUP currentVenue:", currentVenue)}
        <Modal open={open} onClose={handleClose}>
          {/* <Box sx={boxStyle}> */}
          <Box className="agreementBox">
            {props.sign !== "agentSignature" && props.sign !== "archiving" && bookingData.status !== "Confirmed" && (
              <ConnectAgreement agreementId={props.agreementId} />
            )}
            {console.log("AGREEMENTAL", agreement)}
            {props.sign !== "archiving" && (
              <div>
                <IconButton onClick={() => setLanguage("english")}>🇬🇧</IconButton>
                <IconButton onClick={() => setLanguage("svenska")}>🇸🇪</IconButton>
              </div>
            )}
            <div style={{ padding: "10px" }}>
              {language === "english" && (
                <>
                  <FormControlLabel
                    value="end"
                    control={
                      <Switch
                        size="small"
                        checked={translateModules}
                        onChange={() => setTranslateModules(!translateModules)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Translate specifications"
                    labelPlacement="end"
                  />
                </>
              )}
            </div>
            <div>
              {/* <div style={{ width: "800px" }} id="agreementWrapper"> */}
              <div id="agreementWrapper">
                <div
                  id="agreement"
                  style={{
                    color: "black",
                    background: "white",
                    padding: "50px",
                    fontSize: "12px",
                  }}
                >
                  {/* <head>
                    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet" />
                  </head> */}
                  {/* <img src="agreement.svg" style="width: 100%; max-width: 200px;" /> */}
                  <h2>
                    <br></br>
                    {/* {agreement && agreement.agent.userId ? <b>Rental Agreement</b> : <b style={{ opacity: "0.3" }}>Proposal</b>} */}
                    {agreement && agreement.agentSignature ? (
                      <b>Avtal</b>
                    ) : (
                      <b style={{ opacity: "0.3" }}>{language === "svenska" ? "Offert" : "Proposal"}</b>
                    )}
                  </h2>
                  {currentVenue.venueLogo && (
                    <div className="agreementVenueLogo">
                      <img src={currentVenue.venueLogo} style={{ width: "100%", maxWidth: "200px" }} />
                      <br></br>
                      <br></br>
                    </div>
                  )}

                  {language === "svenska" && (
                    <AvtalsingressSwedish agreement={agreement} bookingData={bookingData} currency={currency} sumIncVat={sumIncVat} />
                  )}
                  {language === "english" && (
                    <AvtalsingressEnglish agreement={agreement} bookingData={bookingData} currency={currency} sumIncVat={sumIncVat} />
                  )}

                  <br></br>
                  <table style={{ fontSize: "12px", padding: "10px", width: "100%", lineHeight: "13px" }}>
                    <th style={{ width: "130px" }}></th>
                    <th></th>
                    <tr>
                      <td></td>
                    </tr>
                    <tr style={{ background: "#efefef" }}>
                      <td colspan="2">
                        <b>VENUE </b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Venue: </b>
                      </td>
                      <td> {agreement.venue.company}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        {agreement.venue.postalStreetAddress}, {agreement.venue.postalZipCode} {agreement.venue.postalCity}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Email: </b>
                      </td>
                      <td>{agreement.venue.email}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Phone: </b>
                      </td>
                      <td>{agreement.venue.phone}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Org.nr: </b>
                      </td>
                      <td>{agreement.venue.orgNumber}</td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                    </tr>
                    <tr style={{ background: "#efefef" }}>
                      <td colspan="3">
                        <b>CUSTOMER </b>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <b>Company: </b>
                      </td>
                      <td>{(agreement.agent && agreement.agent.company) || "-"}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        {(agreement.agent && agreement.agent.streetAddress) || "-"}, {(agreement.agent && agreement.agent.zipCode) || "-"}{" "}
                        {(agreement.agent && agreement.agent.city) || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Reference: </b>
                      </td>
                      <td>{agreement.agent && agreement.agent.reference ? agreement.agent.reference : ""}</td>
                      {/* {console.log("here reference", agreement.agent.reference)} */}
                    </tr>
                    <tr>
                      <td>
                        <b>Org.Nr: </b>
                      </td>
                      <td>{agreement.agent && agreement.agent.orgNumber}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Artist/Event: </b>
                      </td>
                      <td>{agreement.eventTitle || bookingData.artist.artist}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Contact: </b>
                      </td>
                      <td>
                        {agreement.agent && agreement.agent.firstName} {agreement.agent && agreement.agent.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Email: </b>
                      </td>
                      <td>{agreement.agent && agreement.agent.email}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Phone: </b>
                      </td>
                      <td>{agreement.agent && agreement.agent.phone}</td>
                    </tr>
                  </table>

                  <br></br>
                  {specificationsRef.current && console.log("clientHeight", specificationsRef.current.clientHeight)}
                  {/* {specificationsRef.current && specificationsRef.current.clientHeight > 300 && <div className="page-break"></div>} */}
                  {<div className="page-break"></div>}
                  <b>Specifications:</b>
                  {/* <div ref={specificationsRef} style={{ borderRadius: "10px", background: "#00ffd444", padding: "10px" }}> */}
                  {/* <QuickSpecsMulti agreementId={props.agreementId} relevantBookings={relevantBookings} type="normal" /> */}

                  {/* </div> */}
                  <div ref={specificationsRef} style={{ borderRadius: "10px", background: "#00ffd444", padding: "10px" }}>
                    <table style={{ fontSize: "12px", lineHeight: "15px", width: "100%" }}>
                      <thead>
                        <th>Date</th>
                        <th>Product</th>
                        <th>Get in</th>
                        <th>Load in</th>
                        <th>Sound</th>
                        <th>Doors</th>
                        <th>Show</th>
                        <th>Load out</th>
                        <th>Curfew</th>
                        <th>Each</th>
                        <th>Quantity</th>
                        <th>CO2e</th>
                        <th style={{ width: "100px" }}>Amount</th>
                      </thead>
                      {relevantBookings &&
                        relevantBookings.map((item, index) => (
                          <>
                            <tr>
                              <td></td>
                            </tr>
                            <tr>
                              <td></td>
                            </tr>
                            <tr style={{ background: "#00000022", padding: "5px" }}>
                              <td>
                                <b>{item.date}</b>
                              </td>
                              <td>{/* <i>{item.product}</i> */}</td>
                              <td>{item.getIn}</td>
                              <td>{item.loadIn}</td>
                              <td>{item.soundcheck}</td>
                              <td>{item.doors}</td>
                              <td>{item.show}</td>
                              <td>{item.loadOut}</td>
                              <td>{item.curfew}</td>
                              <td></td>
                              <td></td>
                              {/* <td>{item.status}</td> */}
                              <td>{item.co2e}</td>
                              {/* <td>{item.price}</td> */}
                              <td></td>
                            </tr>

                            {item.bookingFeatures &&
                              Object.values(item.bookingFeatures).map((feature) => (
                                <>
                                  {Object.values(feature.options)
                                    // First, filter options that meet the conditions, then sort by price
                                    .filter((option) => !option.optional && option.selected && feature.additional === "Free Text")
                                    // .sort((a, b) => parseFloat(b.price) - parseFloat(a.price)) // Sort by price in descending order
                                    .map((option) => (
                                      <>
                                        <tr style={{ lineHeight: "15px" }}>
                                          <td></td>
                                          <td style={{ borderBottom: "10px solid transparent" }}></td>
                                        </tr>
                                        <tr style={{ lineHeight: "15px" }}>
                                          <td style={{ opacity: "0.4" }}>{/* {feature.additional} */}</td>
                                          <td colspan={numberOfColumns - 3}>
                                            {translateModules && language === "english" ? (
                                              <InsTranslate from="sv" to="en" phrase={option.info} />
                                            ) : (
                                              option.info
                                            )}
                                          </td>
                                          <td></td>
                                          <td>{/* {option.climate ? option.climate : "-"} */}</td>
                                          <td>{/* {numberFormatted(convertStringToNumberOrZero(option.price))} */}</td>
                                        </tr>
                                        <tr style={{ lineHeight: "15px" }}>
                                          <td></td>
                                          <td style={{ borderBottom: "10px solid transparent" }}></td>
                                        </tr>
                                      </>
                                    ))}
                                </>
                              ))}
                            {item.bookingFeatures &&
                              Object.values(item.bookingFeatures).map((feature) => (
                                <>
                                  {/* Show rental fee first */}
                                  {/* {Object.values(feature.options).map(
                                    (option) =>
                                      !option.optional &&
                                      option.selected &&
                                      feature.additional === "Rental Fee" && (
                                        <tr style={{ lineHeight: "15px" }}>
                                          <td style={{ opacity: "0.4" }}>{feature.additional}</td>
                                          <td colspan={numberOfColumns - 3}>
                                            {translateModules && language === "english" ? (
                                              <InsTranslate from="sv" to="en" phrase={option.option} />
                                            ) : (
                                              option.option
                                            )}

                                            {option.info ? `, ${option.info}` : ""}
                                            {option.specs ? `, ${option.specs}` : ""}
                                          </td>
                                          <td>{option.climate ? option.climate : "-"}</td>
                                          <td>{numberFormatted(convertStringToNumberOrZero(option.price))}</td>
                                        </tr>
                                      )
                                  )} */}

                                  {Object.values(feature.options)
                                    // First, filter options that meet the conditions, then sort by price
                                    .filter(
                                      (option) =>
                                        !option.optional &&
                                        option.selected &&
                                        (feature.additional === "Rental Fee" || feature.additional === "Lokalhyra")
                                    )
                                    .sort((a, b) => parseFloat(b.price) - parseFloat(a.price)) // Sort by price in descending order
                                    .map((option) => (
                                      <tr style={{ lineHeight: "15px" }}>
                                        {/* <td style={{ opacity: "0.4" }}>{feature.additional}</td> */}
                                        <td style={{ opacity: "0.4" }}>
                                          {/^\d/.test(feature.additional) ? feature.additional.slice(2) : feature.additional}
                                        </td>
                                        <td colspan={numberOfColumns - 3}>
                                          {translateModules && language === "english" ? (
                                            <InsTranslate from="sv" to="en" phrase={option.option} />
                                          ) : (
                                            option.option
                                          )}
                                          {option.info ? `, ${option.info}` : ""}
                                          {/* {option.specs ? `, ${option.specs}` : ""} */}
                                        </td>
                                        <td></td>
                                        <td>{option.climate ? option.climate : "·"}</td>
                                        <td>{numberFormatted(convertStringToNumberOrZero(option.price))}</td>
                                      </tr>
                                    ))}
                                </>
                              ))}

                            {item.bookingFeatures &&
                              Object.values(item.bookingFeatures)
                                .sort((a, b) => a.additional.localeCompare(b.additional, "sv")) // Sort by feature.additional
                                .map((feature) => (
                                  <>
                                    {Object.values(feature.options)
                                      .sort((a, b) => a.option.localeCompare(b.option, "sv")) // Sort by option.option
                                      .map((option) => (
                                        <>
                                          {/* customer opted features */}
                                          {option.customerOpted && option.price && (
                                            <tr style={{ lineHeight: "15px" }}>
                                              {/* <td style={{ opacity: "0.4" }}>{feature.additional}</td> */}
                                              <td style={{ opacity: "0.4" }}>
                                                {" "}
                                                {/^\d/.test(feature.additional) ? feature.additional.slice(2) : feature.additional}
                                              </td>
                                              <td colspan={numberOfColumns - 3}>{option.option}</td>
                                              <td>{option.climate ? option.climate : "·"}</td>
                                              <td>{numberFormatted(convertStringToNumberOrZero(option.price))}</td>
                                            </tr>
                                          )}
                                          {/* Included features with a cost */}
                                          {
                                            // option.quantity > 0 &&
                                            !option.optional &&
                                              option.selected &&
                                              feature.additional !== "Staff" &&
                                              feature.additional !== "Capacity" &&
                                              feature.additional !== "Free Text" &&
                                              feature.additional !== "Lokalhyra" &&
                                              feature.additional !== "Rental Fee" && (
                                                // feature.additional !== "Stage" &&
                                                <tr style={{ lineHeight: "15px" }}>
                                                  {/* <td style={{ opacity: "0.4" }}>{feature.additional}</td> */}
                                                  <td style={{ opacity: "0.4" }}>
                                                    {" "}
                                                    {/^\d/.test(feature.additional) ? feature.additional.slice(2) : feature.additional}
                                                  </td>
                                                  <td colspan={numberOfColumns - 4}>
                                                    {translateModules && language === "english" ? (
                                                      <InsTranslate from="sv" to="en" phrase={option.option} />
                                                    ) : (
                                                      option.option
                                                    )}

                                                    {option.info && option.info.length > 1 ? `, ${option.info}` : ""}
                                                    {/*  {option.specs ? `, ${option.specs}` : ""} */}
                                                  </td>
                                                  <td style={{ opacity: "0.6" }}>{option.each}</td>
                                                  <td>{option.quantity}</td>
                                                  <td>{option.climate ? option.climate : "."}</td>
                                                  <td>{numberFormatted(convertStringToNumberOrZero(option.price))}</td>
                                                </tr>
                                              )
                                          }
                                          {/* indluded features */}
                                          {/* <DisplayObject data={option} /> */}
                                          {option.selected && option.optional && feature.additional !== "Staff" && (
                                            <tr style={{ lineHeight: "15px" }}>
                                              <td colspan={numberOfColumns - 1}>
                                                <span style={{ opacity: "0.4" }}>{feature.additional}: </span>
                                                <span>{option.option}</span>
                                                <span>{option.price === 0 || (!option.price && !option.optional && " (Included)")}</span>
                                              </td>
                                              <td>{option.optional ? <span style={{ opacity: "0.4" }}>(optional)</span> : option.price}</td>
                                            </tr>
                                          )}
                                        </>
                                      ))}
                                  </>
                                ))}
                          </>
                        ))}
                      {props.climateCompensation && (
                        <>
                          {/* <tr style={{ borderTop: "0.1pt solid #ccc" }}>
                                <th>Additional</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                              </tr> */}
                          {/* <tr style={{ borderTop: "1pt solid transparent" }}>
                                <td colspan="10">CO2e</td>
                                <td>{numberFormatted(Math.round(sumIncVat * 0.035))} kg</td>
                              </tr> */}
                        </>
                      )}
                      <tr>
                        <td colSpan={numberOfColumns + 1}>
                          <div style={{ width: "100%", borderTop: "0.3px solid #00000044" }}></div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={numberOfColumns - 1}>Sum</td>
                        <td></td>
                        <td>
                          <b>{numberFormatted(sum)}</b> {currency}
                        </td>
                      </tr>
                      {Object.keys(vatAmounts).map(
                        //Map through the vat amounts
                        (obj) => (
                          <>
                            {vatAmounts[obj] > 0 && (
                              <tr style={{ lineHeight: "15px" }}>
                                <td></td>
                                <td colSpan={numberOfColumns - 1}>vat({obj}%)</td>
                                <td>{numberFormatted(vatAmounts[obj])}</td>
                              </tr>
                            )}
                          </>
                        )
                      )}
                      <tr>
                        <td></td>
                      </tr>
                      <tr>
                        <td colSpan={numberOfColumns + 1}>
                          <div style={{ width: "100%", borderTop: "0.3px solid black" }}></div>
                        </td>
                      </tr>
                      <tr style={{ lineHeight: "15px" }}>
                        <td colSpan={numberOfColumns - 1}>Total</td>
                        <td></td>
                        <td style={{ width: "50pt" }}>
                          {numberFormatted(sumIncVat)} {currency}
                        </td>
                      </tr>
                    </table>
                  </div>

                  <table>
                    {bookingFeatures && console.log(bookingFeatures)}

                    {bookingFeatures &&
                      Object.values(bookingFeatures).map((feature) => (
                        <>
                          {Object.values(feature.options).map((option) => (
                            <>
                              {console.log("option", option)}
                              <tr>{option.customerOpted && <>{option.option}</>}</tr>

                              <tr>{option.selected && option.option}</tr>
                            </>
                          ))}
                        </>
                      ))}
                  </table>
                  <br></br>
                  {/* {props.climateCompensation ? (
                    // <div>CO2e: {Math.round(sumIncVat * 0.035)} kg. 🌿 CO2e compensation initialized.</div>
                    <div>CO2e: -</div>
                  ) : (
                    // <div>CO2e: {Math.round(sumIncVat * 0.035)} kg.</div>
                    <div>CO2e: -</div>
                  )} */}

                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>

                  <div>
                    <div style={{ fontSize: "10px", marginBottom: "10px" }} className="specialConditions">
                      {agreement.addendum && agreement.addendum.useAddendum && (
                        <>
                          <b style={{}}>
                            {translateModules && language === "english" ? (
                              <InsTranslate from="sv" to="en" phrase={"SÄRSKILDA VILLKOR:"} />
                            ) : (
                              "SÄRSKILDA VILLKOR:"
                            )}
                          </b>
                          <div style={{ borderRadius: "10px", padding: "15px", width: "95%", background: "#00ffd433" }}>
                            <div className="text">
                              {translateModules && language === "english" ? (
                                <InsTranslate from="sv" to="en" phrase={agreement.addendum.text} />
                              ) : (
                                agreement.addendum.text
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="page-break"></div>
                    {language === "svenska" && <GeneralConditionsSwedish agreement={agreement} />}
                    {language === "english" && <GeneralConditionsEnglish agreement={agreement} />}
                  </div>
                  <table style={{ fontSize: "12px", padding: "10px", width: "100%", lineHeight: "13px" }}>
                    <tr>
                      <br></br>
                      <span style={{ fontSize: "8px" }}>{props.agreementId}</span>
                      <br></br>
                    </tr>
                    <tr>
                      <td>{includedAttachments && includedAttachments.length > 0 && <b>Addendum:</b>}</td>
                    </tr>

                    <tr>
                      <td style={{ padding: "10px" }}>
                        <div>
                          {includedAttachments && includedAttachments.length > 0 && (
                            <div>
                              {includedAttachments.map((attachment) => (
                                <div>
                                  {attachment.url.includes(".pdf") ? (
                                    <a href={attachment.url} style={{ background: "#aaa", padding: "0px 10px" }}>
                                      📄 {attachment.name}
                                    </a>
                                  ) : attachment.url.includes(".doc") || attachment.url.includes(".docx") ? (
                                    <a href={attachment.url} style={{ background: "#aaa", padding: "0px 10px" }}>
                                      📄 {attachment.name}
                                    </a>
                                  ) : attachment.url.includes(".xls") || attachment.url.includes(".xlsx") ? (
                                    <a href={attachment.url} style={{ background: "#aaa", padding: "0px 10px" }}>
                                      📄 {attachment.name}
                                    </a>
                                  ) : null}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  </table>

                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  {console.log("Agreement?", agreement)}
                  {/* Signatures */}
                  <div style={{ height: "100px", width: "400px" }}>
                    {agreement.venueSignature && agreement.agentSignature ? (
                      <span style={{ margin: "2pt", color: "blue" }}>
                        Signed by {agreement.venueSignature.userId} at {agreement.venueSignature.date}
                      </span>
                    ) : (
                      ""
                    )}
                    <div style={{ borderTop: "1px solid black", marginTop: "5px" }}>Venue: {agreement.venue.venue}</div>

                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div style={{ height: "100px", width: "400px" }}>
                      {agreement.agentSignature && (
                        <span style={{ margin: "2pt", color: "blue" }}>
                          Signed by {agreement.agentSignature.userId} at {agreement.agentSignature.date}
                        </span>
                      )}
                      <div style={{ borderTop: "1px solid black" }}>
                        Customer: {agreement.agent && agreement.agent.firstName} {agreement.agent && agreement.agent.lastName}
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div style={{ position: "relative" }}>
                    <div style={{ position: "absolute", right: "20px", bottom: "15px", verticalAlign: "middle" }}>
                      {/* Powered by: */}
                      <div>
                        <img
                          width="90px"
                          height="auto"
                          src="https://firebasestorage.googleapis.com/v0/b/venuehub-64e72.appspot.com/o/images%2Fvh-logos%2FVenuehub_Blackonwhite.png?alt=media&token=f6fe81d1-74f8-424f-8f48-1f4ddf89a943"
                        />
                      </div>
                    </div>
                  </div>
                  {console.log("sustainabilityData", sustainabilityData)}
                  {sustainabilityData && (
                    <div style={{ position: "relative" }}>{/* <VisualDataSummaryPDF formData={sustainabilityData} /> */}</div>
                  )}
                </div>
              </div>
              <div style={{}}>
                {/* <IconButton style={{ marginTop: "20px" }} variant="outlined" onClick={() => savePDF()}>
                  <DownloadIcon />
                </IconButton> */}
                <div style={{ padding: "15px" }}>
                  {/* <PdfDownloader
                    doc={document.querySelector("#agreement") && document.querySelector("#agreement").innerHTML}
                    setPdfUrl={setPdfUrl}
                    includedAttachments={includedAttachments}
                    agreementId={bookingData.agreementId}
                  /> */}
                  {bookingData && (
                    <PdfHandler
                      pdfDoc={document.querySelector("#agreement") && document.querySelector("#agreement").innerHTML}
                      setPdfUrl={setPdfUrl}
                      includedAttachments={includedAttachments}
                      // agreementId={bookingData.agreementId}
                      agreement={agreement}
                    />
                  )}
                  {/* PDF: {pdfUrl && pdfUrl} */}
                </div>
                {/* ATTACHMENTS */}
                {props.sign !== "agentSignature" && currentVenue && currentVenue.attachments && bookingData.status !== "Confirmed" && (
                  <>
                    {/* {!validEmail && (
                      <div
                        style={{
                          background: validateEmail(bookingData.agent.email) ? "green" : "orange",
                          color: "black",
                          textIndent: "15px",
                        }}
                      >
                        {validateEmail(bookingData.agent.email)
                          ? "Sending to:  "
                          : "Please enter the booking customer email address before signing:  "}
                        <input
                          placeholder="  Enter email"
                          onChange={(e) =>
                            setBookingData((prev) => ({
                              ...prev,
                              agent: {
                                ...prev.agent,
                                email: e.target.value,
                              },
                            }))
                          }
                        />
                      </div>
                    )} */}
                    <div style={{ padding: "15px", marginBottom: "20px", background: "#222" }}>
                      <AgreementAddendum agreement={agreement} type="sign" />

                      <h7 style={{ cursor: "pointer" }} onClick={() => handleNavigate()}>
                        Add attachment?
                      </h7>
                      {/* <PdfUploader /> */}
                      {currentVenue.attachments.map((attachment) => (
                        <div style={{ fontSize: "12px", color: "#00ffd499" }}>
                          <Switch
                            checked={includedAttachments && includedAttachments.some((obj) => obj.url === attachment.url)}
                            onChange={(e) => handleAttachment(e.target.checked, attachment)}
                            // checked={includedAttachments.some((att) => att.url === attachment.url)}
                          />
                          <a href={attachment.url} target="_blank">
                            {attachment.name}
                          </a>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {/* {props.sign !== "agentSignature" && includedAttachments.length > 0 && (
                  <div style={{ marginBottom: "20px" }}>
                    Attachments included:{" "}
                    {includedAttachments.map((item) => (
                      <div style={{ background: "#222", padding: "5px", marginBottom: "5px" }}>{item.name}</div>
                    ))}
                  </div>
                )} */}
                {/* {props.sign === "agentSignature" && agreement.attachments.length > 0 && (
                  <div style={{ marginBottom: "20px" }}>
                    Contract amendments:{" "}
                    {agreement.attachments.map((item) => (
                      <div style={{ background: "#222", padding: "5px", marginBottom: "5px" }}>{item.name}</div>
                    ))}
                  </div>
                )} */}
                {/* <div style={{ margin: "20px 0px" }}>
                  <AttachmentUpload />
                </div> */}
                {console.log("agreementId:", props.agreementId)}
                {/* {superOwner && bookingData.agent.email} */}
                {bookingData.status === "Pending Request" && (
                  <BatchAgreementSign
                    agreementId={props.agreementId}
                    bookingIds={bookingIds}
                    sign={props.sign}
                    socialId={currentUser.socialId}
                    phone={currentUser.phone}
                    reciever={bookingData.agent.email}
                    bookingData={bookingData}
                    agreement={agreement}
                    setAgreement={setAgreement}
                    sumIncVat={sumIncVat}
                  />
                )}
                {bookingData.status === "Awaiting Artist" && (
                  <BankidSignAgreement
                    // agreementId={props.agreementId}
                    bookingIds={bookingIds}
                    sign={props.sign}
                    socialId={currentUser.socialId}
                    phone={currentUser.phone}
                    reciever={bookingData.agent.email}
                    bookingData={bookingData}
                    agreement={agreement}
                    setAgreement={setAgreement}
                    sumIncVat={sumIncVat}
                  />
                )}
                {props.sign !== "agentSignature" && (
                  <div
                    style={{
                      height: "20px",
                      display: "flex",
                      gap: "10px",
                      verticalAlign: "middle",
                      textIndent: "15px",
                      position: "absolute",
                      right: "20px",
                      opacity: "0.5",
                    }}
                  >
                    {superOwner && (
                      <>
                        {props.agreementId}
                        <EmailProgressBar docId={props.agreementId} setSentArray={setSentArray} response="popper" />
                      </>
                    )}
                    Send test:
                    <input
                      placeholder="enter test email"
                      onChange={(e) => setSendEmail(e.target.value)}
                      style={{
                        height: "20px",
                        background: "#ffffff22",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        padding: "5px",
                      }}
                    />
                    <Button onClick={() => testSend()}>Send</Button>
                  </div>
                )}
                {/* {!currentUser.socialId && (
                  <>
                    <div>Complete your user information below in order to sign agreements.</div>
                    <GeneralForm
                      form={userObj}
                      formName="userForm"
                      priority={10000}
                      setUserData={setUserData}
                      fields={["firstName", "lastName", "socialId", "phone", "update"]}
                    />
                  </>
                )} */}
              </div>
            </div>
            {/* <PrivacyPolicy /> */}
          </Box>
        </Modal>
      </div>
    );
};

export default BatchAgreementModal;

const useStyles = {
  paper: {
    position: "absolute",
    width: "60%",
    height: "90%",
    backgroundColor: "yellow",
    border: "2px solid #000",
    boxShadow: "5px 5px 10px black",
    padding: "10px",
  },
};

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "80vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};
