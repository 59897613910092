/** @format */

import React, { useState, useEffect, useCallback } from "react";
import Slider from "@mui/material/Slider";
import { parse, format, set } from "date-fns";

function TimeSlot({ item, formlock, handleChange, collision }) {
  const [values, setValues] = useState([300, 1080]); // Minutes from 05:00 to 04:30 next day
  const [editableIndices, setEditableIndices] = useState([1, 5]);
  const [timeLabels, setTimeLabels] = useState(["10:00", "18:00"]);
  const [loadIn, setLoadIn] = useState(item.loadIn);
  const [loadOut, setLoadOut] = useState(item.loadOut);

  const timeToMinutes = useCallback((time) => {
    if (!time) return 0;
    const parsedTime = parse(time, "HH:mm", new Date());
    const hours = parsedTime.getHours();
    const minutes = parsedTime.getMinutes();
    return (hours - 5) * 60 + minutes;
  }, []);

  const minutesToTime = useCallback((minutes) => {
    const hours = Math.floor(minutes / 60) + 5;
    const mins = minutes % 60;
    const date = set(new Date(0, 0, 0, hours, mins), { milliseconds: 0 });
    return format(date, "HH:mm");
  }, []);

  useEffect(() => {
    if (loadIn && loadOut) {
      const loadInMinutes = Math.round(timeToMinutes(loadIn) / 30) * 30; // Round to nearest 30 mins
      const loadOutMinutes = Math.round(timeToMinutes(loadOut) / 30) * 30;

      setValues([loadInMinutes, loadOutMinutes]);
    }
  }, [loadIn, loadOut, timeToMinutes]);

  useEffect(() => {
    setTimeLabels([minutesToTime(values[0]), minutesToTime(values[1])]);
  }, [values, minutesToTime]);

  const handleNewValue = (event, newValue) => {
    setValues(newValue);
  };

  const handleSliderChange = (event, newValue) => {
    // setValues(newValue);

    handleChange({ ...item, loadIn: minutesToTime(newValue[0]), loadOut: minutesToTime(newValue[1]) });
  };

  const marks = Array.from({ length: 48 }, (_, i) => ({
    value: i * 30,
    label: i % 2 === 0 ? minutesToTime(i * 30) : "",
  }));

  const valueLabelFormat = (value) => {
    return minutesToTime(value);
  };

  //   useEffect(() => {
  //     {
  //       console.log("kuken", kuken);
  //     }
  //     // if (onChange) {
  //     //   onChange(kuken);
  //     // }
  //   }, [kuken, onChange]);

  return (
    <div>
      {timeLabels[0]} - {timeLabels[1]}
      <Slider
        disabled={formlock}
        value={values}
        // onChange={handleChange({ ...item, loadIn: timeToMinutes(values[0]), loadOut: timeToMinutes(values[1]) })}
        // onChange={() => setKuken((prev) => ({ ...prev, valuesA: values[0], valuesB: values[1] }))}
        onChange={handleNewValue}
        onChangeCommitted={handleSliderChange}
        // onChangeCommitted={() => handleChange({ ...item, loadIn: minutesToTime(values[0]), loadOut: minutesToTime(values[1]) })}
        min={0}
        max={1410}
        step={30}
        valueLabelDisplay="auto"
        aria-labelledby="time-slot-slider"
        marks={marks}
        valueLabelFormat={valueLabelFormat}
        style={{ color: collision ? "red" : "" }}
      />
    </div>
  );
}

export default TimeSlot;
