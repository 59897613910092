/** @format */

import React, { useState, useEffect } from "react";
import { Grid, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight, Event, DateRange } from "@mui/icons-material";
import { storeCurrentMonth, storeCurrentYear } from "../../features/venueFilter/venueFilterSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import MonthRangePicker from "../UI helpers/MonthRangePicker";
import CalendarQuickView from "./CalendarQuickView/CalendarQuickView";

export const MonthNavigator = (props) => {
  const cs = useSelector((state) => state.counter.currentSettings);
  const superOwner = useSelector((state) => state.counter.superOwner);
  const [currentMonth, setCurrentMonth] = useState(cs.vcMonth);
  const [currentYear, setCurrentYear] = useState(cs.vcYear);
  const [openMonthPicker, setOpenMonthPicker] = useState(false);
  // const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  // const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const dispatch = useDispatch();

  useEffect(() => {
    props.setSelectedMonth(currentMonth);
    props.setSelectedYear(currentYear);
    dispatch(storeCurrentMonth(currentMonth));
    dispatch(storeCurrentYear(currentYear));
  }, [currentMonth, currentYear]);

  const handlePreviousClick = () => {
    props.setWeeklyMode(false);
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleNextClick = () => {
    props.setWeeklyMode(false);
    props.setEventsByWeek([]);
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const resetMonth = () => {
    props.setWeeklyMode(false);
    setCurrentMonth(new Date().getMonth());
    setCurrentYear(new Date().getFullYear());
  };

  const weeklyViewHandler = () => {
    props.setWeeklyMode(!props.weeklyMode);
  };
  return (
    <div>
      <IconButton onClick={handlePreviousClick}>
        <ChevronLeft />
      </IconButton>
      <span onClick={() => setOpenMonthPicker(!openMonthPicker)} className="ShownMonthYear">
        {months[currentMonth]} {currentYear}
      </span>
      <IconButton onClick={handleNextClick}>
        <ChevronRight />
      </IconButton>
      {/* <IconButton onClick={resetMonth}>
        <Event />
      </IconButton>
      <IconButton onClick={weeklyViewHandler}>
        <DateRange />
      </IconButton> */}
      <div style={{ position: "relative" }}>
        <div className="monthPickerModule">
          <MonthRangePicker
            setCurrentMonth={setCurrentMonth}
            setCurrentYear={setCurrentYear}
            setOpenMonthPicker={setOpenMonthPicker}
            openMonthPicker={openMonthPicker}
          />
        </div>
      </div>
      {/* {superOwner && (
        <div style={{ width: "500px", overflow: "hidden" }}>
          <CalendarQuickView />
        </div>
      )} */}
    </div>
  );
};
