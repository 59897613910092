/** @format */

import React, { useState, useRef, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

function FollowCursorTooltip({ children, title }) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const tooltipRef = useRef(null);

  const handleMouseMove = (event) => {
    setPosition({ x: event.clientX + 10, y: event.clientY + 10 }); // Add offset
  };

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div ref={anchorRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ display: "inline-block" }}>
      {children}
      {open && (
        <div
          ref={tooltipRef}
          style={{
            position: "fixed",
            left: `${position.x}px`,
            top: `${position.y}px`,
            pointerEvents: "none",
            zIndex: 1000, // Ensure it's above other elements
          }}
        >
          <Tooltip title={title} open={true} placement="bottom-start">
            <span></span> {/* Hidden span for Tooltip */}
          </Tooltip>
        </div>
      )}
    </div>
  );
}

export default FollowCursorTooltip;
