/** @format */

import React, { useState, useEffect } from "react";
import { db } from "../../firebase"; // Firestore imports
import { doc, setDoc } from "firebase/firestore";
import { CircularProgress } from "@mui/material";
import ObjectSize from "../../helpers/ObjectSize";
import { useSelector } from "react-redux";

// Firestore imports

function MouseTracker() {
  const superOwner = useSelector((state) => state.counter.superOwner);
  const [mouseMoves, setMouseMoves] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentUser = useSelector((state) => state.counter.currentUser);

  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    setMouseMoves((prevMoves) => [...prevMoves, { x: clientX, y: clientY }]);
  };

  /**
   * Store the current mouseMoves array in Firestore.
   * This writes to the doc with ID "mouseTrack" in the "tracker" collection.
   */
  const storeMouseMoves = async () => {
    try {
      setLoading(true);
      await setDoc(doc(db, "Tracker", "mouseTrack"), {
        mouseMoves,
        updatedAt: new Date().toISOString(), // e.g., record when it was saved
        userId: currentUser.email,
      });
      setLoading(false);
      setMouseMoves([]); // Clear the array after saving
      //   console.log("Mouse moves saved to Firestore.");
    } catch (error) {
      setLoading(false);
      //   console.error("Error saving mouse moves:", error);
    }
  };

  /**
   * Use an interval to store data every 10 seconds.
   * This effect re-runs whenever `mouseMoves` changes to ensure we
   * save the latest data on the next interval.
   */
  useEffect(() => {
    // Only set up the interval if there are moves to save, but this is optional.
    if (mouseMoves.length === 0) return;
    if (mouseMoves.length > 100) {
      storeMouseMoves();
    }
    // const intervalId = setInterval(() => {
    //   storeMouseMoves();
    // }, 5000);

    // Cleanup on unmount (or if mouseMoves length changes)
    // return () => clearInterval(intervalId);
  }, [mouseMoves]);

  useEffect(() => {
    // function handleMouseMove(event) {
    //   setMousePosition({ x: event.clientX, y: event.clientY });
    //   // handleMouseMove(event);
    // }

    // Attach to the `window` (or `document`) for global mouse tracking
    window.addEventListener("mousemove", handleMouseMove);

    // Cleanup when component unmounts
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  if (superOwner)
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          //   border: "2px solid #000",
          //   marginBottom: "1rem",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: -10,
          // pointerEvents: "none",
        }}
        // onMouseMove={handleMouseMove}
      >
        <p style={{ position: "absolute", top: 0, right: 0, margin: 0 }}>
          {" "}
          Length: {mouseMoves.length} {loading && <CircularProgress />} Move your mouse in this box
        </p>

        {/* <div>
        <h4>Mouse Moves (Length: {mouseMoves.length}):</h4>
        <ul>
          {mouseMoves.map((move, index) => (
            <li key={index}>
              x: {move.x}, y: {move.y}
            </li>
          ))}
        </ul>
      </div> */}
      </div>
    );
}

export default MouseTracker;
