/** @format */

import React, { useEffect, useState } from "react";
import { SingleBookingContainer } from "./SingleBookingContainer";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { ArtistInfoModal } from "../Modals/ArtistInfoModal";
import { Portal } from "react-portal";
import { GeneralModal } from "../Modals/GeneralModal";
import BookingViewOptions from "./BookingViewOptions";
import MultiBookingsCalendar from "./MultiBookingsCalendar";

export const BookingsByMonthContainer = (props) => {
  const [showArtistInfo, setShowArtistInfo] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const currentLoadingBookingsProgress = useSelector((state) => state.counter.currentLoadingBookingsProgress);
  const currentBookings = useSelector((state) => state.counter.currentBookings);
  const currentVenueBookings = useSelector((state) => state.counter.currentVenueBookings);
  const onClickHandler = (artist) => {
    setShowArtistInfo(true);
    setSelectedArtist(artist);
  };

  const sortedBookings = currentBookings
    .filter(
      (booking) =>
        booking.bookingid &&
        (booking.status === "Confirmed" || booking.status === "Awaiting Artist" || booking.status === "Pending Request")
    )
    .sort((a, b) => {
      const dateA = new Date(`${a.date}T${a.loadIn}`);
      const dateB = new Date(`${b.date}T${b.loadIn}`);
      return dateA - dateB;
    });

  useEffect(() => {
    console.log("currentVenueBookings", currentVenueBookings);
  }, [currentVenueBookings]);

  useEffect(() => {
    console.log("currentBookings", currentBookings);
  }, [currentBookings]);

  return (
    <>
      {/* <ArtistInfoModal
        setOpen={() => {
          setShowArtistInfo(true);
        }}
        open={showArtistInfo}
        onClose={() => setShowArtistInfo(false)}
        selectedArtist={selectedArtist}
        selectedEvent={selectedEvent}
      /> */}
      <Grid container>
        {/* {console.log("currentBookings", currentBookings)} */}
        {sortedBookings.map((booking, i) => {
          if (!booking.bookingid) {
            return;
          }
          if (booking.status === "Confirmed" || booking.status === "Awaiting Artist" || booking.status === "Pending Request") {
            return (
              <Grid
                container
                xs={12}
                sm={4.5}
                md={3.5}
                lg={2.5}
                key={booking.bookingid}
                sx={{
                  marginLeft: {
                    xs: "0px", // Margin for extra small screens
                    sm: "20px", // Margin for small screens
                  },
                  marginBottom: "20px",
                }}
                className="BookingsByMonthContainer"
              >
                <SingleBookingContainer
                  event={booking}
                  action={() => {
                    setShowArtistInfo(true);
                  }}
                  setSelectedArtist={setSelectedArtist}
                  setSelectedEvent={setSelectedEvent}
                />
              </Grid>
            );
          }
        })}
      </Grid>
    </>
  );
};
