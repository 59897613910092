/** @format */

import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import { addDoc, collection, doc, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import { bookingsObj } from "../dbObjects.js/dbBookings";
import { sampleArtist } from "../dbObjects.js/dbSampleArtist";
import { createId, db } from "../firebase";
import { checkBookings, isArtistAvailable, updateBookedDatesForAllArtists } from "./CheckArtistAvailability";
import SelectArtist from "./SelectArtist";

export const SendRequest = (currentArtist, currentAgent, availData, proposedDate, agreementId, bookingid) => {
  const today = new Date().toISOString().split("T")[0];

  console.log(today);
  // const [agent, setAgent] = useState(currentAgent);
  // const [venue, setVenue] = useState(availData);
  // const [artist, setArtist] = useState(currentArtist);
  // const [date, setDate] = useState(proposedDate);
  const agent = currentAgent;
  let venue = JSON.parse(JSON.stringify(availData));
  const artist = currentArtist;

  console.log("venue, deductfromtickets?", venue);
  createRequest();

  async function createRequest() {
    let booking = [];

    // set all the fields for booking to ""
    await bookingsObj.map((data) => {
      booking[data.id] = "";
    });
    // venue.venue.bookings = "";
    const product = venue.venue.products && venue.venue.products.filter((product) => product.productid === venue.productid); // Aktuell produkt
    // const priceIncVat = product && product[0].priceIncVat ? product[0].priceIncVat : 0; // produktens pris inkl moms
    console.log("availData", availData);
    console.log("venue", venue);
    console.log("product", product);
    console.log("PRODUCT and ID", product[0], product[0].productid);
    console.log("THIS is Venue", venue);
    venue.venue.bookings = "-";

    // If there's no product, we just give bookingFeatures some capacity and rental fee.
    const bookingFeatures =
      product && product[0].features
        ? product[0].features
        : {
            nne2yi5m: {
              type: "capacityFields",
              additionalId: "nne2yi5m",
              options: {
                q6qobn53: {
                  option: "Standard",
                  capStanding: "",
                  optionId: "q6qobn53",
                  selected: true,
                  capacity: "468",
                  climate: "",
                  each: 0,
                  price: 0,
                  capSeated: "468",
                  vat: "25%",
                },
              },
              additional: "Capacity",
            },
            "3xsdeavf": {
              additionalId: "3xsdeavf",
              additional: "Rental Fee",
              type: "rentalFeeFields",
              options: {
                cekaezqc: {
                  optionId: "cekaezqc",
                  selected: true,
                  each: 0,
                  climate: "",
                  option: "Standard Fee",
                  price: "30000",
                  vat: "25%",
                },
              },
            },
          }; // produktens features
    console.log(
      "Checking balance stuff",
      venue.balance,
      venue.balanceAt,
      "upfront",
      venue.upfront,
      venue.upfrontAt,
      "preCharge",
      venue.preCharge,
      venue.preChargeAt
    );
    // then fill it with some data
    booking = {
      updated: Timestamp.fromDate(new Date()),
      agreementId: agreementId,
      documentid: bookingid,
      bookingid: bookingid,
      date: proposedDate,
      created: today,
      status: venue.status ? venue.status : "Pending Request",
      agentid: agent.userid,
      agent: agent,
      artistid: artist.artistid,
      artist: artist,
      bookingFeatures: bookingFeatures, // get bookingFeatures from the specific productdid
      venueid: venue.venueid,
      venue: venue.venue,
      product: venue.product,
      productid: venue.productid,
      getIn: venue.getIn,
      loadIn: venue.loadIn,
      soundcheck: venue.soundcheck,
      doors: venue.doors,
      show: venue.show,
      loadOut: venue.loadOut,
      curfew: venue.curfew,
      phone: venue.venue.phone,
      email: venue.venue.email,
      price: venue.price,
      priceIncVat: venue.priceIncVat,
      upfront: venue.upfront,
      upfrontAt: venue.upfrontAt,
      preCharge: venue.preCharge,
      preChargeAt: venue.preChargeAt,
      balance: venue.balance,
      balanceAt: venue.balanceAt,
      tixlyId: venue.tixlyId,
      ticketEventId: venue.ticketEventId,
      ticketVenueId: venue.venue.ticketVenueId,
      deductFromTickets: venue.venue.deductFromTickets,
      tickets: [{ type: "Tickets A", cost: 0, quantity: 0, vat: 0, sum: 0 }],
      ticketSummary: [{ ticketsSold: 0, ticketsTotal: 0 }],
      summary: [{ type: "", cost: 0, quantity: 0, vat: "25%", vatSum: 0 }],
      summaryTotal: { totalExclVat: 0, totalInclVat: 0, vat: 0 },
    };
    console.log("New Booking: ", booking, "Venue: ", venue);
    saveBooking(booking);
  }

  const saveBooking = async (booking) => {
    var bookingKeys = Object.keys(booking);
    bookingKeys.map((key) => {
      if (booking[key] == null) {
        booking[key] = "-";
      }
    });

    // booking.bookingid = uuid();
    // booking.bookingid = uuid();

    try {
      function findUndefinedProperties(obj, parentKey = "") {
        let undefinedProps = [];

        for (const key in obj) {
          // Construct the path (key) for nested objects
          const currentKey = parentKey ? `${parentKey}.${key}` : key;

          if (obj[key] === undefined) {
            // If the value is undefined, log the key
            undefinedProps.push(currentKey);
          } else if (obj[key] !== null && typeof obj[key] === "object") {
            // If it's a nested object, recursively find undefined properties
            undefinedProps = undefinedProps.concat(findUndefinedProperties(obj[key], currentKey));
          }
          // Optionally, add checks for any specific keys that you expect to exist
        }

        return undefinedProps;
      }

      const undefinedProperties = findUndefinedProperties(booking);
      console.log("UNDEFINED", undefinedProperties); // Logs paths to undefined properties
      findUndefinedInObject(booking);
      console.log("BOOKING", booking);
      // await setDoc(doc(db, "Bookings", booking.bookingid), { ...booking });
      // booking[venue].bookings = "";
      // console.log("This", booking[venue].bookings);
      console.log("THIS BOOKING", booking);
      await setDoc(doc(db, "Bookings", booking.bookingid), { ...booking });
      console.log("ProductId:", booking.productid);
    } catch (error) {
      console.error("Error in booking. Parameter missing or undefined?", booking);
    }
  };
};

function findUndefinedInObject(obj, path = "") {
  // Iterate over all properties of the object
  for (const key in obj) {
    // Construct the path for nested objects
    const currentPath = path ? `${path}.${key}` : key;

    // Check if the current property is an object, and if so, search it recursively
    if (obj[key] !== null && typeof obj[key] === "object") {
      findUndefinedInObject(obj[key], currentPath);
    } else if (obj[key] === undefined) {
      // If the current property is undefined, log its path
      console.log(`Found undefined at path: ${currentPath}`);
    }
  }
}
