/** @format */

import { MenuItem, Select } from "@mui/material";
import { set } from "date-fns";
import React, { useState } from "react";
import { useEffect } from "react";
import { privateIcon, publicIcon } from "../../helpers/customIcons";
import { useSelector } from "react-redux";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { storeCurrentVenue } from "../../features/venueFilter/venueFilterSlice";
import { useDispatch } from "react-redux";

// const SelectProductInBookingForm = ({ bookingData, setBookingData, currentVenue, formLock }) => {
const SelectProductInBookingForm = ({ bookingData, setBookingData, formLock }) => {
  const dispatch = useDispatch();
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  console.log("currentVenue products", currentVenue.products);
  const sortedProducts = Object.values(currentVenue.products).sort((b, a) => {
    console.log("a", a);
    console.log("b", b);
    if (!a.privatePublic || !b.privatePublic) return 0;
    return a.privatePublic.localeCompare(b.privatePublic);
  });

  console.group("sorted products", sortedProducts);
  const [products, setProducts] = useState(sortedProducts);
  const [value, setValue] = useState();
  console.log("bookingData", bookingData);
  console.log("products", products);
  const handleChange = async (product) => {
    let tmp = JSON.parse(JSON.stringify(bookingData));
    tmp.bookingFeatures = product.features;
    tmp.productid = product.productid;
    // alert("productid " + tmp.productid);
    tmp.product = product.product;
    tmp.getIn = product.getIn;
    tmp.loadIn = product.loadIn;
    tmp.soundcheck = product.soundcheck;
    tmp.doors = product.doors;
    tmp.show = product.show;
    tmp.loadOut = product.loadOut;
    tmp.curfew = product.curfew;
    console.log("tmp", tmp);
    console.log(tmp, product.features, product.productid, product.product);

    console.log("bookingData new", bookingData);
    // setBookingData(tmp, product.features, product.productid, product.product); // this might be horribly wrong and cause modules to be overwritten or deleted.
    setBookingData(tmp, product.features, product.productid, product.product);
  };

  // create a retired product object to be used when the previously choosen product is not found in the current venue
  const retiredProductData = {
    productid: bookingData.productid,
    product: `${bookingData.product} (retired)`,
    features: bookingData.bookingFeatures,
    getIn: bookingData.getIn,
    loadIn: bookingData.loadIn,
    soundcheck: bookingData.soundcheck,
    doors: bookingData.doors,
    show: bookingData.show,
    loadOut: bookingData.loadOut,
    curfew: bookingData.curfew,
    features: bookingData.bookingFeatures,
    privatePublic: bookingData.privatePublic,
  };

  useEffect(() => {
    if (value === undefined || value.productid === bookingData.productid) return;
    console.log("value", value);
    // alert("value " + value + " " + bookingData.productid);
    handleChange(value);
  }, [value]);

  useEffect(() => {
    console.log("bookingData.productid", bookingData.productid);
    const currentProduct = products.filter((product) => product.productid === bookingData.productid);

    // Om produkten inte finns i currentVenue, använd retiredProductData (alltså den produktdata som användaren valt tidigare)
    if (currentProduct.length === 0) {
      setValue(retiredProductData);
      setProducts([...products, retiredProductData]);
      return;
    }
    setValue(currentProduct[0]);
  }, [products]);

  useEffect(() => {
    console.log("bookingData:=!", bookingData);
  }, [bookingData]);

  useEffect(() => {
    setProducts(
      Object.values(currentVenue.products).sort((b, a) => {
        console.log("a", a);
        console.log("b", b);
        if (!a.privatePublic || !b.privatePublic) return 0;
        return a.privatePublic.localeCompare(b.privatePublic);
      })
    );
  }, [currentVenue]);

  console.log("value", value);
  if (value)
    return (
      <div>
        {console.log("value", value)}
        <Select
          disabled={formLock}
          sx={{ width: "250px" }}
          size="small"
          label="Product"
          value={value}
          // productid={bookingData.productid}
          // onChange={(e) => handleChange(e.target.value)}
          onChange={(e) => setValue(e.target.value)}
        >
          {console.log("products HERE", products)}
          {console.log("bookingData HERE", bookingData)}
          {products.map((product) => (
            <MenuItem label={product.product} value={product}>
              {product.privatePublic === "Public Event" ? publicIcon : privateIcon} {product.product}{" "}
              <span style={{ opacity: "0.3", marginLeft: "5px" }}>
                {product.loadIn}-{product.loadOut}
              </span>
            </MenuItem>
          ))}
        </Select>
      </div>
    );
};

export default SelectProductInBookingForm;
