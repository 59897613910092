/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import { Provider } from "react-redux";
import store from "./app/store";
import zIndex from "@mui/material/styles/zIndex";
import ClickLog from "./helpers/ClickLog";
import Helper from "./helpers/HelpTool";
import MouseTracker from "./features/Trackers/MouseTracker";
import MouseDisplay from "./features/Trackers/MouseDisplay";

// const log = console.log;
// const db = firebase.firestore();
const root = ReactDOM.createRoot(document.getElementById("root"));

// HELPER start
// const Helper = () => {
//   return <div className="helper">I'm a helper!</div>;
// };

// const helperRoot = document.createElement("div");
// helperRoot.id = "helper-root"; // Give it an ID
// document.body.appendChild(helperRoot);

// ReactDOM.createPortal(<Helper />, helperRoot);
// HELPER end

root.render(
  <>
    {/* <React.StrictMode> */}
    <Provider store={store}>
      {/* <Helper /> */}

      <App />
      {/* <ClickLog /> */}
    </Provider>
    {/* </React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWgit statusebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
