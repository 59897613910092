/** @format */

import { CircularProgress, Grid } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import LoginMUI from "../components/Authentication/LoginMUI";
import AgreementModal from "../components/Documents/AgreementModal";
import AgreementSign from "../components/Documents/AgreementSign";
import { storeCurrentBookings } from "../features/venueFilter/venueFilterSlice";
import { db, useAuth } from "../firebase";
import BatchAgreementModal from "../components/Documents/BatchAgreementModal";
import { checkIfUserExist } from "../helpers/CheckIfUserExist";
import CheckIfEmailExists from "../utils/CheckIfEmailExists";
import SimpleSignup from "../helpers/simpleSignup";
import BankIdSignIn from "../helpers/BankIdSignIn";
import { set } from "date-fns";
import PrivacyPolicy from "../components/Documents/PrivacyPolicy";

const SignAgreement = () => {
  const currentUser = useAuth();
  const userRole = useSelector((state) => state.counter.userRole);
  const bookings = useSelector((state) => state.counter.bookings);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bookingids = queryParams.get("bookingids").split(",");
  const sign = queryParams.get("sign");
  const agreementId = queryParams.get("agreementId");
  const userid = queryParams.get("userid");
  const agreementPDF = queryParams.get("agreement");
  const customToken = queryParams.get("customToken");
  const [passedVerification, setPassedVerification] = useState(queryParams.get("passedVerification"));
  const [token, setToken] = useState(queryParams.get("JWT"));
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [myBookings, setMyBookings] = useState();
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignup, setOpenSignup] = useState(passedVerification);
  const [docPDF, setDocPDF] = useState("");
  const [bookingData, setBookingData] = useState({});
  const [newUser, setNewUser] = useState(false);
  const [agreement, setAgreement] = useState({});

  console.log("BookingData Fresh", bookingData);
  // Get the booking to sign. (AgreementModal picks it up from currentBookings, so we fetch it and put it there)
  // useEffect(() => {
  //   setLoading(true);
  //   const tmp = bookings
  //     .filter(function (booking) {
  //       return booking.agentid === currentUser.userid;
  //     })
  //     .map(function (booking) {
  //       return booking;
  //     });
  //   tmp.sort((a, b) => new Date(a.date) - new Date(b.date));
  //   setMyBookings(tmp);
  //   dispatch(storeCurrentBookings(tmp));
  //   setLoading(false);
  // }, [bookings]);

  useEffect(() => {
    if (passedVerification === "false" || !passedVerification) {
      setOpenSignup(true);
    } else {
      setOpenSignup(false);
    }
  }, [passedVerification]);

  useEffect(() => {
    if (currentUser === null) return;
    console.log("now it should open", currentUser);
  }, [currentUser]);

  // useEffect(() => {
  //   if (!agreement) return;
  //   // get the agreement with this agreementId
  //   async function getAgreement() {
  //     const docRef = doc(db, "agreements", agreementId);
  //     const docSnap = await getDoc(docRef);
  //     if (docSnap.exists()) {
  //       console.log("Document data:", docSnap.data());
  //       setAgreement(docSnap.data());
  //       alert("agreement set");
  //     } else {
  //       alert("No such document for agreement!");
  //       // doc.data() will be undefined in this case
  //       console.log("No such document!");
  //     }
  //   }
  //   getAgreement();
  // }, [agreementId]);

  return (
    <div>
      {console.log("BookingData hej", bookingData)}
      {/* old signUp/signIn */}
      {/* <SimpleSignup openSignup={true} setOpenSignup={setOpenSignup} mailAddress={userid} role={"agent"} /> */}
      {/* NEW signIn forn agentSignature */}
      {console.log("currentUser", currentUser)}
      {(sign === "agentSignature" || currentUser !== []) && (
        <BankIdSignIn
          openSignup={openSignup}
          setOpenSignup={setOpenSignup}
          mailAddress={userid}
          role={"agent"}
          customToken={customToken}
          agreementId={agreementId}
          sign={"agentSignature"}
        />
      )}
      <div>
        <Grid mt={12} xs={12} p={8} key={currentUser + sign + agreementId}>
          {console.log("count me")}
          {!bookingData.agentSignature && <h2>Document</h2>}
          {bookingData.agentSignature && bookingData.venueSignature && <h2>Document signed...</h2>}
          {!openLogin && bookingData && <span>Click to open</span>}
          {sign === "venueSignature" && currentUser && <BatchAgreementModal sign={sign} agreementId={agreementId} open={!openLogin} />}
          {/* {sign === "agentSignature" && userRole !== "none" && <BatchAgreementModal sign={sign} agreementId={agreementId} open="true" />} */}
          {sign === "agentSignature" && currentUser && <BatchAgreementModal sign={sign} agreementId={agreementId} open={true} />}
          {sign === "showAgreement" && (
            <BatchAgreementModal key={currentUser + sign + agreementId} sign={sign} agreementId={agreementId} open={true} />
          )}
        </Grid>
      </div>
    </div>
  );
};

export default SignAgreement;
