/** @format */

import { fetchCachedData, storeDataInIndexedDB, ensureStores } from "../components/Sidebar/IndexedDBForCurrentVenueBookings";
import { db } from "../firebase";
import { onSnapshot, query, collection, where } from "firebase/firestore";
import { Timestamp } from "firebase/firestore";

export const fetchCachedBookingsOrLoad = async (venueid, venueAccess) => {
  let resultBookings = [];
  if (venueid === "none" || venueid === undefined) return;
  await ensureStores("venuehubDatabase", venueAccess, "bookingid"); // Ensure the database is opened before fetching data
  await ensureStores("venuehubTimestamp", venueAccess, "venueid");
  await ensureStores("venuehubSettings", ["venueid"], "lastUsed");

  console.time("setListener"); // Start timing the entire function
  if (venueid === undefined) return;
  if (venueid) {
    // First get cached bookings
    const getChachedBookings = await fetchCachedData("venuehubDatabase", venueid, "bookingid");
    const getTimestampChachedBookings = await fetchCachedData("venuehubTimestamp", venueid, "venueid");
    const cachedTimestamp = getTimestampChachedBookings[0] && getTimestampChachedBookings[0].dateTime;

    const cachedBookings = getChachedBookings.map((booking) => {
      // Remove products and features from cached bookings
      return {
        ...booking, // Spread the existing booking properties
        venue: {
          ...booking.venue, // Spread the existing venue properties
          products: [], // Replace products with an empty array
          features: [], // Replace features with an empty array
        },
      };
    });
    if (cachedBookings.length > 0) {
      // setCached(cachedBookings);
      resultBookings = cachedBookings;
    }

    const td = new Date();
    const todayTimestamp = Timestamp.fromDate(td);
    const q =
      cachedTimestamp === undefined
        ? query(collection(db, "Bookings"), where("venueid", "==", venueid))
        : query(collection(db, "Bookings"), where("venueid", "==", venueid), where("updated", ">", cachedTimestamp));

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      // Only process changes, additions, and deletions
      querySnapshot.docChanges().forEach(async (change, index) => {
        console.log("indexad", index);
        if (change.type === "added") {
          console.log("Document added: ", change.doc.data());
          // Handle added document
        } else if (change.type === "modified") {
          console.log("Document modified: ", change.doc.data());
          // Handle modified document
        } else if (change.type === "removed") {
          console.log("Document removed: ", change.doc.id);
          // Handle removed document
        }
      });

      // Optionally, maintain a local cache to avoid redundant reprocessing
      const updatedBookings = querySnapshot.docs.map((doc) => doc.data());
      updatedBookings.sort((a, b) => new Date(a.date) - new Date(b.date));

      const minimizedBookings = updatedBookings.map((booking) => ({
        ...booking,
        venue: {
          ...booking.venue,
          products: [],
          features: [],
        },
      }));
      // Assuming minimizedBookings is the array of new bookings from Firestore
      // and cachedBookings is an array you've retrieved from IndexedDB or another local source

      // Combine both arrays
      const combinedBookings = [...cachedBookings, ...minimizedBookings];

      // Use a Map to remove duplicates (last one wins)
      const bookingsMap = new Map();
      combinedBookings.forEach((booking) => {
        bookingsMap.set(booking.bookingid, booking);
      });

      // Convert the Map values back to an array
      const uniqueBookings = Array.from(bookingsMap.values());

      // Now update tempCurrentVenue with the unique bookings
      resultBookings = { ...uniqueBookings };

      // The bookingslistener might be using an outdated currentVenue since it was first set, so we're updating the currentVenueRef whenever currentVenue changes.

      const timestamp = [{ timestamp: todayTimestamp, venueid: venueid, dateTime: todayTimestamp.toDate() }];
      // Dispatch and store minimized bookings
      await storeDataInIndexedDB("venuehubDatabase", minimizedBookings, venueid, "bookingid"); // Store the bookings in indexedDB
      await storeDataInIndexedDB("venuehubTimestamp", timestamp, venueid, "venueid"); // Store the timestamp showing when bookings last were cached in indexedDB.
      // unsubscribe();
    });
  }
  return resultBookings;
};
