/** @format */

// /** @format */

// import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
// import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

// const ConfirmDialog = ({ onConfirm, onCancel, ...props }) => {
//   const defaultState = props.open || false;
//   const [open, setOpen] = useState(defaultState);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);
//   const [header, setHeader] = useState("Are you sure?");
//   const [message, setMessage] = useState("This action cannot be undone. Are you sure you want to proceed?");
//   const [optionA, setOptionA] = useState("Cancel");
//   const [optionB, setOptionB] = useState("Confirm");
//   const [onHover, setOnHover] = useState();

//   const handleConfirm = () => {
//     onConfirm();
//     handleClose();
//   };

//   const handleCancel = () => {
//     onCancel && onCancel();
//     handleClose();
//   };

//   useEffect(() => {
//     props.header && setHeader(props.header);
//     props.message !== "" && setMessage(props.message);
//     props.optionA && setOptionA(props.optionA);
//     props.optionB && setOptionB(props.optionB);
//   }, []);

//   return (
//     <>
//       {props.noButton ? (
//         <div onClick={handleOpen} {...props} />
//       ) : (
//         <Button disabled={props.disabled} onClick={handleOpen} {...props} size="small" />
//       )}
//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle>{header}</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             <div style={{ textAlign: "left" }} className="text">
//               {message}
//             </div>
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           {/* <Button onClick={handleClose}>{optionA}</Button> */}
//           <Button onClick={handleCancel}>{optionA}</Button>
//           <Button onClick={handleConfirm}>{optionB}</Button>
//         </DialogActions>
//       </Dialog>
//     </>
//     // <>
//     //   <Button onClick={handleOpen} {...props} size="small" />
//     //   <Dialog open={open} onClose={handleClose}>
//     //     <DialogTitle>Are you sure?</DialogTitle>
//     //     <DialogContent>
//     //       <DialogContentText>This action cannot be undone. Are you sure you want to proceed?</DialogContentText>
//     //     </DialogContent>
//     //     <DialogActions>
//     //       <Button onClick={handleClose}>Cancel</Button>
//     //       <Button onClick={handleConfirm}>Confirm</Button>
//     //     </DialogActions>
//     //   </Dialog>
//     // </>
//   );
// };

// ConfirmDialog.propTypes = {
//   onDelete: PropTypes.func.isRequired,
// };

// export default ConfirmDialog;

/** @format */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const ConfirmDialog = ({ onConfirm, onCancel, open, ...props }) => {
  const [header, setHeader] = useState("Are you sure?");
  const [message, setMessage] = useState("This action cannot be undone. Are you sure you want to proceed?");
  const [optionA, setOptionA] = useState("Cancel");
  const [optionB, setOptionB] = useState("Confirm");

  const handleConfirm = () => {
    onConfirm();
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  useEffect(() => {
    props.header && setHeader(props.header);
    props.message !== "" && setMessage(props.message);
    props.optionA && setOptionA(props.optionA);
    props.optionB && setOptionB(props.optionB);
  }, [props.header, props.message, props.optionA, props.optionB]);

  return (
    <>
      {props.noButton ? <div {...props} /> : <Button disabled={props.disabled} {...props} size="small" />}
      <Dialog open={open} disableBackdropClick>
        <DialogTitle>{header}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ textAlign: "left" }} className="text">
              {message}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>{optionA}</Button>
          <Button onClick={handleConfirm}>{optionB}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ConfirmDialog.propTypes = {
  onDelete: PropTypes.func.isRequired,
};

export default ConfirmDialog;
