/** @format */

import React, { useState } from "react";
import LoginMUI from "../components/Authentication/LoginMUI";
import { useSearchParams } from "react-router-dom";

const LoginPage = () => {
  const [openLogin, setOpenLogin] = useState(false);
  const [searchParams] = useSearchParams();
  const userName = searchParams.get("userName");

  return (
    <div>
      <LoginMUI setOpenLogin={setOpenLogin} openLogin={true} userName={userName} />
    </div>
  );
};

export default LoginPage;
