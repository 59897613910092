/** @format */
import React, { useEffect, useRef, useState } from "react";
import { Grid, Tooltip, ButtonGroup, Button } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import TitleIcon from "@mui/icons-material/Title";
import ShareIcon from "@mui/icons-material/Share";
import FormatClearIcon from "@mui/icons-material/FormatClear";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";

const BookingNotes = ({ bookingData, setBookingData, saveBooking }) => {
  const editorRef = useRef(null);
  // Initialize displayedContent to the saved notes or a placeholder.
  const [displayedContent, setDisplayedContent] = useState(bookingData.notes || "Enter notes here...");

  // Set the editor content on mount.
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.innerHTML = bookingData.notes || "";
      setDisplayedContent(bookingData.notes || "");
    }
  }, []); // run only once on mount

  const handleInput = () => {
    if (editorRef.current) {
      const newHTML = editorRef.current.innerHTML;
      setDisplayedContent(newHTML);
      // Update bookingData with the new HTML content.
      setBookingData((prev) => ({ ...prev, notes: newHTML }));
    }
  };

  // Ensure the editor is focused before executing the command.
  const execCmd = (command, value = null) => {
    if (editorRef.current) {
      editorRef.current.focus();
      document.execCommand(command, false, value);
    }
  };

  // Remove formatting and force a block-level element.
  const removeAllFormatting = () => {
    execCmd("removeFormat");
    execCmd("formatBlock", "<div>");
  };

  const shareNotes = () => {
    // Implement your sharing logic here.
    alert("Sharing notes: " + displayedContent);
  };

  const handleSave = () => {
    saveBooking();
  };

  // useEffect(() => {
  //   saveBooking();
  // }, [bookingData.notes]);

  // Grouping all the formatting buttons including new alignment and color options.
  const TextFormatButtonGroup = ({ execCmd }) => {
    return (
      <>
        {/* Basic Formatting */}
        <ButtonGroup variant="contained" size="small" style={{ marginLeft: "10px" }}>
          <Tooltip title="Header">
            <Button onClick={() => execCmd("formatBlock", "<h2>")}>
              <TitleIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Plain Text">
            <Button onClick={removeAllFormatting}>
              <FormatClearIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Bold">
            <Button onClick={() => execCmd("bold")}>
              <FormatBoldIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Italic">
            <Button onClick={() => execCmd("italic")}>
              <FormatItalicIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Underline">
            <Button onClick={() => execCmd("underline")}>
              <FormatUnderlinedIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>

        {/* Text Alignment */}
        <ButtonGroup variant="contained" size="small" style={{ marginLeft: "10px" }}>
          <Tooltip title="Align Left">
            <Button onClick={() => execCmd("justifyLeft")}>
              <FormatAlignLeftIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Align Center">
            <Button onClick={() => execCmd("justifyCenter")}>
              <FormatAlignCenterIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Align Right">
            <Button onClick={() => execCmd("justifyRight")}>
              <FormatAlignRightIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>

        {/* Color Pickers */}
        <ButtonGroup variant="contained" size="small" style={{ marginLeft: "10px" }}>
          <Tooltip title="Text Color">
            <Button style={{ position: "relative" }}>
              <FormatColorTextIcon />
              {/* The transparent color input overlays the icon */}
              <input
                type="color"
                onChange={(e) => execCmd("foreColor", e.target.value)}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                  cursor: "pointer",
                }}
              />
            </Button>
          </Tooltip>
          <Tooltip title="Text background">
            <Button style={{ position: "relative" }}>
              <FormatColorFillIcon />
              <input
                type="color"
                onChange={(e) => execCmd("hiliteColor", e.target.value)}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                  cursor: "pointer",
                }}
              />
            </Button>
          </Tooltip>
        </ButtonGroup>

        {/* Share Button (currently disabled) */}
        <ButtonGroup variant="contained" size="small" style={{ marginLeft: "10px" }}>
          <Tooltip title="Share">
            <Button onClick={shareNotes} disabled>
              <ShareIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} p={4}>
        <TextFormatButtonGroup execCmd={execCmd} />
      </Grid>
      <Grid item xs={12}>
        <div
          ref={editorRef}
          contentEditable={true}
          onInput={handleInput}
          onBlur={() => handleSave()}
          style={{
            width: "100%",
            background: "linear-gradient(0, #222, #333)",
            color: "white",
            border: "none",
            padding: "10px",
            height: "40vh",
            fontSize: "16px",
            outline: "none", // removes the focus outline
            overflow: "auto",
          }}
        ></div>
      </Grid>
    </Grid>
  );
};

export default BookingNotes;
