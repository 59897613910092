/** @format */

import { CircularProgress, FormControlLabel, Grid, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import EmailProgressBar from "../../helpers/EmailProgressBar";

const BookingStaffEditor = ({ bookingData, setBookingData }) => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const venueFeatures = currentVenue.features;
  const product = bookingData.venue.products.find((product) => product.productid === bookingData.productid);
  const [features, setFeatures] = useState(bookingData.bookingFeatures ? bookingData.bookingFeatures : product ? product.features : []);
  const [staff, setStaff] = useState();
  const [editMode, setEditMode] = useState(false);
  const [sentArray, setSentArray] = useState([]);
  const [invitingAll, setInvitingAll] = useState(false);

  useEffect(() => {
    setFeatures(bookingData.bookingFeatures ? bookingData.bookingFeatures : product ? product.features : []);
  }, [bookingData]);

  useEffect(() => {
    const tmp = Object.values(features).filter((feature) => feature.additional === "Staff");
    setStaff(tmp[0]);
  }, [features]);

  useEffect(() => {
    console.log("staff", staff);
  }, [staff]);

  const handleChangeValue = (additionalId, optionId, key, value) => {
    const tmp = JSON.parse(JSON.stringify(features));
    tmp[additionalId].options[optionId][key] = value === "" ? " " : value; // Set the new value, but if empty provide a blank (otherwise the field disapears).
    console.log("tmp", tmp);
    setFeatures(tmp);
    setBookingData((prev) => ({ ...prev, bookingFeatures: tmp }));
  };

  // Merge any new modules or options
  useEffect(() => {
    setTimeout(() => {
      mergeNewFeatures(features, venueFeatures);
    }, 500);
  }, []);

  // Function to merge new features and options from venueFeatures into bookingFeatures
  function mergeNewFeatures(bookingFeatures, venueFeatures) {
    // if (bookingFeatures === undefined || bookingFeatures === "-") return;
    console.log("bookingFeatures", bookingFeatures);
    console.log("venueFeatures", venueFeatures);
    bookingFeatures = JSON.parse(JSON.stringify(bookingFeatures));
    venueFeatures = JSON.parse(JSON.stringify(venueFeatures));
    Object.keys(venueFeatures).forEach((featureKey) => {
      if (!bookingFeatures[featureKey]) {
        // Initialize bookingFeatures as an object if it is not already one
        if (typeof bookingFeatures === "string") {
          bookingFeatures = {}; // Convert to an empty object
        }
        // If the feature doesn't exist in bookingFeatures, add it
        bookingFeatures[featureKey] = venueFeatures[featureKey];
      } else {
        // If the feature exists, check options within it
        if (venueFeatures[featureKey].options) {
          // Ensure the bookingFeatures feature has an options object
          if (!bookingFeatures[featureKey].options) {
            bookingFeatures[featureKey].options = {};
          }
          // Iterate over venueFeatures feature options
          Object.keys(venueFeatures[featureKey].options).forEach((optionKey) => {
            // Add the option if it doesn't exist in bookingFeatures
            if (!bookingFeatures[featureKey].options[optionKey]) {
              bookingFeatures[featureKey].options[optionKey] = venueFeatures[featureKey].options[optionKey];
            }
            // If the option exists, it remains unchanged
          });
        }
        // Any additional properties of features can be checked and merged similarly
      }
    });
    console.log("This should be it", bookingFeatures);
    setFeatures(bookingFeatures);
  }

  const sendInvite = async (email, usr) => {
    // alert("Invite sent to " + email);
    const body = {
      usr: usr,
      email: email,
      bookingid: bookingData.bookingid,
      eventImage: bookingData.artist.image,
    };
    const response = await axios.post(`https://europe-west3-venuehub-64e72.cloudfunctions.net/sendEmailInviteToItinerary`, body); //for live
    // const response = await axios.post(`http://127.0.0.1:5001/venuehub-64e72/europe-west3/sendEmailInviteToItinerary`, body); // for testing
  };

  const inviteAll = async () => {
    setInvitingAll(true);
    console.log("staff", staff);
    for (let i = 0; i < Object.values(staff.options).length; i++) {
      if (Object.values(staff.options)[i].selected) {
        sendInvite(Object.values(staff.options)[i].email, Object.values(staff.options)[i].optionId);
      }
    }
  };

  useEffect(() => {
    const numberStaffSelected = staff && Object.values(staff.options).filter((option) => option.selected).length;
    staff && console.log("Lengths", numberStaffSelected, sentArray.length);
    sentArray.length === numberStaffSelected && setInvitingAll(false);
  }, [sentArray]);

  return (
    <Grid container>
      <h7>Staff</h7>
      <span style={{ marginLeft: "20px", fontSize: "10px", float: "right" }}>
        <FormControlLabel control={<Switch size="small" checked={editMode} onChange={() => setEditMode(!editMode)} />} label="Edit Mode" />
      </span>
      {/* <span className="staffInvite" style={{ width: "100px" }} onClick={() => inviteAll()}>
        <p>{invitingAll && <CircularProgress style={{ zoom: "0.4" }} />} Invite All</p>
      </span> */}
      <Grid item>
        <table className="staffTable">
          {typeof staff === "object" &&
            staff !== null &&
            Object.values(features).map((feature) => (
              <>
                {feature.additional === "Staff" &&
                  Object.values(feature.options).map((option) => (
                    <>
                      <tr
                        style={{
                          opacity: option.selected ? 1 : 0.3,
                          display: option.selected ? "table" : editMode ? "table" : "none",
                        }}
                        className="staffRow"
                      >
                        {editMode && (
                          <td style={{ width: "50px" }}>
                            <Switch
                              size="small"
                              checked={option.selected}
                              onChange={(e) => handleChangeValue(feature.additionalId, option.optionId, "selected", !option.selected)}
                            />
                          </td>
                        )}
                        <td>{option.option}</td>
                        <td>
                          <input
                            disabled={!option.selected}
                            type="text"
                            placeholder="enter responsibility..."
                            value={option.responsibility}
                            onChange={(e) => handleChangeValue(feature.additionalId, option.optionId, "responsibility", e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            disabled={!option.selected}
                            type="text"
                            placeholder="enter phonenumber..."
                            value={option.phone}
                            onChange={(e) => handleChangeValue(feature.additionalId, option.optionId, "phone", e.target.value)}
                          />
                        </td>
                        <td>
                          <input
                            disabled={!option.selected}
                            type="text"
                            placeholder="enter email..."
                            value={option.email}
                            onChange={(e) => handleChangeValue(feature.additionalId, option.optionId, "email", e.target.value)}
                          />
                        </td>
                        {option.selected && (
                          <td style={{ padding: "5px" }}>
                            <p
                              className="staffInvite"
                              onClick={() => {
                                sendInvite(option.email, option.optionId);
                              }}
                              style={{ backgroundColor: sentArray.includes(bookingData.bookingid + option.optionId) ? "green" : "orange" }}
                            >
                              {sentArray.includes(bookingData.bookingid + option.optionId) ? "Invited" : "Invite"}
                            </p>
                            <span style={{ zoom: "0.5" }}>
                              {
                                <EmailProgressBar
                                  docId={bookingData.bookingid + option.optionId}
                                  sentArray={sentArray}
                                  setSentArray={setSentArray}
                                />
                              }
                              {console.log(sentArray)}
                            </span>
                          </td>
                        )}
                        <tr>
                          <td colSpan="5">
                            <input
                              className="staffNotes"
                              placeholder="enter notes..."
                              disabled={!option.selected}
                              type="textarea"
                              value={option.notes}
                              onChange={(e) => handleChangeValue(feature.additionalId, option.optionId, "notes", e.target.value)}
                            />
                          </td>
                        </tr>
                      </tr>
                    </>
                  ))}
              </>
            ))}
        </table>
      </Grid>
    </Grid>
  );
};

export default BookingStaffEditor;
