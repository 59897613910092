/** @format */

// TODO
// Snabba upp allt genom att inte köra <GetBookings /> utan istälet använda currentVenue.bookings

/** @format */

import DateRange from "../../components/Sidebar/DateRange";
import {
  Alert,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Popover,
  Popper,
  Snackbar,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createAvailsPost, quickSend } from "./QuickSend";
import { useDispatch, useSelector } from "react-redux";
import PreferredDays from "../../components/Sidebar/PreferredDays";
import checkAvails from "./CheckAvails";
import SeatedStandingSelect from "../../components/Sidebar/SeatedStandingSelect";
import ProductSelect from "../../components/MiscUIComponents/ProductSelect";
import DayNightSelect from "../../components/Sidebar/DayNightSelect";
import { db } from "../../firebase";
import { collection, doc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import GetBookings from "../../helpers/GetBookings";
import { InputLabel, Input } from "@mui/material";
import GetBookingsForCurrentVenue from "../../helpers/GetBookingsForCurrentVenue";
import { Data } from "@react-google-maps/api";
import { storeSituation, storeUsers } from "../venueFilter/venueFilterSlice";
import EmailProgressBar from "../../helpers/EmailProgressBar";
import HelpBox from "../../helpers/HelpBox";

var awaiting = [];

const SendAvails = ({ expanded, closeAvails }) => {
  const dispatch = useDispatch();
  dispatch(storeSituation("sendAvails"));
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  // console.log(currentVenue);
  const chosenDateRange = useSelector((state) => state.counter.chosenDateRange);
  const prefDays = useSelector((state) => state.counter.prefDays);
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [allUsers, setAllUsers] = useState([{}]);
  //This is set in checkAvails
  const [avails, setAvails] = useState([]);
  const [awaiting, setAwaiting] = useState([]);
  // To find recurring customers
  // const bookings = useSelector((state) => state.counter.bookings);
  // const bookingsForCurrentVenue = useSelector((state) => state.counter.bookingsForCurrentVenue);
  const [agents, setAgents] = useState([{}]);
  const [bookings, setBookings] = useState([{}]);
  const [existingEmailAddresses, setExistingEmailAddresses] = useState([{}]);

  const currentProduct = useSelector((state) => state.counter.currentProduct);
  const [sentArray, setSentArray] = useState([]); // to keep track of sent emails

  const [open, setOpen] = useState(expanded);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    dispatch(storeSituation(""));
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState("Right");

  function handleEmail(emailAddress) {
    !active && setActive(true);
    checkUserExists(emailAddress);
    setEmail(emailAddress);
  }

  function checkUserExists(emailAddress) {
    const user = allUsers.find((user) => user.email === emailAddress);
    user && setName(`${user.firstName} ${user.lastName}`);
    return user ? user : null;
  }

  function handleName(customerName) {
    setName(customerName);
  }

  const [availsid, setAvailsid] = useState();
  const [sent, setSent] = useState(false);
  async function handleSubmit() {
    let exists = null;
    const existingUser = await checkUserExists(email);
    let tmpAvailsid = await createAvailsPost(name, email, currentVenue, avails, awaiting, chosenDateRange, currentProduct);
    setAvailsid(tmpAvailsid);

    if (!existingUser) {
      exists = false;
      await quickSend(exists, name, email, currentVenue, avails, awaiting, tmpAvailsid, currentProduct, EmailSent);
    } else {
      exists = true;
      await quickSend(exists, name, email, currentVenue, avails, awaiting, tmpAvailsid, currentProduct, EmailSent);
    }
    setSent(true);
  }

  function EmailSent(msg) {
    // alert(msg);
  }

  useEffect(() => {
    async function getData() {
      await checkAvails(currentVenue, chosenDateRange, prefDays, currentProduct, setAvails, setAwaiting);
    }
    getData();
  }, [chosenDateRange, prefDays, currentVenue, currentProduct]);

  //Get previous customers
  useEffect(() => {
    let temp = [];
    let agentsListed = [...new Map(bookings.map((e) => [e.agentid, e])).values()];

    agentsListed.map((b, index) => {
      let sum = 0;
      bookings.forEach((booking) => booking.agentid === b.agentid && sum++);
      console.log(sum);
      temp.push({
        id: index,
        artist: b.artist,
        agent: b.agent,
        date: b.date,
        agentEmail: b.agentEmail,
        agentid: b.agentid,
        artistImage: b.artistImage,
        price: b.price,
        // priceIncVat: b.priceIncVat,
        numberofevents: sum,
      });
    });
    setAgents(temp);
    console.log(temp);
  }, [bookings]);

  // async function getAgentsBookings() {
  //   getUsers();
  //   let tmp = [{}];
  //   const querySnapshot = await getDocs(query(collection(db, "Bookings"), where("agent.email", "==", email)));
  //   setBookings(querySnapshot.docs.map((doc) => doc.data()));
  //   console.log("agents finished");
  // }

  // useEffect(() => {
  //   handleEmail();
  //   checkUserExists(email);
  // }, [allUsers]);

  const [active, setActive] = useState(false);
  // if sendAvails is active get all users
  useEffect(() => {
    const getData = async () => {
      const data = await getDocs(collection(db, "Users"));
      setAllUsers(data.docs.map((doc) => doc.data()));
    };
    {
      active && getData();
    }
  }, [active]);

  // show email state
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [state, setState] = useState();

  useEffect(() => {
    sent && setOpenSnackbar(true);
    const unsubscribe =
      sent &&
      onSnapshot(doc(db, "mail", availsid), (doc) => {
        if (doc.exists()) {
          doc.data().delivery && setState(doc.data().delivery.state);
          setOpen(true);
        } else {
          console.log("No such document!");
        }
      });

    // cleanup function
    return () => sent && unsubscribe();
  }, [sent]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };

  useEffect(() => {
    state === "SUCCESS" && setState("EMAIL SENT");
    (state === "ERROR" || state === "EMAIL SENT") &&
      setTimeout(() => {
        setOpenSnackbar(false);
        setState("");
        setSent(false);
      }, 2000);
  }, [state]);

  return (
    <div>
      <Grid item container spacing={2} xs={12}>
        {/* <HelpBox id="sendAvails" /> */}
        {/* <Card className="availsCard">
          <div id="">
            <stats className="">
              <div>
                {avails.length} Available Dates
                {console.log(avails, awaiting)}
                {avails.map((date) => (
                  <div className="availItem">{date}</div>
                ))}
              </div>
              <div>
                {awaiting.length} Preliminary booked
                {awaiting.map((date) => (
                  <div className="awaitingItem">{date}</div>
                ))}
              </div>
            </stats>
          </div>
        </Card> */}

        <Grid item mt={2} style={{ float: "left", marginLeft: "0px" }}>
          <div className="filterLabel">Date Range</div>
          <DateRange />
        </Grid>
        <Grid item sx={12} style={{ width: "100%" }}>
          <div className="filterLabel">Choose Product</div>
          <ProductSelect products={currentVenue.products} />
        </Grid>
        <Grid item>
          <div className="filterLabel">Preferred Days</div>
          <PreferredDays specificDays={currentProduct.preferredDays} numberOfLetters="2" />
        </Grid>
        <Grid item>
          <stats className="">
            <div>
              {avails.length} Available Dates
              {console.log(avails, awaiting)}
              {avails.map((date) => (
                <div className="availItem">{date}</div>
              ))}
            </div>
            <div>
              {awaiting.length} Preliminary booked
              {awaiting.map((date) => (
                <div className="awaitingItem">{date}</div>
              ))}
            </div>
          </stats>
        </Grid>

        <Grid item style={{ width: "100%" }} mt={1}>
          <div className="filterLabel">Personal Information</div>

          <TextField
            fullWidth={true}
            label="Email"
            placeholder="Enter Email"
            size={"small"}
            onChange={(e) => handleEmail(e.target.value)}
            // onBlur={() => getUsers()}
          />
        </Grid>
        {email && (
          <Grid item style={{ width: "100%" }}>
            <title>Name</title>
            <TextField
              fullWidth={true}
              value={name}
              label="Name"
              size={"small"}
              onChange={(e) => handleName(e.target.value)}
              InputLabelProps={{
                shrink: name ? true : false,
              }}
            />
          </Grid>
        )}
        <Grid item style={{ width: "100%" }}>
          {availsid && <EmailProgressBar docId={availsid} close={closeAvails} setSentArray={setSentArray} />}
        </Grid>
        <Tooltip title={email && currentProduct ? "" : "Choose product and enter email address to send avails"} placement="top">
          <Grid item style={{ width: "100%" }}>
            <Button
              fullWidth={true}
              variant="contained"
              onClick={() => {
                handleSubmit();
              }}
              disabled={email && currentProduct ? false : true}
            >
              Send Avails
            </Button>
          </Grid>
        </Tooltip>
      </Grid>
      {/* <Snackbar open={openSnackbar} autoHideDuration={1000} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={state === "PROCESSING" ? "info" : state === "EMAIL SENT" ? "success" : state === "ERROR" ? "error" : "info"}
          sx={{ width: "100%" }}
        >
          {state}
        </Alert>
      </Snackbar> */}
      {/* </Card> */}
    </div>
  );
};

export default SendAvails;
