/** @format */

// /** @format */

// import { Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
// import { doc, setDoc, Timestamp, updateDoc } from "firebase/firestore";
// import React, { useEffect } from "react";
// import { useState } from "react";
// import { db } from "../../firebase";
// import ConfirmDialog from "../../helpers/ConfirmDialog";
// import { useSelector } from "react-redux";
// import LockIcon from "@mui/icons-material/Lock";

// const SelectStatus = ({ bookingData, setBookingData, agreement, loading, setLoading }) => {
//   const [newValue, setNewValue] = useState();
//   const [oldValue, setOldValue] = useState(bookingData.status);
//   const [done, setDone] = useState(true);
//   const [removingSignatures, setRemovingSignatures] = useState(null);
//   const [formLock, setFormLock] = useState(false);
//   const superOwner = useSelector((state) => state.counter.superOwner);
//   const bookingsLength = agreement && agreement.bookingids ? agreement.bookingids.length : 0;
// const newSignturesRequired =
//   agreement && (agreement.agentSignature || agreement.venueSignature)
//     ? `New signatures required.
// `
//     : "";
// const venueSignedBy = agreement && agreement.venueSignature ? `- Removing venue signature by ${agreement.venueSignature.userId}.` : "";
// const agentSignedBy =
//   agreement && agreement.agentSignature
//     ? `
// - Removing customer signature by ${agreement.agentSignature.userId}.`
//     : "";

//   const handleChange = async () => {
//     console.log("bookingstatus", bookingData.status);
//     if (oldValue === newValue) return;
//     console.log("6");
//     setOldValue(bookingData.status);
//     setLoading(true);
//     // update agreement
//     await setDoc(doc(db, "Agreements", bookingData.agreementId), { status: newValue }, { merge: true });
//     //update all the bookings
//     const updatePromises = agreement.bookingids.map((bookingId) =>
//       setDoc(doc(db, "Bookings", bookingId), { status: newValue, updated: Timestamp.fromDate(new Date()) }, { merge: true })
//     );
//     await Promise.all(updatePromises);
//     // Now change locally

//     // update bookingdata
//     setBookingData((prev) => ({ ...prev, status: newValue, updated: Timestamp.fromDate(new Date()) }));
//     setLoading(false);
//   };

//   async function removeSignatures(type) {
//     alert(type);
//     return;
//     // alert("removing signatures");
//     const previousVenueSignature = agreement.venueSignature || "";
//     const previousAgentSignature = agreement.agentSignature || "";
//     // update agreement with empty signatures and setting previous signatures (for safety matters)

//     await setDoc(
//       doc(db, "Agreements", bookingData.agreementId),
//       // { previousVenueSignature, previousAgentSignature, venueSignature: "", agentSignature: "" },
//       { previousVenueSignature, previousAgentSignature, [type]: "" },
//       { merge: true }
//     );
//     // Now do the same thing for all the bookings
//     const updatePromises = agreement.bookingids.map((bookingId) =>
//       setDoc(
//         doc(db, "Bookings", bookingId),
//         // { previousVenueSignature, previousAgentSignature, venueSignature: "", agentSignature: "" },
//         { previousVenueSignature, previousAgentSignature, [type]: "" },
//         { merge: true }
//       )
//     );
//     await Promise.all(updatePromises);
//     handleChange();
//   }

//   function dontRemoveSignatures() {
//     // setRemovingSignatures(false);
//     handleChange();
//   }

//   async function removeVenueSignatureAndHandleChange() {
//     await removeSignatures("venueSignature");
//     handleChange();
//   }

//   async function removeAgentSignatureAndHandleChange() {
//     await removeSignatures("agentSignature");
//     handleChange();
//   }

//   async function removeAllSignaturesAndHandleChange() {
//     await removeSignatures("venueSignature");
//     await removeSignatures("agentSignature");
//     handleChange();
//   }

//   console.log("selectstatus", agreement);

//   // useEffect(() => {
//   //   // bookingData.status === "Confirmed" && setFormLock(true);
//   //   // bookingData.status !== "Confirmed" && setFormLock(false);
//   //   // setOldValue(bookingData.status);
//   // }, [bookingData.status]);

//   const cancel = () => {
//     alert("no change");
//     setOldValue(bookingData.status);
//   };

//   if (agreement)
//     return (
//       <>//   function restoreVenueSignature() {
//     updateDoc(doc(db, "Agreements", bookingData.agreementId), {
//       venueSignature: agreement.previousVenueSignature,
//       previousVenueSignature: "",
//     });
//   }

//   function restoreAgentSignature() {
//     updateDoc(doc(db, "Agreements", bookingData.agreementId), {
//       agentSignature: agreement.previousAgentSignature,
//       previousAgentSignature: "",
//     });
//   }
//         {/* <ConfirmDialog
//           disabled={formLock}
//           onConfirm={() => setRemovingSignatures(true)}
//           header={bookingsLength > 1 ? `Change status for ${bookingsLength} bookings? ` : `Change status for ${bookingsLength} booking? `}
//           message={`${newSignturesRequired} ${venueSignedBy} ${agentSignedBy}`}
//         > */}
//         <FormControl fullWidth disabled={formLock || loading}>
//           {/* <InputLabel id="demo-simple-select-label">Status</InputLabel> */}
//           <Select
//             // sx={{ height: "30px", color: "transparent" }}
//             size="small"
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             value={bookingData.status}
//             label="Status"
//             // onChange={(e) => handleChange(e.target.value)}
//             onChange={(e) => setNewValue(e.target.value)}
//           >
//             <MenuItem value={"Pending Request"}>
//               <b style={{ color: "red", marginRight: "15px" }}>█</b>
//               <span style={{ color: "white" }}> Pending Request</span>
//             </MenuItem>
//             <MenuItem value={"Awaiting Artist"}>
// <b style={{ color: "orange", marginRight: "15px" }}>█</b>
// <span style={{ color: "white" }}> Awaiting Artist</span>
//             </MenuItem>
//             <MenuItem value={"Confirmed"}>
//               <b style={{ color: "green", marginRight: "15px" }}>█</b>
//               <span style={{ color: "white" }}>Confirmed</span>
//               <span style={{ color: "white" }}>
//                 {" "}
//                 {formLock && (
//                   <Tooltip title="Unlock form to change status">
//                     {/* <Button onClick={() => setFormLock(false)} style={{ float: "right" }}> */}
//                     <LockIcon onClick={() => setFormLock(false)} style={{ float: "right", color: "#00ffd4" }} />
//                     {/* </Button> */}
//                   </Tooltip>
//                 )}
//               </span>
//             </MenuItem>
//           </Select>
//         </FormControl>

//         {oldValue === "Awaiting Artist" && newValue === "Pending Request" && (
//           <ConfirmDialog
//             // onConfirm={handleChangeAndRemoveSignatures}
//             onConfirm={() => removeVenueSignatureAndHandleChange()}
//             onCancel={cancel}
//             // onCancel={alert("cancel")}
//             // onCancel={setRemovingSignatures(false)}
//             header={bookingsLength > 1 ? `Change status for ${bookingsLength} bookings? ` : `Change status for ${bookingsLength} booking? `}
//             message={`${newSignturesRequired} ${venueSignedBy} ${agentSignedBy}`}
//             open={true}
//           ></ConfirmDialog>
//         )}
//         {oldValue === "Confirmed" && newValue === "Pending Request" && (
//           <ConfirmDialog
//             onConfirm={removeAllSignaturesAndHandleChange()}
//             onCancel={cancel}
//             // onCancel={alert("cancel")}
//             // onCancel={setRemovingSignatures(false)}
//             header={bookingsLength > 1 ? `Change status for ${bookingsLength} bookings? ` : `Change status for ${bookingsLength} booking? `}
//             message={`${newSignturesRequired} ${venueSignedBy} ${agentSignedBy}`}
//             open={true}
//           ></ConfirmDialog>
//         )}
//         {oldValue === "Confirmed" && newValue === "Awaiting Artist" && (
//           <ConfirmDialog
//             onConfirm={() => removeAgentSignatureAndHandleChange()}
//             onCancel={cancel}
//             // onCancel={alert("cancel")}
//             // onCancel={setRemovingSignatures(false)}
//             header={bookingsLength > 1 ? `Change status for ${bookingsLength} bookings? ` : `Change status for ${bookingsLength} booking? `}
//             message={`${newSignturesRequired} ${venueSignedBy} ${agentSignedBy}`}
//             open={true}
//           ></ConfirmDialog>
//         )}
//         {oldValue === "Pending Request" && newValue === "Awaiting Artist" && (
//           <ConfirmDialog
//             onConfirm={dontRemoveSignatures}
//             onCancel={cancel}
//             header={`Agreement not signed`}
//             message={`Continue without signing? In order to sign on behalf of the venue, return to Pending Request at your convenience.`}
//             open={true}
//           ></ConfirmDialog>
//         )}
//         {oldValue === "Pending Request" && newValue === "Confirmed" && (
//           <ConfirmDialog
//             onConfirm={dontRemoveSignatures}
//             onCancel={cancel}
//             header={`Agreement not signed`}
//             message={`Continue without signing?`}
//             open={true}
//           ></ConfirmDialog>
//         )}
//         {oldValue === "Awaiting Artist" && newValue === "Confirmed" && (
//           <ConfirmDialog
//             onConfirm={dontRemoveSignatures}
//             onCancel={cancel}
//             header={`Agreement not signed`}
//             message={`Continue without signing?`}
//             open={true}
//           ></ConfirmDialog>
//         )}
//         {superOwner && agreement.previousVenueSignature && agreement.previousVenueSignature.userId && (
//           <Button onClick={() => restoreVenueSignature()}>Restore previous venue signature</Button>
//         )}
//         {superOwner && agreement.previousAgentSignature && agreement.previousAgentSignature.userId && (
//           <Button onClick={() => restoreAgentSignature()}>Restore previous customer signature</Button>
//         )}
//         {oldValue} - {newValue}
//       </>
//     );
// };

// export default SelectStatus;

import React, { useState } from "react";
import { Select, MenuItem, InputLabel, FormControl, Button, Tooltip, CircularProgress, InputAdornment } from "@mui/material";
import ConfirmDialog from "../../helpers/ConfirmDialog";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Timestamp } from "firebase/firestore";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import LockIcon from "@mui/icons-material/Lock";

function SelectStatus({ bookingData, setBookingData, agreement, loading, setLoading }) {
  const [oldStatus, setOldStatus] = useState(bookingData.status);
  const [newStatus, setNewStatus] = useState(bookingData.status);
  const [dialogComponent, setDialogComponent] = useState(null);
  const [formLock, setFormLock] = useState(bookingData.status === "Confirmed" ? true : false);
  const superOwner = useSelector((state) => state.counter.superOwner);

  const newSignturesRequired =
    agreement && (agreement.agentSignature || agreement.venueSignature)
      ? `New signatures required.
`
      : "";
  const venueSignedBy = agreement && agreement.venueSignature ? `- Removing venue signature by ${agreement.venueSignature.userId}.` : "";
  const agentSignedBy =
    agreement && agreement.agentSignature
      ? `
- Removing customer signature by ${agreement.agentSignature.userId}.`
      : "";

  const handlePendingToAwaiting = (value) => {
    console.log("Pending Request -> Awaiting Artist");
    setDialogComponent(
      <ConfirmDialog
        header={`Agreement not signed`}
        message={`Continue without signing?`}
        onConfirm={() => {
          console.log("Confirm");
          updateBookingsAndAgrement(value);
          setFormLock(false);
          setDialogComponent(null);
        }}
        onCancel={() => {
          console.log("Cancel");
          cancel();
          setDialogComponent(null);
        }}
        open={true}
      />
    );
  };

  const handlePendingToConfirmed = (value) => {
    console.log("Pending Request -> Confirmed");
    setDialogComponent(
      <ConfirmDialog
        header={`Confirm Agreement`}
        message={`Are you sure you want to confirm this agreement?`}
        onConfirm={() => {
          console.log("Confirm");
          updateBookingsAndAgrement(value);
          setFormLock(true);
          setDialogComponent(null);
        }}
        onCancel={() => {
          console.log("Cancel");
          cancel();
          setDialogComponent(null);
        }}
        open={true}
      />
    );
  };

  const handleAwaitingToPending = (value) => {
    console.log("Awaiting Artist -> Pending Request");
    setDialogComponent(
      <ConfirmDialog
        header={`Revert to Pending`}
        message={`Are you sure you want to revert to pending request? ${newSignturesRequired}${venueSignedBy}`}
        onConfirm={() => {
          console.log("Confirm");
          updateBookingsAndAgrement(value);
          removeSignatures("venueSignature");
          setFormLock(false);
          setDialogComponent(null);
        }}
        onCancel={() => {
          console.log("Cancel");
          cancel();
          setDialogComponent(null);
        }}
        open={true}
      />
    );
  };

  const handleAwaitingToConfirmed = (value) => {
    console.log("Awaiting Artist -> Confirmed");
    setDialogComponent(
      <ConfirmDialog
        header={`Confirm Agreement`}
        message={`Are you sure you want to confirm this agreement?`}
        onConfirm={() => {
          console.log("Confirm");
          updateBookingsAndAgrement(value);
          setFormLock(true);
          setDialogComponent(null);
        }}
        onCancel={() => {
          console.log("Cancel");
          cancel();
          setDialogComponent(null);
        }}
        open={true}
      />
    );
  };

  const handleConfirmedToPending = (value) => {
    console.log("Confirmed -> Pending Request");
    setDialogComponent(
      <ConfirmDialog
        header={`Revert to Pending`}
        message={`Are you sure you want to revert to pending request? ${newSignturesRequired}${venueSignedBy}${agentSignedBy}`}
        onConfirm={() => {
          console.log("Confirm");
          updateBookingsAndAgrement(value);
          removeSignatures("venueSignature");
          removeSignatures("agentSignature");
          setFormLock(false);
          setDialogComponent(null);
        }}
        onCancel={() => {
          console.log("Cancel");
          cancel();
          setFormLock(true);
          setDialogComponent(null);
        }}
        open={true}
      />
    );
  };

  const handleConfirmedToAwaiting = (value) => {
    console.log("Confirmed -> Awaiting Artist");
    setDialogComponent(
      <ConfirmDialog
        header={`Revert to Awaiting`}
        message={`Are you sure you want to revert to awaiting artist? ${newSignturesRequired}${agentSignedBy}`}
        onConfirm={() => {
          console.log("Confirm");
          updateBookingsAndAgrement(value);
          removeSignatures("agentSignature");
          setFormLock(false);
          setDialogComponent(null);
        }}
        onCancel={() => {
          console.log("Cancel");
          cancel();
          setFormLock(true);
          setDialogComponent(null);
        }}
        open={true}
      />
    );
  };

  const cancel = () => {
    setNewStatus(oldStatus);
  };

  const handleChange = (event) => {
    const selectedStatus = event.target.value;
    const previousStatus = newStatus;

    setOldStatus(previousStatus);
    setNewStatus(selectedStatus);

    switch (`${previousStatus}-${selectedStatus}`) {
      case "Pending Request-Awaiting Artist":
        handlePendingToAwaiting(selectedStatus);
        break;
      case "Pending Request-Confirmed":
        handlePendingToConfirmed(selectedStatus);
        break;
      case "Awaiting Artist-Pending Request":
        handleAwaitingToPending(selectedStatus);
        break;
      case "Awaiting Artist-Confirmed":
        handleAwaitingToConfirmed(selectedStatus);
        break;
      case "Confirmed-Pending Request":
        handleConfirmedToPending(selectedStatus);
        break;
      case "Confirmed-Awaiting Artist":
        handleConfirmedToAwaiting(selectedStatus);
        break;
      default:
        break;
    }
  };

  const updateBookingsAndAgrement = async (value) => {
    setLoading(true);
    // update agreement
    await setDoc(doc(db, "Agreements", bookingData.agreementId), { status: value }, { merge: true });
    //update all the bookings
    const updatePromises = agreement.bookingids.map((bookingId) =>
      setDoc(doc(db, "Bookings", bookingId), { status: value, updated: Timestamp.fromDate(new Date()) }, { merge: true })
    );
    await Promise.all(updatePromises);
    // update bookingdata
    setBookingData((prev) => ({ ...prev, status: value, updated: Timestamp.fromDate(new Date()) }));
    setLoading(false);
  };

  async function removeSignatures(type) {
    // alert(type);
    // return;
    // alert("removing signatures");
    const previousVenueSignature = agreement.venueSignature || "";
    const previousAgentSignature = agreement.agentSignature || "";
    // update agreement with empty signatures and setting previous signatures (for safety matters)
    setLoading(true);
    await setDoc(
      doc(db, "Agreements", bookingData.agreementId),
      // { previousVenueSignature, previousAgentSignature, venueSignature: "", agentSignature: "" },
      { previousVenueSignature, previousAgentSignature, [type]: "" },
      { merge: true }
    );
    // Now do the same thing for all the bookings
    const updatePromises = agreement.bookingids.map((bookingId) =>
      setDoc(
        doc(db, "Bookings", bookingId),
        // { previousVenueSignature, previousAgentSignature, venueSignature: "", agentSignature: "" },
        { previousVenueSignature, previousAgentSignature, [type]: "" },
        { merge: true }
      )
    );
    await Promise.all(updatePromises);
    setLoading(false);
  }

  function restoreVenueSignature() {
    updateDoc(doc(db, "Agreements", bookingData.agreementId), {
      venueSignature: agreement.previousVenueSignature,
      previousVenueSignature: "",
    });
  }

  function restoreAgentSignature() {
    updateDoc(doc(db, "Agreements", bookingData.agreementId), {
      agentSignature: agreement.previousAgentSignature,
      previousAgentSignature: "",
    });
  }

  return (
    <div>
      <FormControl disabled={formLock}>
        <InputLabel id="status-select-label">Status</InputLabel>
        <Select
          labelId="status-select-label"
          id="status-select"
          value={newStatus}
          label="Status"
          onChange={handleChange}
          size="small"
          inputProps={{
            // Always render InputAdornment
            startAdornment: (
              <InputAdornment position="start">
                <CircularProgress size={20} />
              </InputAdornment>
            ),
          }}
        >
          <MenuItem value="Pending Request" style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
            <b style={{ color: "red", marginRight: "15px" }}>{!loading ? "█" : <CircularProgress size={20} />}</b>
            <span style={{ color: "white" }}> Pending Request</span>
          </MenuItem>
          <MenuItem value="Awaiting Artist" style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
            <b style={{ color: "orange", marginRight: "15px" }}>{!loading ? "█" : <CircularProgress size={20} />}</b>
            <span style={{ color: "white" }}> Awaiting Artist</span>
          </MenuItem>
          <MenuItem value="Confirmed" style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
            <b style={{ color: "green", marginRight: "15px" }}>{!loading ? "█" : <CircularProgress size={20} />}</b>
            <span style={{ color: "white" }}>Confirmed</span>
            <span style={{ color: "white" }}>
              {" "}
              {formLock && (
                <Tooltip title="Unlock form to change status">
                  <LockIcon onClick={() => setFormLock(false)} style={{ float: "right", color: "#00ffd4", marginLeft: "50px" }} />
                </Tooltip>
              )}
            </span>
          </MenuItem>
        </Select>
      </FormControl>
      {dialogComponent}
      {/* {superOwner && agreement.previousVenueSignature && agreement.previousVenueSignature.userId && ( */}
      {/* <Button onClick={() => restoreVenueSignature()}>Restore previous venue signature</Button> */}
      {/* )} */}
      {/* {superOwner && agreement.previousAgentSignature && agreement.previousAgentSignature.userId && ( */}
      {/* <Button onClick={() => restoreAgentSignature()}>Restore previous customer signature</Button> */}
      {/* )} */}
    </div>
  );
}

export default SelectStatus;
