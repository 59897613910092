/** @format */

import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";

const CsvToJsonConverter = () => {
  const [jsonData, setJsonData] = useState([]);

  // Function to handle file upload and parse CSV
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const text = e.target.result;
        const rows = text.split("\n"); // Split CSV into rows
        const headers = rows[0].split(";"); // Extract headers (first row)

        // Process each row and convert to JSON
        const data = rows.slice(1).map((row) => {
          const values = row.split(";");
          const entry = {};

          headers.forEach((header, index) => {
            entry[header.trim()] = values[index] ? values[index].trim() : ""; // Trim whitespace
          });

          return entry;
        });

        // Set the parsed JSON data to state
        setJsonData(data);
        storeTrasactions(data);
      };

      reader.readAsText(file); // Read the file as text
    }
  };

  async function storeTrasactions(data) {
    console.log("storing", data);
    await setDoc(doc(db, "Transactions", "SEBtransactions"), { ...data });
  }

  return (
    <div>
      <h1>Nytt kontoutdrag</h1>
      <p>Hämta en CSV-fil från banken och ladda upp den här.</p>
      <input type="file" accept=".csv" onChange={handleFileUpload} />
      <h2>Parsed JSON Data:</h2>
      <pre>{JSON.stringify(jsonData, null, 2)}</pre>
    </div>
  );
};

export default CsvToJsonConverter;
