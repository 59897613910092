/** @format */

import { Drawer, FormControlLabel, Grid, Slide, SwipeableDrawer, Switch, ToggleButton } from "@mui/material";
import React, { Fragment } from "react";
import BookingViewOptions from "../Containers/BookingViewOptions";
import MultiBookingsCalendar from "../Containers/MultiBookingsCalendar";

const CalendarOverView = ({ setOpenCalendarOverview }) => {
  const [numberOfVenuesToView, setNumberOfVenuesToView] = React.useState(1);
  const [open, setOpen] = React.useState(false);

  const overViewToggle = (
    <div
      onClick={() => setOpen(!open)}
      style={{
        opacity: "0.2",
        cursor: "pointer",
        textAlign: "center",
      }}
    >
      <div
        style={{
          fontWeight: "100",
          fontSize: "40px",
          transform: open ? "rotateX(180deg)" : "rotateX(0deg)", // Correct rotation
          marginTop: open ? "-20px" : "-20px", // Separate margin property
          transition: "transform 0.5s ease-in-out, margin-bottom 0.5s ease-in-out", // Transition both
        }}
      >
        ⌃
      </div>
      <div style={{ marginTop: open ? "-10px" : "-30px", fontSize: "12px" }}>{open ? "Close overview" : "Overview"}</div>
    </div>
  );

  return (
    <div className="calendarOverview" style={{ backgound: "red" }}>
      <Grid
        style={
          {
            //   borderTop: open ? "1px solid #fff" : "none",
            //   background: open ? "#111" : "transparent",
            //   maxHeight: !open ? "70px" : "2000px", // Use max-height; adjust "1000px" as needed
            //   transition: "max-height 0.5s ease-in-out",
          }
        }
        container
      >
        <BookingViewOptions setNumberOfVenuesToView={setNumberOfVenuesToView} />
        <Grid
          item
          className="multiBookingsCalendar"
          style={{
            position: "fixed",
            left: open ? "0" : "50vw",
            width: open ? "100vw" : "100px",
            bottom: open ? "0" : "-10px",
            zIndex: "10000000000000",
            borderRadius: open ? "0px" : "50px 50px 0px 0px",
            transition: "all 0.1s ease-out",
          }}
        >
          <div className="overviewSwitch"> {overViewToggle} </div>
          {open && <MultiBookingsCalendar />}
        </Grid>
      </Grid>
    </div>
  );
};

export default CalendarOverView;
