/** @format */

import { FormControlLabel, Grid, Switch } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ObjectSize from "../../helpers/ObjectSize";
import { useDispatch } from "react-redux";
import { storeSuperOwner } from "../../features/venueFilter/venueFilterSlice";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useState } from "react";
import AdditionalFees from "../../features/Admin Backend/AdditionalFees";
import DisplayObject from "../../helpers/displayObject";
import ConnectAgreement from "../../helpers/ConnectAgreement";

const VenueInfo = () => {
  const situation = useSelector((state) => state.counter.situation);
  const helperInfo = useSelector((state) => state.counter.helperInfo);
  const currentVersion = useSelector((state) => state.counter.currentVersion);
  const superOwner = useSelector((state) => state.counter.superOwner);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentBooking = useSelector((state) => state.counter.currentBooking);
  const currentAgreement = useSelector((state) => state.counter.currentAgreement);
  const currentVenueBookings = useSelector((state) => state.counter.currentVenueBookings);
  const currentTickets = useSelector((state) => state.counter.currentTickets);
  const currentTicketSummary = useSelector((state) => state.counter.currentTicketSummary);
  const currentProducts = useSelector((state) => state.counter.currentProducts);
  const currentAgreements = useSelector((state) => state.counter.currentAgreements);
  const userRole = useSelector((state) => state.counter.userRole);
  const currentUser = useSelector((state) => state.counter.currentUser);
  const currentSettings = useSelector((state) => state.counter.currentSettings);
  const cs = useSelector((state) => state.counter.currentSettings);
  const currentMonth = useSelector((state) => state.counter.currentMonth);
  const currentYear = useSelector((state) => state.counter.currentYear);
  const vcMonth = currentSettings && currentSettings.vcMonth + 1;
  const vcYear = currentSettings && currentSettings.vcYear;
  const infoColor = "#00ffd4";
  const redux = useSelector((state) => state);
  const myKey = `${vcYear}-${vcMonth < 10 ? "0" : ""}${vcMonth}`;
  const dispatch = useDispatch();

  console.log("ticketSummaries", currentTicketSummary);

  const summarizeEventsByMonthYear = (events) => {
    const summary = { count: 0, sum: 0 };
    if (Object.values(events).length < 10) return summary;
    Object.values(events).forEach((event) => {
      // Extract year and month from eventStart
      const date = new Date(event.eventStart);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // Months are zero-based, so add 1
      const monthYearKey = `${year}-${month < 10 ? "0" : ""}${month}`; // Format as YYYY-MM
      console.log("monthYearKey", monthYearKey);
      console.log("event", event);
      // Initialize summary for this monthYearKey if not already present
      if (!summary[monthYearKey]) {
        summary[monthYearKey] = {
          events: 0,
          count: 0,
          sum: 0,
        };
      }

      // Add the count and sum to the corresponding monthYearKey
      summary[monthYearKey].count += event.summary.count;
      summary[monthYearKey].sum += event.summary.sum;
      summary[monthYearKey].events += 1;
    });

    return summary;
  };

  const monthlySummary = Object.values(currentTicketSummary).length && summarizeEventsByMonthYear(currentTicketSummary);
  console.log("Monthly Summary: ", monthlySummary);

  return (
    <Grid style={{ fontSize: "12px", maxWidth: "200px", overflow: "scroll", height: "30vh" }}>
      {/* <div>{currentVenue && Object.values(currentVenue.products).map((product) => product.product)}</div> */}
      {/* {superOwner && <ConnectAgreement agreementId="acvddwougf6j465xuj2u2dsfockmxu65" />} */}
      {/* <div>
        <b>Ticket service: </b>
        <span style={{ color: infoColor }}>{currentVenue.ticketService}</span>
      </div> */}
      {/* <div>
        {monthlySummary[myKey] && monthlySummary[myKey].count} tickets sold ({monthlySummary[myKey] && monthlySummary[myKey].sum}{" "}
        {currentVenue.currency}) in {monthlySummary[myKey] && monthlySummary[myKey].events} events.
      </div>
      <div>Sales this month: {vcMonth}</div>
      <div>
        <b>Venue products: </b>
        <span style={{ color: infoColor }}>{currentVenue.products && currentVenue.products.length}</span>
      </div> */}
      {/* <DisplayObject data={currentVenue.products} /> */}
      {/* Products: <ObjectSize dataObject={currentVenue.products} /> */}
      <div className="versionNumber">
        <div>
          <b>v. </b>
          <span style={{ color: infoColor }}>{currentVersion}</span>
        </div>
        <div>
          <b>… </b>
        </div>
      </div>
      {superOwner && (
        <>
          <div>
            currentVenueBookings: {currentVenueBookings.length} <ObjectSize dataObject={currentVenueBookings} />
          </div>
          <div>Situation: {situation}</div>
          <div style={{ color: helperInfo ? "green" : "red" }}>HelperInfo</div>
          <div>
            features: <ObjectSize dataObject={currentVenue.features} />
          </div>
          <div>
            <div style={{ color: "yellow" }}>
              {cs.vcMonth}/{cs.vcYear}
            </div>
            <div style={{ color: "lime" }}>
              {currentMonth}/{currentYear}
            </div>
            <div style={{ color: "orange" }} v>
              <b>{currentVenue.venue}</b>
              <table>
                <tr>
                  <td>venueId:</td>
                  <td>{currentVenue.venueid}</td>
                </tr>
                <tr>
                  <td>ticketVenueId:</td>
                  <td>{currentVenue.ticketVenueId}</td>
                </tr>
                <tr>
                  <td>ticketEventId:</td>
                  <td>{currentBooking.ticketEventId}</td>
                </tr>
                <tr>
                  <td>ticketService:</td>
                  <td>{currentVenue.ticketService}</td>
                </tr>
                <tr>
                  <td>upfront:</td>
                  <td>
                    {currentVenue.upfront}, {currentVenue.upfrontAt} days ahead
                  </td>
                </tr>
                <tr>
                  <td>preCharge:</td>
                  <td>
                    {currentVenue.preCharge}, {currentVenue.preChargeAt} days ahead
                  </td>
                </tr>
                <tr>
                  <td>balance:</td>
                  <td>
                    {currentVenue.balance}, {currentVenue.balanceAt} days ahead
                  </td>
                </tr>
                <tr>
                  <td>earlyCancelation:</td>
                  <td>
                    {currentVenue.earlyCancelation}, {currentVenue.earlyCancelationAt} days ahead
                  </td>
                </tr>
                <tr>
                  <td>lateCancelation:</td>
                  <td>
                    {currentVenue.lateCancelation}, {currentVenue.lateCancelationAt} days ahead
                  </td>
                </tr>
              </table>
            </div>
            <div>
              <b>currentBooking: </b>
              {currentBooking.artist && currentBooking.artist.artist ? currentBooking.artist.artist : "No artist selected"}
              <table>
                <tr>
                  <td>upfront:</td>
                  <td>
                    {currentBooking.upfront}, {currentBooking.upfrontAt} days ahead
                  </td>
                </tr>
                <tr>
                  <td>preCharge:</td>
                  <td>
                    {currentBooking.preCharge}, {currentBooking.preChargeAt} days ahead
                  </td>
                </tr>
                <tr>
                  <td>balance:</td>
                  <td>
                    {currentBooking.balance}, {currentBooking.balanceAt} days ahead
                  </td>
                </tr>
                <tr>
                  <td>earlyCancelation:</td>
                  <td>
                    {currentBooking.earlyCancelation}, {currentBooking.earlyCancelationAt} days ahead
                  </td>
                </tr>
                <tr>
                  <td>lateCancelation:</td>
                  <td>
                    {currentBooking.lateCancelation}, {currentBooking.lateCancelationAt} days ahead
                  </td>
                </tr>
              </table>
            </div>
            <div>
              <b>currentAgreement</b>
              {currentAgreement && currentAgreement.venue}
            </div>
          </div>
          <div>
            redux size: <ObjectSize dataObject={redux} />
          </div>
          <div>
            Tickets: <ObjectSize dataObject={currentTickets} />
          </div>
          <div>
            Ticket summary: <ObjectSize dataObject={currentTicketSummary} />
          </div>
          <div>
            Bookings: <ObjectSize dataObject={currentVenueBookings} /> in {currentVenueBookings.length} bookings
          </div>
          <div>
            Agreements: <ObjectSize dataObject={currentAgreements} /> in {currentAgreements.length} agreements
          </div>
          <div>User: {currentUser.email}</div>
          <div>Role: {userRole}</div>

          <div style={{ background: "#00ffd411", padding: "5px", borderRadius: "10px" }}>
            Additional Fees
            <AdditionalFees />
          </div>

          <FormControlLabel
            size="small"
            label={<div>Admin</div>}
            control={<Switch size="small" checked={superOwner} onChange={() => dispatch(storeSuperOwner(!superOwner))} />}
          />
        </>
      )}
    </Grid>
  );
};

export default VenueInfo;
