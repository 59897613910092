/** @format */

import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { db } from "../../firebase";

const getAllTicketSalesFromAgreementId = async (agreementId) => {
  let ticketEventIds = [];
  let ticketVenueId;

  // Get bookingids from agreementId
  const docSnapAgreement = await getDoc(doc(db, "Agreements", agreementId));
  const bookingids = docSnapAgreement.data().bookingids;
  //   console.log("bookingIds", bookingids);

  // Get ticketEventIds and ticketVenueId from bookingids
  for (const bookingId of bookingids) {
    const docSnap = await getDoc(doc(db, "Bookings", bookingId));
    const ticketEventId = docSnap.data().ticketEventId;
    ticketEventIds.push(ticketEventId);
    ticketVenueId = docSnap.data().ticketVenueId;
    // console.log("ticketVenueId", ticketVenueId);
  }
  //   console.log("ticketEventIds", ticketEventIds, "ticketVenueId", ticketVenueId);

  // Get all ticket sales from ticketEventIds
  const docSnapTickets = await getDoc(doc(db, "TicketSummaries", ticketVenueId));
  const ticketSummaries = docSnapTickets.data() && Object.values(docSnapTickets.data());
  let allTicketsSum = 0;
  //   console.log("ticketSummaries", ticketSummaries);

  console.log("ticketEventIds", ticketEventIds);
  //   if (ticketEventIds) {
  //     return;
  //   }
  for (const ticketEventId of ticketEventIds) {
    if (ticketEventId === "-") {
      return 0;
    }
    const data = ticketSummaries.filter((event) => event.ticketEventId === ticketEventId);
    const summarySum = (data[0] && data[0].summary && data[0].summary.sum) || 0;
    // console.log("ticketEventId", ticketEventId, "summarySum", summarySum);
    allTicketsSum += summarySum;
  }
  //   console.log("allTicketsSum", allTicketsSum);
  return allTicketsSum;
};

export default getAllTicketSalesFromAgreementId;
