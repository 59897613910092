/** @format */

import React, { useState, useEffect } from "react";
import Badge from "@mui/material/Badge";
import WarningIcon from "@mui/icons-material/Warning";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { parseISO, parse, isWithinInterval, set } from "date-fns";
import { checkCollision } from "./checkCollision";
import { Tooltip } from "@mui/material";
import CorrectCollisions from "./CorrectCollisions";

function CollisionBadge({ booking, bookings, setCollisionOpen }) {
  const [collision, setCollision] = useState(false);
  const [collidingArray, setCollidingArray] = useState(bookings);
  const [message, setMessage] = useState("");
  const [openCorrectCollision, setOpenCorrectCollision] = useState(false);

  // First check if the booking is colliding with any other bookings
  useEffect(() => {
    // alert("running once");
    async function collisionCheck() {
      let tmp = await checkCollision(collidingArray, booking.date);
      if (tmp.length > 1) {
        setCollision(true);
        setCollidingArray(tmp); // set collidingArray
      } else {
        setCollision(false);
      }
    }
    collisionCheck();
  }, []);

  // then from correction check collidingArray each time it changes (without changing the collidingArray creating an infinite loop)
  useEffect(() => {
    // alert("over and over");
    async function collisionCheck() {
      let tmp = await checkCollision(collidingArray, booking.date);
      if (tmp.length > 1) {
        setCollision(true);
      } else {
        setCollision(false);
      }
    }
    collisionCheck();
  }, [collidingArray]);

  useEffect(() => {
    setCollisionOpen(openCorrectCollision);
  }, [openCorrectCollision]);

  return (
    <div>
      <Badge
        color="warning"
        badgeContent={
          <AccessTimeIcon style={{ fontSize: "14px", color: "white" }} placement="end" onMouseEnter={() => setOpenCorrectCollision(true)} />
        }
        invisible={!collision}
      >
        {/* {collidingArray && collidingArray.length > 0 && collidingArray.length} */}
      </Badge>
      {/* {openCorrectCollision && <div>{message}</div>} */}
      {openCorrectCollision && (
        <CorrectCollisions
          openCorrectCollision={openCorrectCollision}
          setOpenCorrectCollision={setOpenCorrectCollision}
          booking={booking}
          collidingArray={collidingArray}
          setCollidingArray={setCollidingArray}
          collision={collision}
        />
      )}
    </div>
  );
}

export default CollisionBadge;
