/** @format */

import { ca } from "date-fns/locale";

export function calculateEmissionsBasedOnDistance(distanceKm = 0, numberTraveling = 1) {
  const emissionFactors = {
    car: 135, // g CO2e/passenger-km
    bus: 92.46, // g CO2e/passenger-km
    train: 3.822, // g CO2e/passenger-km
    bicycle: 0, // g CO2e/passenger-km
    plane: 185.9171396, // g CO2e/passenger-km
  };
  const backAndForth = 2;

  let emissionsKg = 0;
  let carDistance = 0;
  let bicycleDistance = 0;
  let busDistance = 0;
  let trainDistance = 0;
  let planeDistance = 0;
  let carShare = 0;
  let bicycleShare = 0;
  let busShare = 0;
  let trainShare = 0;
  let planeShare = 0;

  let carImpact = 0;
  let bicycleImpact = 0;
  let busImpact = 0;
  let trainImpact = 0;
  let planeImpact = 0;

  if (distanceKm < 10) {
    carShare = 0.05;
    bicycleShare = 0.15;
    busShare = 0.2;
    trainShare = 0.6;
    planeShare = 0.0;

    // Calculate emissionshare based on distribution
    carDistance = distanceKm * carShare;
    bicycleDistance = distanceKm * bicycleShare;
    busDistance = distanceKm * busShare;
    trainDistance = distanceKm * trainShare;
    planeDistance = distanceKm * planeShare;

    // Calculate emissions based on distribution
    carImpact = (carDistance * emissionFactors.car * backAndForth) / 1000;
    bicycleImpact = (bicycleDistance * emissionFactors.bicycle * numberTraveling * backAndForth) / 1000;
    busImpact = (busDistance * emissionFactors.bus * numberTraveling * backAndForth) / 1000;
    trainImpact = (trainDistance * emissionFactors.train * numberTraveling * backAndForth) / 1000;
    planeImpact = (planeDistance * emissionFactors.plane * numberTraveling * backAndForth) / 1000;

    // Sum up emissions
    emissionsKg = carImpact + bicycleImpact + busImpact + trainImpact + planeImpact;
    console.log("🚜", carImpact, bicycleImpact, busImpact, trainImpact, planeImpact, emissionsKg);
  } else if (distanceKm < 20 && distanceKm >= 10) {
    carShare = 0.2;
    bicycleShare = 0.0;
    busShare = 0.2;
    trainShare = 0.6;
    planeShare = 0.0;

    // Calculate emissionshare based on distribution
    carDistance = distanceKm * carShare;
    bicycleDistance = distanceKm * bicycleShare;
    busDistance = distanceKm * busShare;
    trainDistance = distanceKm * trainShare;
    planeDistance = distanceKm * planeShare;

    // Calculate emissions based on distribution
    carImpact = (carDistance * emissionFactors.car * backAndForth) / 1000;
    bicycleImpact = (bicycleDistance * emissionFactors.bicycle * numberTraveling * backAndForth) / 1000;
    busImpact = (busDistance * emissionFactors.bus * numberTraveling * backAndForth) / 1000;
    trainImpact = (trainDistance * emissionFactors.train * numberTraveling * backAndForth) / 1000;
    planeImpact = (planeDistance * emissionFactors.plane * numberTraveling * backAndForth) / 1000;

    // Sum up emissions
    emissionsKg = carImpact + bicycleImpact + busImpact + trainImpact + planeImpact;
    console.log("🚜", carImpact, bicycleImpact, busImpact, trainImpact, planeImpact, emissionsKg);
  } else if (distanceKm > 20 && distanceKm <= 70) {
    carShare = 0.3;
    bicycleShare = 0.0;
    busShare = 0.1;
    trainShare = 0.6;
    planeShare = 0.0;

    // Calculate emissionshare based on distribution
    carDistance = distanceKm * carShare;
    bicycleDistance = distanceKm * bicycleShare;
    busDistance = distanceKm * busShare;
    trainDistance = distanceKm * trainShare;
    planeDistance = distanceKm * planeShare;

    // Calculate emissions based on distribution
    carImpact = (carDistance * emissionFactors.car * backAndForth) / 1000;
    bicycleImpact = (bicycleDistance * emissionFactors.bicycle * numberTraveling * backAndForth) / 1000;
    busImpact = (busDistance * emissionFactors.bus * numberTraveling * backAndForth) / 1000;
    trainImpact = (trainDistance * emissionFactors.train * numberTraveling * backAndForth) / 1000;
    planeImpact = (planeDistance * emissionFactors.plane * numberTraveling * backAndForth) / 1000;

    // Sum up emissions
    emissionsKg = carImpact + bicycleImpact + busImpact + trainImpact + planeImpact;
    console.log("🚜", carImpact, bicycleImpact, busImpact, trainImpact, planeImpact, emissionsKg);
  } else if (distanceKm > 70 && distanceKm <= 800) {
    carShare = 0.3;
    bicycleShare = 0.0;
    busShare = 0.1;
    trainShare = 0.58;
    planeShare = 0.02;

    // Calculate emissionshare based on distribution
    carDistance = distanceKm * carShare;
    bicycleDistance = distanceKm * bicycleShare;
    busDistance = distanceKm * busShare;
    trainDistance = distanceKm * trainShare;
    planeDistance = distanceKm * planeShare;

    // Calculate emissions based on distribution
    carImpact = (carDistance * emissionFactors.car * backAndForth) / 1000;
    bicycleImpact = (bicycleDistance * emissionFactors.bicycle * numberTraveling * backAndForth) / 1000;
    busImpact = (busDistance * emissionFactors.bus * numberTraveling * backAndForth) / 1000;
    trainImpact = (trainDistance * emissionFactors.train * numberTraveling * backAndForth) / 1000;
    planeImpact = (planeDistance * emissionFactors.plane * numberTraveling * backAndForth) / 1000;

    // Sum up emissions
    emissionsKg = carImpact + bicycleImpact + busImpact + trainImpact + planeImpact;
    console.log("🚜", carImpact, bicycleImpact, busImpact, trainImpact, planeImpact, emissionsKg);
  } else if (distanceKm > 800) {
    carShare = 0.1;
    bicycleShare = 0.0;
    busShare = 0.1;
    trainShare = 0.3;
    planeShare = 0.5;

    // Calculate emissionshare based on distribution
    carDistance = distanceKm * carShare;
    bicycleDistance = distanceKm * bicycleShare;
    busDistance = distanceKm * busShare;
    trainDistance = distanceKm * trainShare;
    planeDistance = distanceKm * planeShare;

    // Calculate emissions based on distribution
    carImpact = (carDistance * emissionFactors.car * backAndForth) / 1000;
    bicycleImpact = (bicycleDistance * emissionFactors.bicycle * numberTraveling * backAndForth) / 1000;
    busImpact = (busDistance * emissionFactors.bus * numberTraveling * backAndForth) / 1000;
    trainImpact = (trainDistance * emissionFactors.train * numberTraveling * backAndForth) / 1000;
    planeImpact = (planeDistance * emissionFactors.plane * numberTraveling * backAndForth) / 1000;

    // Sum up emissions
    emissionsKg = carImpact + bicycleImpact + busImpact + trainImpact + planeImpact;
    console.log("🚜", carImpact, bicycleImpact, busImpact, trainImpact, planeImpact, emissionsKg);
  } else {
    // Calculate emissions based on entire distance by car
    alert("Please enter a valid distance");
    emissionsKg = (distanceKm * emissionFactors.car) / 1000;
  }

  if (planeShare === undefined || planeDistance === undefined) {
    alert(distanceKm);
  }

  return {
    impact: emissionsKg,
    carDistance,
    bicycleDistance,
    busDistance,
    trainDistance,
    planeDistance,
    carShare,
    bicycleShare,
    busShare,
    trainShare,
    planeShare,
  };
}

// Example usage
// const distance = 8; // km
// const emissions = calculateEmissions(distance);
// console.log(`Emissions for traveling ${distance} km: ${emissions.toFixed(2)} kg CO2e`);
