/** @format */

import { all } from "axios";
import { parse } from "date-fns";

export const checkCollision = (allBookings, bookingDate) => {
  const bookings = allBookings.filter((booking) => booking.date === bookingDate); // only use bookings for the same date
  // console.log("allBookings", bookings, "date", bookingDate);
  if (!bookings || bookings.length < 2) {
    return []; // No collisions possible with 0 or 1 bookings
  }

  const timeToMinutes = (date, time) => {
    const parsedTime = parse(time, "HH:mm", new Date(date));
    let hours = parsedTime.getHours();
    let minutes = parsedTime.getMinutes();
    if (hours < 5) {
      hours += 24;
    }
    return (hours - 5) * 60 + minutes;
  };

  const collidingArray = [];

  for (let i = 0; i < bookings.length; i++) {
    const bookingA = bookings[i];
    // if (bookingA.date === bookingDate) console.log("bookingA", bookingA.date, "=", bookingDate);

    if (bookingA.status === "Killed") continue; // Skip killed bookings

    for (let j = i + 1; j < bookings.length; j++) {
      const bookingB = bookings[j];
      if (bookingB.status === "Killed") continue; // Skip killed bookings

      if (bookingA.date === bookingB.date) {
        const bookingALoadInMinutes = timeToMinutes(bookingA.date, bookingA.loadIn);
        const bookingALoadOutMinutes = timeToMinutes(bookingA.date, bookingA.loadOut);
        const bookingBLoadInMinutes = timeToMinutes(bookingB.date, bookingB.loadIn);
        const bookingBLoadOutMinutes = timeToMinutes(bookingB.date, bookingB.loadOut);

        // Manual interval overlap check
        if (
          (bookingALoadInMinutes >= bookingBLoadInMinutes && bookingALoadInMinutes < bookingBLoadOutMinutes) ||
          (bookingALoadOutMinutes > bookingBLoadInMinutes && bookingALoadOutMinutes <= bookingBLoadOutMinutes) ||
          (bookingBLoadInMinutes >= bookingALoadInMinutes && bookingBLoadInMinutes < bookingALoadOutMinutes) ||
          (bookingBLoadOutMinutes > bookingALoadInMinutes && bookingBLoadOutMinutes <= bookingALoadOutMinutes)
        ) {
          if (!collidingArray.includes(bookingA)) {
            collidingArray.push(bookingA);
          }
          if (!collidingArray.includes(bookingB)) {
            collidingArray.push(bookingB);
          }
        }
      }
    }
  }
  return collidingArray;
};
