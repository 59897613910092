/** @format */

import React, { useEffect } from "react";
import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Login from "./Login";
import Signup from "./Signup";
import { signup, login, logout, useAuth, db } from "../../firebase";
import { getAuth } from "firebase/auth";
import CheckUserRole from "./CheckUserRole";
import PersonIcon from "@mui/icons-material/Person";
import GeneralSignup from "./GeneralSignup";
import { doc, getDoc } from "firebase/firestore";
import LoginMUI from "./LoginMUI";
import OwnerSignup from "../../pages/OwnerSignup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { storeCurrentUserId, storeOwnedVenues, storeReset } from "../../features/venueFilter/venueFilterSlice";
import { Avatar, Button, Grid, IconButton, MenuItem, Select } from "@mui/material";
import GetVenues from "../../helpers/GetVenues";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import { current } from "@reduxjs/toolkit";
import ViewListIcon from "@mui/icons-material/ViewList";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HomeIcon from "@mui/icons-material/Home";
import CustomerAccount from "../../pages/CustomerAccount";

const Authentication = () => {
  //   const [modal, setModal] = useState(false);
  //   const Toggle = () => setModal(!modal);
  const currentUser = useAuth();
  const [loading, setLoading] = useState(false);
  var user = getAuth().currentUser;
  const navigate = useNavigate();
  const venueAccess = useSelector((state) => state.counter.venueAccess);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const userRole = useSelector((state) => state.counter.userRole);
  const [venueSelection, setVenueSelection] = useState([]);
  const dispatch = useDispatch();
  const [openLogin, setOpenLogin] = useState(false);
  const [currentVenueId, setCurrentVenueId] = useState({});

  //   function getVenuesToAccess() {
  //     let vta = [];
  //     venueAccess.map(async (id) => {
  //       const docSnap = await getDoc(doc(db, "Venues", id));
  //       vta.push(await docSnap.data());
  //       console.log("v .  t .  a", vta);
  //       setVenuesToAccess(vta);
  //     });
  //   }

  //   useEffect(() => {
  //     console.log(venuesToAccess);
  //   }, [venuesToAccess]);

  async function getUserData() {
    const docSnap = await getDoc(doc(db, "Users", currentUser.uid));
    // console.log("uid", docSnap.data());
  }

  useEffect(() => {
    // console.log("userRole", userRole);
    // userRole === "owner" && navigate("/Owner/Home");
  }, [userRole]);

  useEffect(() => {
    // console.log("currentVenue", currentVenueId);
  }, [currentVenueId]);

  useEffect(() => {
    async function getData() {
      user && (await getUserData());
      user && (await saveCurrentUserId());
      // console.log("currentUser", await currentUser, user);
    }
    getData();
  }, []);

  useEffect(() => {
    const VenuesToAccess = async () => {
      let tmp = [];
      // console.log(venueAccess);
      await venueAccess.map(async (venueid) => {
        const docSnap = await getDoc(doc(db, "Venues", venueid));
        if (docSnap.exists()) {
          // Convert to City object
          tmp.push({ id: docSnap.data().venueid, venue: docSnap.data().venue, image: docSnap.data().venueImage });
          // Use a City instance method
        } else {
          // console.log("No such document!");
        }
      });
      // console.log(tmp);
      setVenueSelection(tmp);
      // console.log("venueSelection", venueSelection);
      // setVenueSelection(tmp);
    };

    venueAccess && VenuesToAccess();
  }, [venueAccess]);

  function saveCurrentUserId() {
    dispatchEvent(storeCurrentUserId({ currentUserId: currentUser.uid }));
  }

  async function handleLogout() {
    setLoading(true);
    try {
      await logout();
      await dispatch(storeReset()); //Reset Redux to initialState.
      navigate("/Home");
    } catch {
      alert("Failed to logout");
    }
    setLoading(false);
    // setModal(false);
  }

  return (
    <div className="">
      {/* <CheckUserRole /> */}
      <Grid container flexDirection="row">
        {user && (userRole === "owner" || userRole === "editor") && (
          <IconButton onClick={() => navigate("/Owner/Home")}>
            <HomeIcon style={{ color: "#00ffd4" }} />
          </IconButton>
        )}
        <LoginMUI setOpenLogin={setOpenLogin} openLogin={openLogin} />
        {currentUser ? null : (
          <div className="notSignedIn" onClick={() => setOpenLogin(true)} className="loginButton">
            Log in
          </div>
        )}
        <Select
          size="small"
          p={4}
          sx={{ color: "transparent" }}
          startAdornment={
            // <IconButton onCLick={() => navigate("/Owner/Account")}>
            <PersonIcon
              sx={{
                color: currentUser ? "#00ffd4" : "#00ffd422",
                // mr: 1.5,
                border: currentUser ? "none" : "1px solid #00ffd444",
                borderRadius: "50px",
                cursor: "pointer",
              }}
              MenuProps={{ disableAutoClose: true }}
              onClick={() =>
                user && (userRole.toLowerCase() === "owner" || userRole.toLowerCase() === "editor")
                  ? navigate("/Owner/Account")
                  : navigate("/Account")
              }
            />
            // </IconButton>
          }
        >
          {currentUser ? (
            <span style={{ padding: "10px", opacity: "0.4", fontWeight: "100", fontFamily: "Avenir" }}>
              {currentUser.email}
              {/* {console.log("current user:", currentUser)} */}
              {/* {currentUser.userRole} */}
            </span>
          ) : (
            <></>
          )}

          {!user && <MenuItem onClick={() => setOpenLogin(true)}>Login</MenuItem>}
          {!user && (
            <MenuItem>
              <Signup />
            </MenuItem>
          )}
          {!user && <MenuItem onClick={() => navigate("/Signup")}>Own a Venue?</MenuItem>}
          {userRole === "agent" && (
            <MenuItem onClick={() => navigate("/Account")}>
              <PersonIcon style={{ marginRight: "10px", fontSize: "18px" }} /> Account
            </MenuItem>
          )}
          {userRole === "agent" && (
            <MenuItem onClick={() => navigate("/Requests")}>
              <ViewListIcon style={{ marginRight: "10px", fontSize: "18px" }} /> Overview
            </MenuItem>
          )}
          {/* {userRole === "agent" && (
            <MenuItem onClick={() => navigate("/Requests/Avails")}>
              <CalendarMonthIcon style={{ marginRight: "10px", fontSize: "18px" }} /> Requested avails
            </MenuItem>
          )} */}

          {user && userRole === "owner" && (
            <MenuItem onClick={() => navigate("/Owner/Account")}>
              <ManageAccountsRoundedIcon style={{ marginRight: "10px", fontSize: "18px" }} /> Account
            </MenuItem>
          )}
          {user && userRole === "owner" && (
            <MenuItem onClick={() => navigate("Owner/Settings")}>
              <SettingsRoundedIcon style={{ marginRight: "10px", fontSize: "18px" }} /> Settings
            </MenuItem>
          )}
          {currentUser ? (
            <MenuItem variant="" disabled={loading || !currentUser} onClick={handleLogout}>
              <LogoutRoundedIcon style={{ marginRight: "10px", fontSize: "18px" }} /> Log Out
            </MenuItem>
          ) : (
            <div></div>
          )}
          {/* <MenuItem variant="" onClick={() => navigate("SustainabilityForm")}>
            Sustainability Form
          </MenuItem> */}
        </Select>
      </Grid>
    </div>
  );
};

export default Authentication;
